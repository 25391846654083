import type { Head, InnerContent } from '@unhead/schema';

type PageMetaParams = Partial<{
  page: Record<string, any>;
  product: Record<string, any>;
  store: Record<string, any>;
  productList: Record<string, any>;
  breadcrumbs: Record<string, any>;
}>;

// It can be overwritten by using the useHead() composable on page components.
export const usePageMeta = async ({ page, product, store, productList, breadcrumbs }: PageMetaParams) => {
  const { $i18n, $host } = useNuxtApp();
  const { isMultilang, getSeoStoreList } = useMainStore();
  const route = useRoute();
  const isoLang = useIsoLang();

  const isStoreFinder = computed(() => route?.path?.indexOf($i18n.t('url.stores.index')) >= 0);
  const seoStoreList = ref<Record<string, any>[]>();

  if (isStoreFinder.value) {
    seoStoreList.value = await getSeoStoreList();
  }

  // Default page obj
  if (!page?.content?.meta) {
    // TODO: We should test if this has side-effects which we depend on, and stop mutating the function param
    // eslint-disable-next-line no-param-reassign
    page = page || {};
    page.content = page.content || {};
    page.content.meta = page.content.meta || {};
  }

  const title =
    product?.fullName ||
    product?.name ||
    store?.displayName ||
    page?.content.meta.title ||
    page?.content.title ||
    page?.content.additional_title ||
    page?.pageTitle;

  // Initialize arrays for useHead args
  const meta: Head['meta'] = [];
  const script: Head['script'] = [];
  const jsonLdData: InnerContent['innerHTML'] = [];

  // Init with localized prefetch links
  const link: Head['link'] = [
    {
      key: 'dns-preconnect-purpose',
      rel: 'preconnect',
      href: $i18n.t('header.meta.purpose'),
    },
    {
      key: 'dns-preconnect-metrics',
      rel: 'preconnect',
      href: $i18n.t('header.meta.metrics'),
    },
  ];

  // If the hrefLangData's are set in Filter page's SEO contents (NEW), use those for alternate-hreflang
  const hreflangInfo = page?.hrefLangDataNew ? page?.hrefLangDataNew : page?.hrefLangData || [];

  /* Add hreflang links to link tags, except
   * - on PDP
   * - on PLP with active facets
   * - on PLP filter pages without new hreflangData
   */
  if (
    hreflangInfo.length &&
    !product &&
    !(page?.template === 'product-listing' && route.query.q && !page?.hrefLangDataNew) &&
    !(page?.template === 'store-detail') &&
    !route.query.currentPage
  ) {
    for (const hreflang of hreflangInfo) {
      link.push({
        key: `alternate-hreflang-${hreflang.language}`,
        rel: 'alternate',
        href: hreflang.url,
        hreflang: hreflang.language,
      });
    }
  }

  // Add hreflangs to multilang store detail page
  if (hreflangInfo.length && isMultilang && page?.template === 'store-detail') {
    const path = route.path.substring(3);
    for (const hreflang of hreflangInfo) {
      link.push({
        key: `alternate-hreflang-${hreflang.key}`,
        rel: 'alternate',
        href: `${$host}/${hreflang.language.split('-')[0]}${path}`,
        hreflang: hreflang.language,
      });
    }
  }

  // Add PDP hreflangs
  if (product && product.hreflang?.entry?.length) {
    for (const hreflang of hreflangInfo) {
      link.push({
        key: `alternate-hreflang-${hreflang.key}`,
        rel: 'alternate',
        href: hreflang.value,
        hreflang: hreflang.key,
      });
    }
  }

  // Add app deeplink for PDP
  if (product) {
    link.push({
      key: 'alternate-app-deeplink',
      rel: 'alternate',
      href: `fressnapfapp://product/details/${product.code}?utm_medium=seo&utm_campaign=app`,
    });
  }

  //  Add app deeplink for PLP
  if (productList && !productList.freeTextSearch && !route.query.q && !route.query.sort) {
    link.push({
      key: 'alternate-app-deeplink',
      rel: 'alternate',
      href: `fressnapfapp://product/list?path=${encodeURIComponent(route.path)}&utm_medium=seo&utm_campaign=app`,
    });
  }

  //  Add app deeplink for store detail page
  if (store) {
    link.push({
      key: 'alternate-app-deeplink',
      rel: 'alternate',
      href: `fressnapfapp://store/detail/${store.storeNumber}?utm_medium=seo&utm_campaign=app`,
    });
  }

  // Add app deeplink for magazine and other content pages
  if (page?.content.has_app_deeplink) {
    const appDeeplinkUrl = encodeURIComponent(`${$host}${route.fullPath}`);
    link.push({
      key: 'alternate-app-deeplink',
      rel: 'alternate',
      href: `fressnapfapp://content/view?url=${appDeeplinkUrl}&utm_medium=seo&utm_campaign=app`,
    });
  }

  // Add title
  meta.push({
    key: 'og:title',
    name: 'og:title',
    property: 'og:title',
    content: `${title} ${$i18n.t('pages.title_suffix')}`,
  });

  // Set description
  const description = page?.content.meta.description || null;

  // Add description and og:description to meta tags
  meta.push({
    key: 'description',
    name: 'description',
    property: 'description',
    content: description,
  });

  meta.push({
    key: 'og:description',
    name: 'og:description',
    property: 'og:description',
    content: description,
  });

  // Add og:image to meta tags
  const concatCurrentRoot = `${$host}${$i18n.t('url.opengraph')}`;
  const ogImage = page?.content.thumbnail ? page?.content.thumbnail : concatCurrentRoot;

  if (ogImage) {
    meta.push({
      key: 'og:image',
      name: 'og:image',
      property: 'og:image',
      content: ogImage,
    });
  }

  const { robotsHasNoIndex, robotsContent } = useGetRobots({ page });
  if (unref(robotsContent)) {
    meta.push({
      key: 'robots',
      name: 'robots',
      content: unref(robotsContent),
    });
  }

  const canonical = useGetCanonical({ page, currentHost: $host as string, robotsHasNoIndex });
  if (unref(canonical)) {
    link.push({
      key: 'canonical',
      rel: 'canonical',
      href: unref(canonical),
    });

    meta.push({
      key: 'og:url',
      property: 'og:url',
      content: unref(canonical),
    });
  }

  // Add og:locale:alternate meta tags
  if (hreflangInfo.length) {
    for (const hreflang of hreflangInfo) {
      meta.push({
        key: `og:locale:alternate-${hreflang.language}`,
        name: `og:locale:alternate-${hreflang.language}`,
        property: 'og:locale:alternate',
        content: hreflang.language,
      });
    }
  }

  // Add pagination
  if (productList?.pagination?.nextLink) {
    link.push({
      key: 'next',
      rel: 'next',
      href: $host + productList.pagination.nextLink,
    });
  }

  if (productList?.pagination?.prevLink) {
    link.push({
      key: 'prev',
      rel: 'prev',
      href: $host + productList.pagination.prevLink,
    });
  }

  // Add breadcrumbs data in json+ld syntax
  const breadcrumbsLdData = breadcrumbs
    ? useBreadcrumbList({}, store, breadcrumbs)
    : page
      ? useBreadcrumbList(page, store)
      : null;
  if (breadcrumbsLdData) {
    jsonLdData.push(breadcrumbsLdData);
  }

  // Add product data in json+ld syntax
  if (product) {
    jsonLdData.push(useProduct(product));
  }

  // Add product list data in json+ld syntax
  if (productList) {
    jsonLdData.push(useItemList(page, productList));
  }

  // Add store data in json+ld syntax
  if (store) {
    jsonLdData.push(useStore(store));
  }

  // Add store finder data in json+ld syntax
  if (isStoreFinder.value && seoStoreList?.value) {
    jsonLdData.push(useLocalBusiness(seoStoreList.value));
  }

  const isHomePage = useIsPageUrl('url.homepage');

  if (unref(isHomePage)) {
    jsonLdData.push(useWebSite());
    jsonLdData.push(useFullOrganization());
  } else if (page?.content?.meta?.schema?.mode === 'manual' && page?.content?.meta?.schema?.code) {
    const schemaData = isValidJson(page?.content?.meta?.schema?.code);
    if (schemaData) {
      jsonLdData.push(schemaData);
    }
  } else if (
    page?.content?.content_slots?.a?.sections?.[0]?.grid?.items?.[0]?.components?.[0]?.type === 'advice_intro'
  ) {
    jsonLdData.push(useArticle(page));
  }

  if (jsonLdData.length) {
    script.push({
      key: 'ld-json-page-meta',
      type: 'application/ld+json',
      innerHTML: jsonLdData.length > 1 ? jsonLdData : jsonLdData[0],
    });
  }

  useHead({
    title,
    meta,
    link,
    script,
    htmlAttrs: {
      lang: isoLang,
    },
  });
};
