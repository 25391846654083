<template>
  <main
    :class="{
      'page-main--loading': mainStore.isLoading,
    }"
    class="page-main"
    data-allow-mismatch="class"
  >
    <client-only>
      <fn-loading-overlay v-if="mainStore.isLoading" />
    </client-only>

    <slot />
  </main>
</template>

<script setup lang="ts">
const mainStore = useMainStore();
</script>
