<template>
  <component
    :is="linkTag"
    :class="[
      {
        'button--secondary': props.secondary,
        'button--secondary-alt': props.secondaryAlt,
        'button--tertiary': props.tertiary,
        'button--tertiary-alt': props.tertiaryAlt,
        'button--quaternary': props.quaternary,
        'button--quaternary-alt': props.quaternaryAlt,
        'button--quinary': props.quinary,
        'button--quinary-alt': props.quinaryAlt,
        'button--warning': props.warning,
        'button--bubble': props.bubble,
        'button--border-bold': props.borderBold,
        'button--disabled': props.disabled || props.showDisabled,
        'button--disabled-green': props.disabledGreen,
        'button--icon': selectedIcon,
        'button--icon-only': !hasSlot,
        'button--icon-centered': props.iconPosition === 'center',
        'button--icon-right': props.iconPosition === 'right',
        'button--full-width': props.fullWidth,
        'button--outline-white': props.outlineWhite,
        'button--outline-black': props.outlineBlack,
        'button--plain': props.plain,
        'button--pill': props.pill,
        'button-rebrand button--white-rebrand-orange': props.whiteRebrandOrange,
        'button-rebrand button--white-rebrand-blue': props.whiteRebrandBlue,
        'button-rebrand button--white-rebrand-red': props.whiteRebrandRed,
        'button-rebrand button--white-rebrand-green': props.whiteRebrandGreen,
        'button--active': props.active,
        'button--orientation-green': props.orientationGreen,
        'button--green-dark': props.darkGreen,
        'button--green-dark-alt': props.darkGreenAlt,
        'button--large': size === 'large',
      },
      props.status ? `button--status-${props.status}` : '',
    ]"
    :data-id="id"
    :disabled="props.disabled || props.disabledGreen"
    :href="linkHref"
    :target="linkTarget"
    :to="linkTo"
    :type="linkButtonType"
    class="button"
    tabindex="0"
    @click="handleClick"
    @mousedown.middle="handleMiddleClick"
  >
    <span
      v-if="arrow"
      class="button-arrow"
      v-html="arrow"
    />
    <fn-icon
      v-if="selectedIcon"
      class="button-icon"
      :icon="selectedIcon"
      :size="props.iconSize"
    />
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { RouteLocation } from 'vue-router';

export type ButtonProps = {
  href?: string;
  to?: string | RouteLocation;
  target?: string;
  darkGreen?: boolean;
  disabled?: boolean;
  disabledGreen?: boolean;
  showDisabled?: boolean;
  secondary?: boolean;
  secondaryAlt?: boolean;
  tertiary?: boolean;
  tertiaryAlt?: boolean;
  quaternary?: boolean;
  quaternaryAlt?: boolean;
  quinary?: boolean;
  quinaryAlt?: boolean;
  warning?: boolean;
  bubble?: boolean;
  submit?: boolean;
  size?: string;
  arrowBack?: boolean;
  icon?: Array<string> | string;
  iconSize?: string;
  iconPosition?: string;
  status?: string;
  id?: string;
  fullWidth?: boolean;
  outlineWhite?: boolean;
  outlineBlack?: boolean;
  pill?: boolean;
  plain?: boolean;
  static?: boolean;
  whiteRebrandOrange?: boolean;
  whiteRebrandBlue?: boolean;
  whiteRebrandRed?: boolean;
  whiteRebrandGreen?: boolean;
  orientationGreen?: boolean;
  active?: boolean;
  borderBold?: boolean;
};

export type LinkEmits = {
  (name: 'click', event: PointerEvent): void;
  (name: 'priorityClick', event: PointerEvent): void;
};

const emit = defineEmits<LinkEmits>();
const props = withDefaults(defineProps<ButtonProps>(), {
  href: undefined,
  to: undefined,
  target: undefined,
  darkGreen: undefined,
  darkGreenAlt: undefined,
  disabled: undefined,
  disabledGreen: undefined,
  showDisabled: undefined,
  secondary: undefined,
  secondaryAlt: undefined,
  tertiary: undefined,
  tertiaryAlt: undefined,
  quaternary: undefined,
  quaternaryAlt: undefined,
  quinary: undefined,
  quinaryAlt: undefined,
  warning: undefined,
  bubble: undefined,
  submit: undefined,
  size: 'medium',
  arrowBack: undefined,
  icon: undefined,
  iconSize: 's',
  iconPosition: 'left',
  status: undefined,
  id: undefined,
  fullWidth: undefined,
  outlineWhite: undefined,
  outlineBlack: undefined,
  pill: undefined,
  plain: undefined,
  static: undefined,
  whiteRebrandOrange: undefined,
  whiteRebrandBlue: undefined,
  whiteRebrandRed: undefined,
  whiteRebrandGreen: undefined,
  orientationGreen: undefined,
  active: undefined,
  borderBold: undefined,
});

const linkTarget = computed(() => props.target || undefined);
const { linkTag, linkTo, linkHref, linkButtonType } = useLinks(props, 'button');
const slots = useSlots();

const { handleClick, handleMiddleClick } = useHideFromCrawl(props, emit);

const selectedIcon = computed(() => {
  if (props.status === 'success-new') {
    return 'check';
  }

  if (props.status === 'success') {
    return 'checkmark-filled';
  }

  if (props.status === 'error') {
    return 'alert';
  }

  if (props.status && ['loading-new', 'loading'].includes(props.status)) {
    return '';
  }

  if (props.status === 'search') {
    return 'magnifying-glass-bold';
  }

  return props.icon;
});

const arrow = computed(() => {
  if (props.arrowBack) {
    return '&lsaquo;&nbsp;';
  }

  return '';
});

const hasSlot = computed(() => {
  return hasSlotContent(slots.default);
});
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  vertical-align: middle;
  width: auto;
  min-height: 40px;
  margin: 0;
  padding: 8px 20px;
  background-color: $color-action-background-primary-default-base;
  background-image: none;
  border: 0;
  border-radius: map-get($border-radiuses, 'full');
  outline: 0;
  color: $color-action-text-inverse-default-base;
  font-size: map-get($font-sizes, 'l');
  font-weight: 600;
  font-family: $font-primary;
  line-height: 1.3;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  transition:
    0.2s ease-in-out background-color,
    0.2s ease-in-out color;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  pointer-events: all;

  // Target all events from childs to parent
  * {
    pointer-events: none;
  }

  &:hover,
  &:active {
    background-color: $color-action-background-primary-default-hover;
  }

  &:focus-visible {
    background-color: $color-action-background-primary-default-base;
    outline: 4px solid $color-action-border-primary-default-hover;
  }

  &.button--large {
    height: 48px;
  }

  &.button--secondary,
  &.button--secondary-alt {
    @include new-button--primary;
  }

  &.button--tertiary,
  &.button--tertiary-alt {
    @include new-button--secondary;
  }

  &.button--quaternary,
  &.button--quaternary-alt {
    @include new-button--secondary;
  }

  &.button--quinary,
  &.button--quinary-alt {
    @include new-button--neutral-inverted;
  }

  &.button--warning {
    background-color: palette(happyColor, orange);
    color: $color-general-text-inverse-default;

    &:hover {
      background-color: palette(orange);
    }

    &:focus,
    &:active {
      background-color: palette(orange);
    }
  }

  &.button--bubble {
    padding: 18px $pad * 1.25;
    background-color: $color-general-background-neutral-default;
    border: 1px solid palette(grey, metallic);
    border-radius: map-get($border-radiuses, 'xl');
    color: palette(grey, dark);
    font-weight: map-get($font-weights, 'normal');
    font-size: map-get($font-sizes, 'm');
    line-height: 1;

    &:focus,
    &:active,
    &.active {
      background-color: $color-brand-fnmz-green;
      color: $color-general-text-inverse-default;
    }
  }

  &.button--status-success {
    background-color: $color-action-background-primary-default-press;
  }

  &.button--status-error {
    background-color: palette(grey, dark);
  }

  &.button--status-loading {
    background-color: $color-action-background-primary-default-disabled;
    border: 2px solid $color-action-border-primary-default-base;
    padding-left: 40px;
    min-width: 40px;
    cursor: default;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 26px;
      height: 26px;
      top: 0;
      left: 5px;
      bottom: 0;
      margin: auto 0;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg enable-background='new 0 0 40 40' version='1.1' viewBox='0 0 40 40' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='m20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-1e-3 -8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634s11.633 5.209 11.633 11.634c0 6.426-5.208 11.634-11.633 11.634z' opacity='.2'/%3E%3Cpath fill='white' d='m26.013 10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012v3.312c2.119 0 4.1 0.576 5.812 1.566z'%3E%3CanimateTransform attributeName='transform' attributeType='xml' dur='0.5s' from='0 20 20' repeatCount='indefinite' to='360 20 20' type='rotate'/%3E%3C/path%3E%3C/svg%3E%0A");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  @keyframes preloader {
    0% {
      rotate: 0deg;
    }

    100% {
      rotate: 360deg;
    }
  }

  &.button--status-loading-new {
    background-color: palette(orientation, green);
    color: $color-general-text-inverse-default;
    padding-left: 40px;
    cursor: default;
    pointer-events: none;
    opacity: 0.4;

    &:before {
      font-family: 'Icons';
      content: map_get($icons, preloader);
      position: absolute;
      display: block;
      top: -1px;
      left: 5px;
      font-size: 40px;
      animation: preloader 2s infinite ease-in-out;
    }
  }

  &.button--icon-only {
    padding: 0 10px;
  }

  &:not(.button--icon-only) {
    .button-icon {
      margin: 0 $pad * 0.5 0 0;
    }
  }

  &.button--icon-centered:not(.button--icon-only) {
    display: flex;
    justify-content: center;
  }

  &.button--icon-right:not(.button--icon-only) {
    grid-template-columns: 1fr min-content;

    .button-icon {
      order: 1;
      margin: 0 0 0 $pad * 0.5;
    }
  }

  &.button--outline-white {
    @include new-button--neutral;
  }

  &.button--outline-black {
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: transparent;
    border: 1px solid palette(grey, light);
    color: palette(black);
    font-weight: normal;

    &:hover {
      background-color: rgba(palette(black), 0.1);
    }

    &:focus,
    &:active {
      background-color: rgba(palette(black), 0.2);
    }

    &:disabled {
      opacity: 1;
      background-color: palette(grey, light);
      color: palette(grey);
    }
  }

  &.button--tracking {
    border: 1px solid $color-white;
    margin: 0 auto;
    display: block;
    font-weight: bold;
  }

  &.button--full-width {
    width: 100%;
  }

  &.button--border-bold {
    border-width: 2px;
  }

  &.button--orientation-green {
    background-color: palette(orientation, green);
    color: $color-general-text-inverse-default;
  }

  &.button--green-dark {
    background-color: $color-brand-fnmz-green;
    color: $color-general-text-inverse-default;
    &:hover {
      background-color: palette(green, darker);
    }
  }

  &.button--green-dark-alt {
    background-color: $color-general-background-neutral-default;
    border: 2px solid $color-brand-fnmz-green;
    color: $color-action-text-primary-default-base;
    &:hover {
      background-color: $color-brand-fnmz-green;
      color: $color-general-text-inverse-default;
    }
  }

  &.button--disabled {
    &,
    &:hover {
      background-color: rgba(palette(black), 0.15);
      color: $color-general-text-inverse-default;
      cursor: default;
    }
  }

  &.button--disabled-green {
    &,
    &:hover {
      background-color: rgba(palette(green, light), 0.4);
      color: $color-general-text-inverse-default;
      cursor: default;
    }
  }

  &:disabled,
  &:disabled[disabled] {
    pointer-events: none;
  }

  &.button-rebrand {
    padding: $pad $space;
    border-radius: map-get($border-radiuses, 'xl');

    &:hover {
      background-color: $color-general-background-neutral-default;
    }

    &:focus,
    &:active {
      background-color: $color-general-background-neutral-default;
    }
  }

  &--white-rebrand-orange {
    background-color: $color-general-background-neutral-default;
    color: palette(happyColor, orange);
    font-size: $font-size-rebranding;
  }

  &--white-rebrand-blue {
    background-color: $color-general-background-neutral-default;
    color: palette(happyColor, blue);
    font-size: $font-size-rebranding;
  }

  &--white-rebrand-red {
    background-color: $color-general-background-neutral-default;
    color: palette(happyColor, red);
    font-size: $font-size-rebranding;
  }

  &--white-rebrand-green {
    background-color: $color-general-background-neutral-default;
    color: palette(happyColor, green);
    font-size: $font-size-rebranding;
  }

  &--pill {
    border-radius: map-get($border-radiuses, 'pill');
  }
}

.button--plain {
  @include new-button--plain;
}
</style>
