export function deepClone<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export function deepMerge<T extends Record<string, any>, S extends Record<string, any>>(target?: T, source?: S): T & S {
  const t = { ...(target || {}) };
  const s = { ...(source || {}) };

  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(s)) {
    if (s[key] instanceof Object) {
      Object.assign(s[key], deepMerge(t[key], s[key]));
    }
  }

  return Object.assign(t, s) as T & S;
}

export const deepSort = (value: Record<string, any>) =>
  Object.keys(value)
    .sort()
    .reduce(
      (accumulator, key): Record<string, any> => ({
        ...accumulator,
        [key]: typeof value[key] === 'object' ? deepSort(value[key]) : value[key],
      }),
      {},
    );

const truthyValues = [true, '1', 'true', 'on', 'yes'];

export function isTruthy(value?: string) {
  if (!value) {
    return false;
  }

  return truthyValues.includes(value);
}

/**
 * Guard '[Vue warn]: Property "x" was accessed during render but is not defined on instance' for legacy compatibility.
 * @returns Function to check reactive props object for existence and boolean truthiness.
 * @example
 *  const hasProp = useHasProp(props);
 *  console.log(hasProp('someProp'))
 */
export const createHasProp = (props: Record<string, any>) => (propName: string) => propName in props && props[propName];

export const conditionalSplitArray = (array: Array<any>, conditionalFn: (item: any) => boolean) => {
  const splitArrays = {
    left: [] as Array<any>,
    right: [] as Array<any>,
  };

  array.forEach((item) => {
    splitArrays[conditionalFn(item) ? 'left' : 'right'].push(item);
  });

  return [splitArrays.left, splitArrays.right];
};
