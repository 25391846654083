export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, () => {
  if (useMainStore().isAppview) {
    return;
  }

  const userStore = useUserStore();

  // Load tealium audiences, skip awaiting to not block the page
  void userStore.loadTealiumAudiences();
  void userStore.loadAbTests();
});
