import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class AddressApi extends AbstractHybrisApi {
  all(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/addresses`,
      params: {
        fields: 'FULL',
      },
    });
  }

  find(userId, addressId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/addresses/${addressId}`,
      params: {
        fields: 'FULL',
      },
    });
  }

  create(userId, data) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/addresses`,
      params: {
        fields: 'FULL',
      },
      data,
    });
  }

  update(userId, addressId, data) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/addresses/${addressId}`,
      data,
    });
  }

  patch(userId, addressId, data) {
    return this.request({
      method: 'patch',
      version: 'v2',
      url: `/users/${userId}/addresses/${addressId}`,
      data,
    });
  }

  delete(userId, addressId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/addresses/${addressId}`,
    });
  }

  verify(userId, data) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/addresses/verification`,
      params: {
        fields: 'FULL',
      },
      data,
    });
  }
}
