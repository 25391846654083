export const PET_TYPE_DOG = 'DOG';
export const PET_TYPE_PUPPY = 'PUPPY';
export const PET_TYPE_CAT = 'CAT';
export const PET_TYPE_KITTEN = 'KITTEN';
export const PET_TYPE_SMALL_ANIMAL = 'SMALL_ANIMAL';
export const PET_TYPE_SMALL_ANIMAL_OR_RODENT = 'SMALL_ANIMAL_OR_RODENT';
export const PET_TYPE_BIRD = 'BIRD';
export const PET_TYPE_FISH = 'FISH';
export const PET_TYPE_AQUARISTIC = 'AQUARISTIC';
export const PET_TYPE_AQUARISTICS = 'AQUARISTICS';
export const PET_TYPE_TERRARISTIC = 'TERRARISTIC';
export const PET_TYPE_TERRARISTICS = 'TERRARISTICS';
export const PET_TYPE_REPTILE = 'REPTILE';
export const PET_TYPE_GARDEN_AND_POND = 'GARDEN_AND_POND';
export const PET_TYPE_HORSE = 'HORSE';

export const ALL_PET_TYPES = [
  PET_TYPE_DOG,
  PET_TYPE_CAT,
  PET_TYPE_SMALL_ANIMAL,
  PET_TYPE_BIRD,
  PET_TYPE_FISH,
  PET_TYPE_REPTILE,
  PET_TYPE_HORSE,
];

export const ALL_PET_TYPES_FOR_NEWSLETTER = [
  PET_TYPE_DOG,
  PET_TYPE_CAT,
  PET_TYPE_SMALL_ANIMAL_OR_RODENT,
  PET_TYPE_BIRD,
  PET_TYPE_AQUARISTICS,
  PET_TYPE_TERRARISTICS,
];

export const ALL_PET_TYPES_FOR_NEWSLETTER_OBJECT = {
  PET_TYPE_DOG,
  PET_TYPE_PUPPY,
  PET_TYPE_CAT,
  PET_TYPE_KITTEN,
  PET_TYPE_SMALL_ANIMAL_OR_RODENT,
  PET_TYPE_HORSE,
  PET_TYPE_BIRD,
  PET_TYPE_AQUARISTIC,
  PET_TYPE_TERRARISTIC,
  PET_TYPE_GARDEN_AND_POND,
} as const;
