/**
 * @deprecated Should no longer be used, instead rewrite it as a composable or util.
 */
export default defineComponent({
  computed: {
    appliedOrderPromotionNotifications() {
      if (!this.cart?.appliedOrderPromotions) {
        return [];
      }

      const notifications = [];

      for (let i = 0; i < this.cart.appliedOrderPromotions.length; i += 1) {
        const promotion = this.cart.appliedOrderPromotions[i];

        if (promotion.description) {
          notifications.push(promotion.description);
        }
      }

      return notifications;
    },
  },
});
