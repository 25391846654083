import { useAxios } from '@/utils/axios/useAxios';

// Important: Create a single shared axios instance on application startup, not inside defineNuxtPlugin
const axiosInstance = useAxios();

export default defineNuxtPluginWithTiming(import.meta.url, () => {
  return {
    provide: {
      axios: axiosInstance,
    },
  };
});
