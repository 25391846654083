export const TEMPLATE_NONE = 'none';
export const TEMPLATE_PET_FUNERAL = 'pet_funeral';
export const TEMPLATE_PET_FUNERAL_BOOKING = 'pet_funeral_booking';
export const TEMPLATE_GOLDENER_FRESSNAPF = 'goldenerFressnapf';
export const TEMPLATE_SALON = 'salon';
export const TEMPLATE_FLEXIBLE_LAYOUT = 'flexible-layout';
export const TEMPLATE_DEFAULT = 'default';
export const TEMPLATE_PRODUCT_LISTING = 'product-listing';
export const TEMPLATE_PRODUCT_DETAIL = 'product-detail';
export const TEMPLATE_CATEGORY = 'category';
