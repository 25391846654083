import type { MaybeRef } from 'vue';

/**
 * Checks if we are on one of the passed URLs, with proper lang prefix and slash handling.
 *
 * @param path One or multiple paths to check against the current url.
 * @param isI18n When true the function expects i18n keys as paths.
 */
export const useIsPageUrl = (path: MaybeRef<string | string[]>, isI18n = true) => {
  const { $i18n } = useNuxtApp();
  const currentPath = useGetPageUrl({ fullPath: false });

  return computed(() => {
    const pathValue = unref(path);
    const paths = Array.isArray(pathValue) ? pathValue : [pathValue];

    for (const pathCompare of paths) {
      if (!pathCompare) {
        return false;
      }

      if (isI18n) {
        return currentPath.value === $i18n.t(pathCompare);
      }

      if (currentPath.value === pathCompare) {
        return true;
      }
    }

    return false;
  });
};
