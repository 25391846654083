<template>
  <div
    class="address"
    data-qa-id="address"
    data-hj-suppress
  >
    <template v-if="withName"> {{ addressName }}<br /> </template>
    <span
      v-if="address?.remarks && !address.pickUpStation"
      data-hj-suppress
    >
      {{ address.remarks }}<br />
    </span>
    {{ addressLines }}<br />
    {{ address.postalCode }} {{ address.town }}<br />
    <template v-if="address.country && address.country.name && !withoutCountry">
      {{ address.country.name }}
    </template>
  </div>
</template>

<script>
export default defineComponent({
  props: {
    address: {
      type: Object,
      required: true,
    },
    withName: {
      type: Boolean,
      default: false,
    },
    withoutCountry: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addressName() {
      return this.address.pickUpStation
        ? this.address.pickUpStationName
        : `${this.address?.firstName || ''} ${this.address?.lastName || ''}`.trim();
    },

    addressLines() {
      return this.address.pickUpStation
        ? `${this.address?.line2 || ''} ${this.address?.line1 || ''}`.trim()
        : `${this.address?.line1 || ''} ${this.address?.line2 || ''}`.trim();
    },
  },
});
</script>
