import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartApi extends AbstractHybrisApi {
  all(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts`,
    });
  }

  create(userId, lang = null, oldCartId = null, toMergeCartGuid = null, fields = 'DEFAULT', abTests = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts`,
      params: {
        fields,
        oldCartId,
        toMergeCartGuid,
        lang,
      },
      abTests,
    });
  }

  find(userId, cartId, fields = 'DEFAULT', lang = null, abTests = null) {
    return this.request({
      method: 'get',
      version: 'v3',
      url: `/users/${userId}/carts/${cartId}`,
      params: {
        fields,
        lang,
      },
      abTests,
    });
  }

  delete(userId, cartId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}`,
    });
  }

  setEmail(userId, cartId, email) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/email`,
      params: {
        fields: 'DEFAULT',
        email,
      },
    });
  }

  recalculate(userId, cartId, abTests = null) {
    return this.request({
      method: 'patch',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/recalculate`,
      abTests,
    });
  }
}
