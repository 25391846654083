import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class NewsletterApi extends AbstractHybrisApi {
  register(data, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/newsletter',
      params: {
        lang,
      },
      data,
    });
  }

  confirm(cryptId) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/newsletter/${cryptId}/confirm`,
    });
  }

  subscription(cryptId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/newsletter/${cryptId}`,
    });
  }

  unsubscribe(cryptId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/newsletter/${cryptId}`,
    });
  }

  update(cryptId, data) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/newsletter/${cryptId}`,
      data,
    });
  }

  status(cryptId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/newsletter/${cryptId}/status`,
    });
  }
}
