import { hash } from 'ohash';
import type { AxiosRequestConfig } from 'axios';

// Used to remove the first and last '/'
const SLASHES_REGEX = /^\/|\/$/g;

/**
 * Generate a consistent cache key.
 */
export const generateCacheKey = (data: any) => hash(data);

/**
 * Generate a consistent page cache key with required parameters.
 */
export const generateCacheKeyPage = (
  fullPath: string,
  page = {} as Record<string, any>,
  custom = {} as Record<string, any>,
) =>
  generateCacheKey({
    id: page?.wordPressPageId,
    rev: page?.revisionId,
    template: page?.content?.template,
    fullPath,
    ...custom,
  });

/**
 * Generate a consistent request cache key with required parameters.
 * @see axios-cache-interceptor/src/util/key-generator.ts Logic copied and adapted from here
 */
export const generateCacheKeyRequest = (req: AxiosRequestConfig) => {
  let { baseURL, url, method } = req;

  // Remove trailing slashes to avoid generating different keys for the "same" final url.
  baseURL = baseURL !== undefined ? baseURL.replace(SLASHES_REGEX, '') : '';
  url = url !== undefined ? url.replace(SLASHES_REGEX, '') : '';
  url = baseURL + (baseURL && url ? '/' : '') + url;

  // Lowercase to avoid generating different keys for the "same" final url.
  method = method !== undefined ? method.toLowerCase() : 'get';

  return generateCacheKey({
    method,
    url,
    params: req.params,
    data: req.data,
  });
};
