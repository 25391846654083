import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class ContentTileApi extends AbstractHybrisApi {
  get(fields = 'DEFAULT', lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/cms/wordpress/contenttiles',
      params: {
        fields,
        lang,
      },
    });
  }
}
