export const PRICE_TYPE_ONLINE = 'ONL';
export const PRICE_TYPE_FRIENDS = 'ZFNP';
export const PRODUCT_ALL_AVAILABLE = 'ALL_AVAILABLE';
export const PRODUCT_NOT_ALL_AVAILABLE = 'NOT_ALL_AVAILABLE';
export const PRODUCT_NO_INFO = 'NO_INFO';
export const PRODUCT_NOT_PARTICIPANT = 'NOT_PARTICIPANT';

const getBaseOption = (variant) => ({
  title: variant.attributeName,
  code: variant.attributeName,
  options: variant.variants,
});

const getNthBaseOption = (variantMatrix, index) => {
  const baseOption = {
    title: null,
    code: null,
    options: [],
  };

  if (!variantMatrix || !variantMatrix[index]) {
    return baseOption;
  }

  const { title, code, options } = getBaseOption(variantMatrix[index]);

  baseOption.title = title;
  baseOption.code = code;
  baseOption.options = options;

  return baseOption;
};

export const getFirstBaseOption = (variantMatrix) => {
  return getNthBaseOption(variantMatrix, 0);
};

export const getSecondBaseOption = (variantMatrix) => {
  return getNthBaseOption(variantMatrix, 1);
};
