import { isTruthy } from '../utils';
import type { RouteLocationNormalizedGeneric } from 'vue-router';
import type { RouterConfig } from '@nuxt/schema';

function shouldScrollToTop(to: RouteLocationNormalizedGeneric) {
  return unref(to)
    .matched.map((page: any) => Object.values(page?.components))
    .flat()
    .some((component: any) => component?.scrollToTop);
}

/* @see https://router.vuejs.org/api/interfaces/RouterOptions.html */
export default <RouterConfig>{
  scrollBehavior(to, _from, savedPosition) {
    // allow to bypass scrolling
    if (isTruthy(to.query.preventScrollToTop?.toString()?.toLowerCase())) {
      return Promise.resolve();
    }

    let position: any = {};

    // savedPosition is only available for popstate navigations (back button)
    if (savedPosition) {
      position = savedPosition;
    } else if (shouldScrollToTop(to) || !to.hash) {
      position = { left: 0, top: 0 };
    }

    return new Promise((resolve) => {
      // Handle scrolling to specific hash
      if (to.hash) {
        const hash = `#${window.CSS.escape(to.hash.substring(1))}`;
        const el = document.querySelector(hash) as HTMLElement;
        if (el) {
          position = { el: hash };

          // Add offset based on scroll-margin-top if present
          const scrollMarginTop = parseFloat(getComputedStyle(el).scrollMarginTop || '0');
          if (scrollMarginTop) {
            position.offset = { y: scrollMarginTop };
          }
        }
      }

      nextTick().then(() => {
        setTimeout(() => {
          resolve(position);
        }, 500);
      });
    });
  },
};
