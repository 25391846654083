// Import manually, as auto-import will not pick it up if we return 'NuxtLink' string
import { NuxtLink } from '#components';
import type { RouteLocation } from 'vue-router';

export type LinksProps = {
  href?: string;
  to?: string | RouteLocation;
  target?: string;
  disabled?: boolean;
  secondary?: boolean;
  static?: boolean;
  hideFromCrawl?: boolean;
  submit?: boolean;
};

export const useLinks = (props: LinksProps, defaultTag = 'a') => {
  const { getUrlPrefix } = useMainStore();

  const path = computed(() => {
    if (typeof props.to === 'string') {
      return props.to;
    } else if (typeof props.to === 'object' && 'path' in props.to) {
      return props.to.path;
    }
    return '';
  });

  const linkTag = computed(() => {
    if (props.static) {
      return 'div';
    }

    // Note: "submit" is used by Anchor, Link, Button and ButtonRound
    if (props.disabled || ('submit' in props && props.submit)) {
      return 'button';
    }

    if (props.hideFromCrawl) {
      return 'span';
    }

    if (props.to) {
      return NuxtLink;
    }

    // Note: "computedHref" is used by Anchor and ButtonRound
    if (props.href || ('computedHref' in props && props.computedHref)) {
      return 'a';
    }
    return defaultTag;
  });

  const multiLangLink = computed(() => {
    if (!getUrlPrefix || !props.to) {
      return null;
    }

    const multilangLink = path.value;
    if (!multilangLink?.startsWith('/')) {
      return null;
    }

    const linkParts = multilangLink.split('/').filter((part) => part !== '');
    if (`/${linkParts[0]}` === getUrlPrefix) {
      return null;
    }

    return getUrlPrefix + multilangLink;
  });

  const linkTo = computed<typeof props.to>(() => {
    return unref(multiLangLink) || props.to || undefined;
  });

  const linkHref = computed(() => {
    if (unref(linkTo) || props.hideFromCrawl) {
      return undefined;
    }

    if (props.href) {
      return props.href;
    }

    if (props.submit) {
      return undefined;
    }

    return '#';
  });

  const linkButtonType = computed(() => {
    return linkTag.value === 'button' ? (props.submit ? 'submit' : 'button') : undefined;
  });

  const linkHrefAttribute = computed(() => {
    if (props.hideFromCrawl) {
      return null;
    }

    return props.href ? 'href' : null;
  });

  return {
    linkTag,
    linkTo,
    linkHref,
    linkHrefAttribute,
    linkButtonType,
    multiLangLink,
  };
};
