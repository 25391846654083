export const OCC_USER_ID_CURRENT = 'current';
export const OCC_USER_ID_ANONYMOUS = 'anonymous';
export const OCC_USER_ID_GUEST = 'guest';

export const OCC_CART_ID_CURRENT = 'current';

export const OCC_CART_ADDRESS_TYPE_DELIVERY = 'delivery';
export const OCC_CART_ADDRESS_TYPE_BILLING = 'billing';

export const OCC_COUPON_INVALID_CODE = 'coupon.invalid.code.provided';
export const OCC_COUPON_NOT_YET_VALID = 'coupon.not.yet.valid';
export const OCC_COUPON_ALREADY_REDEEMED = 'coupon.already.redeemed';
export const OCC_COUPON_EXPIRED = 'coupon.not.active.expired';
