<template>
  <i
    :class="[
      'icon--' + iconName,
      {
        'icon--circled': circled,
        'icon--bordered': bordered,
        'icon--bold': bold,
        'icon--filled': filled,
        [`icon--size-${size}`]: size,
        [`icon--color-${color}`]: color,
        [`icon--bg-color-${backgroundColor}`]: backgroundColor,
      },
    ]"
    :style="{ color: inlineColor, backgroundColor: inlineBackgroundColor }"
    class="icon"
  />
</template>

<script>
import { icons, iconsSizes, colors } from '@/utils/variables';

export default {
  props: {
    customColorCode: {
      type: String,
      default: '',
    },
    icon: {
      type: [Array, String],
      default: () => '',
    },
    size: {
      type: String,
      validator: (value) => {
        if (!value) {
          return true;
        }
        return iconsSizes.includes(value);
      },
      default: '',
    },
    color: {
      type: String,
      validator: (value) => !value || colors.includes(value),
      default: '',
    },
    bordered: {
      type: Boolean,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    customBackgroundColor: {
      type: String,
      default: '',
    },
    bold: {
      type: Boolean,
    },
    circled: {
      type: Boolean,
    },
    filled: {
      type: Boolean,
    },
  },
  computed: {
    iconName() {
      return typeof this.icon === 'object' && this.icon.length ? this.icon[0] : this.icon;
    },
    inlineColor() {
      const dehyphenatedColor = this.customColorCode?.replace(/-/g, '');
      return dehyphenatedColor || null;
    },
    inlineBackgroundColor() {
      const dehyphenatedBackgroundColor = this.customBackgroundColor?.replace(/-/g, '');
      return dehyphenatedBackgroundColor || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  position: relative;
  font-family: 'Icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;

  &.icon--bordered {
    padding: $pad * 0.625;
    border: 1px solid palette(grey, light);
    border-radius: map-get($border-radiuses, 'l');
  }

  &.icon--circled {
    padding: $pad * 0.625;
    border-radius: map-get($border-radiuses, 'half');
    border: 1px solid palette(grey, light);
  }

  &.icon--bold {
    font-weight: map-get($font-weights, 'bold');
  }

  &.icon--filled {
    background: palette(orientation, green);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
  }

  @each $palette, $tones in $palettes {
    @each $tone, $hex in $tones {
      @if $tone == 'base' {
        &.icon--color-#{$palette} {
          color: $hex;
        }
        &.icon--bg-color-#{$palette} {
          background-color: $hex;
        }
      } @else {
        &.icon--color-#{$palette}-#{$tone} {
          color: $hex;
        }
        &.icon--bg-color-#{$palette}-#{$tone} {
          background-color: $hex;
        }
      }
    }
  }

  @each $size, $pixel in $icon-sizes {
    &.icon--size-#{$size} {
      font-size: $pixel;
    }
  }

  @each $icon, $code in $icons {
    &.icon--#{$icon} {
      &:before {
        content: $code;
      }
    }
  }
}
</style>
