export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const mainStore = useMainStore();

  // CMS page loading needs to happen here, so we can set the flexible layout template, footer and header.
  // Loading it on page level is too late, because layout/default.vue will not know about the template early enough.

  // Many pages will load their own CMS page, which may result in duplicate requests.
  // Do not handle 404 errors here! Many pages don't have a CMS page and that's fine.
  await useAsyncData(async () => mainStore.loadPage(to.path));

  useFlexibleLayout(mainStore?.loadedPage);
});
