import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class FileApi extends AbstractHybrisApi {
  async create(fileDataUrl, imageMediaType) {
    const formData = new FormData();
    formData.append('file', fileDataUrl);

    return this.request({
      method: 'post',
      version: 'v2',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: '/upload',
      data: formData,
      params: {
        imageMediaType,
      },
    });
  }

  delete(mediaId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/upload/${mediaId}`,
    });
  }
}
