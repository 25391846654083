export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to) => {
  const { $i18n } = useNuxtApp();
  const userStore = useUserStore();

  if (userStore.getIsAuthenticated && to.path === $i18n.t('url.registration')) {
    return navigateTo($i18n.t('url.account.index'));
  }

  if (userStore.getIsAuthenticated && !userStore.softLogin) {
    return navigateTo('/');
  }
});
