// Add file extension to fix module resolution in /server directory,
// where Vite config (aliases, extensions) is not recognized in Nitro context

/* eslint-disable import/extensions */
import AbstractApi from './AbstractApi.js';

export default class AbstractHybrisApi extends AbstractApi {
  constructor(client, logger, config, isAppview) {
    super(client, logger, config, isAppview);
    this.baseUrl = config.public.hybrisBaseUrl;
    this.basePath = config.public.hybrisApiPath;
    this.baseStore = config.baseStore || '';
  }

  getURL(endpoint, version) {
    const basePath = version ? `/rest/${version}` : this.basePath;
    return `${this.baseUrl}${basePath}/${this.baseStore}${endpoint}`;
  }

  setBaseStore(baseStore) {
    this.baseStore = baseStore;

    return this;
  }
}
