export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    subscriptionRegistration: null,
    subscription: null,
    cryptId: null,
  }),
  actions: {
    async register(payload) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Register subscription...');

      const mainStore = useMainStore();
      const language = mainStore.language.value;

      if (!payload.source) {
        payload.source = 'WEBSHOP';
      }

      if (!payload.recipient?.sessionLanguage) {
        payload.recipient.sessionLanguage = mainStore?.configuration?.language?.available[0]?.isocode;
      }

      return new Promise((resolve, reject) =>
        $api.hybris.subscription
          .register(payload, language)
          .then((response) => {
            $log.debug('Registered subscription entry...');
            this.subscriptionRegistration = payload;
            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while registering subscription.', error);

            return reject(error);
          }),
      );
    },

    async status(payload) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Fetching subscription...');

      const statusApiCall = payload?.isPublic
        ? $api.hybris.subscription.publicStatus(payload.token)
        : $api.hybris.subscription.status();

      return new Promise((resolve, reject) =>
        statusApiCall
          .then((response) => {
            $log.debug('Setting Subscription entry...');

            const newsletterStore = useNewsletterStore();
            newsletterStore.newsletterSubscription = response.data;

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while getting subscription', error);

            return reject(error);
          }),
      );
    },

    async publicUnsubscribe(payload) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Delete subscription...');

      return new Promise((resolve, reject) =>
        $api.hybris.subscription
          .publicUnsubscribe(payload)
          .then((response) => {
            $log.debug('Subscription entry deleted...');
            this.subscription = response;
            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while deleting subscription', error);

            return reject(error);
          }),
      );
    },

    async saveInterests(payload) {
      const { $api, $errorHandler, $log } = useNuxtApp();

      $log.debug('Saving interests...');

      const interestsApiCall = payload.isPublic
        ? $api.hybris.subscription.savePublicInterests(payload.token, payload.interests)
        : $api.hybris.subscription.saveInterests(useUserStore().getType, payload.interests);

      return new Promise((resolve, reject) =>
        interestsApiCall
          .then((response) => {
            $log.debug('Interests saved...');
            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while saving interests', error);

            if (error.response) {
              $errorHandler.handleErrorModal(error);
              return resolve();
            }

            return reject(error);
          }),
      );
    },

    async saveFavoriteStores(payload) {
      const { $api, $errorHandler, $log } = useNuxtApp();

      $log.debug('Saving favorite stores...');

      const storesApiCall = payload.isPublic
        ? $api.hybris.subscription.savePublicFavoriteStores(payload.token, payload.stores)
        : $api.hybris.subscription.saveFavoriteStores(useUserStore().getType, payload.stores);

      return new Promise((resolve, reject) =>
        storesApiCall
          .then(async (response) => {
            $log.debug('Favorite stores saved...');

            await this.status(payload);

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while saving favorite stores', error);

            if (error.response) {
              $errorHandler.handleErrorModal(error);
              return resolve();
            }

            return reject(error);
          }),
      );
    },

    saveProfile(payload) {
      const { $api, $errorHandler, $log } = useNuxtApp();

      $log.debug('Saving subscription profile...');

      const profileApiCall = payload.isPublic
        ? $api.hybris.subscription.savePublicProfile(payload.token, payload.recipient)
        : $api.hybris.subscription.saveProfile(useUserStore().getType, payload.recipient);

      return new Promise((resolve, reject) =>
        profileApiCall
          .then(async (response) => {
            $log.debug('Saved subscription profile...');

            await this.status(payload);

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while saving subscription profile', error);

            if (error.response) {
              $errorHandler.handleErrorModal(error);
              return resolve();
            }

            return reject(error);
          }),
      );
    },

    async update({ data }) {
      const { $log } = useNuxtApp();

      $log.debug('Update subscription entries...');

      const interests = {
        interests: data.interests,
      };

      const favStores = {
        stores: data.stores,
      };

      try {
        const responses = await Promise.all([this.saveInterests(interests), this.saveFavoriteStores(favStores)]);

        $log.debug('Updated subscription entries...');

        return responses;
      } catch (error) {
        $log.error('Error while updating subscription');
        $log.error(error);

        return Promise.reject(error);
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSubscriptionStore, import.meta.hot));
}
