import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartPaymentModeApi extends AbstractHybrisApi {
  all(userId, cartId, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/paymentModes`,
      params: {
        fields: 'FULL',
        lang,
      },
    });
  }

  set(userId, cartId, paymentMode) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/setPaymentMode`,
      params: {
        paymentMode,
      },
    });
  }

  delete(userId, cartId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/paymentdetails`,
    });
  }
}
