export const currentNewsletterIso = (availableLanguages, currentLocale) => {
  if (availableLanguages?.length && currentLocale) {
    if (currentLocale === 'at' || currentLocale === 'lu' || currentLocale === 'ie') {
      return availableLanguages[0].isocode?.toUpperCase();
    }

    return availableLanguages.find((lang) => lang.locale === currentLocale).isocode.toUpperCase();
  }
  return null;
};

export const getUnsubscribeRedirectCca = (route) => {
  const { $config, $i18n } = useNuxtApp();

  if (!$config.public.ccaBaseUrl || !$config.public.unsubscribeApiPath || !route.params.id) {
    return { path: $i18n.t('url.campaign.unsubscribe.error') };
  }

  return `${$config.public.ccaBaseUrl}${$config.public.unsubscribeApiPath}${route.params.id}`;
};

export const getNewsletterStatus = async (cryptId) => {
  const mainStore = useMainStore();
  const subscriptionStore = useSubscriptionStore();
  const ccaSubscriptionApiEnabled = mainStore.getFeature('ccaSubscriptionApiEnabled');

  if (ccaSubscriptionApiEnabled) {
    const { data } = await subscriptionStore.status();
    return data;
  }

  return (await useNuxtApp().$api.hybris.newsletter.status(cryptId))?.data;
};
