import { resourceNames } from '@/utils/analytics';
import type { LocaleObject } from 'vue-i18n-routing';

type DatalayerStateKey = keyof ReturnType<typeof useDatalayerStore>['$state'];
type ResorceNamesKey = keyof typeof resourceNames;

declare const window: Window & {
  pageData: ReturnType<typeof useDatalayerStore>['$state'];
};

const getPagePropertiesLanguage = ($i18n: any, language: string) => {
  const locales = unref($i18n.locales) as Array<LocaleObject>;

  return {
    cntlang: language,
    cntlocale: locales?.find((locale) => locale.code === language)?.language,
  };
};

export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const { $i18n } = useNuxtApp();
  const datalayerStore = useDatalayerStore();
  const userStore = useUserStore();
  const mainStore = useMainStore();

  if (datalayerStore.initParams.initialLoad && !userStore.user) {
    if (datalayerStore.initParams.withUser) {
      if (userStore.getIsAuthenticated) {
        // Filter resources to load with already loaded resources
        const resourceList = Object.keys(resourceNames)
          .map((k) =>
            !datalayerStore.$state[k.toLowerCase() as DatalayerStateKey] ? resourceNames[k as ResorceNamesKey] : null,
          )
          .filter((v) => v !== null);

        await datalayerStore.loadAnalytics(resourceList);
      } else {
        await datalayerStore.loadAnalytics();
      }
    }

    // Prevent unnecessary reloading
    if (import.meta.client && datalayerStore.initParams.initialLoad) {
      datalayerStore.initParams.initialLoad = false;
    }
  }

  // Add extra info to datalayer page
  datalayerStore.addPageProperties({
    pageName: to.fullPath,
    ...getPagePropertiesLanguage($i18n, mainStore.language),
  });

  // Sync datalayer page to window
  if (import.meta.client) {
    window.pageData = toRaw(datalayerStore.$state);
    window.dispatchEvent(new CustomEvent('pageData:updated', { detail: { firstLoad: mainStore.firstLoad } }));
    mainStore.firstLoad = false;
  }
});
