import AbstractHybrisFnApi from '../../AbstractHybrisFnApi';

export default class CustomerApi extends AbstractHybrisFnApi {
  setSoftLogin(userEmail) {
    this.request({
      method: 'put',
      url: '/customer/softlogin',
      params: {
        email: userEmail,
      },
    });
  }
}
