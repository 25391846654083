export const useGetRobots = ({ page }: { page: Record<string, any> }) => {
  const route = useRoute();
  let robotsMeta = page?.content?.meta?.robots;

  // Set defaults
  if (!robotsMeta?.length) {
    robotsMeta = ['index', 'follow'];
  }

  //  Set default checkout and my-account robots
  if ([/\/cart.*/i, /\/checkout.*/i, /\/my-account.*/i].some((regex) => regex.test(route.fullPath))) {
    robotsMeta = ['noindex', 'nofollow'];
  }

  return {
    robots: robotsMeta,
    robotsHasNoIndex: robotsMeta.includes('noindex'),
    robotsContent: useGetRobotsContent(robotsMeta),
  };
};
