/**
 * @deprecated Use `WindowResize` composable instead
 */
export default {
  data: () => ({
    windowWidth: 0,
    windowHeight: 0,
  }),
  mounted() {
    if (!window) {
      return;
    }

    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      if (!window) {
        return;
      }

      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
  },
};
