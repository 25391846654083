<template>
  <div>
    <fn-button
      :id="data.tracking_id ? data.tracking_id : undefined"
      key="button"
      :href="data.file ? data.file : undefined"
      target="_blank"
      secondary
      icon="download"
    >
      {{ data.button_text }}
    </fn-button>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
