import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class Personalization extends AbstractHybrisApi {
  getTopCategories(categories, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/suggestions/categories',
      params: {
        lang,
      },
      data: {
        categories,
      },
    });
  }
}
