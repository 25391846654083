export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const mainStore = useMainStore();
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const { query } = to;

  await Promise.all([
    cartStore.loadCart({ fields: 'FULL' }),
    userStore.getIsAuthenticated ? userStore.loadAddresses() : null,
    !mainStore.shippingCountries.length ? mainStore.loadShippingCountries() : null,
    !mainStore.billingCountries.length ? mainStore.loadBillingCountries() : null,
  ]);

  if (!cartStore.getReachedMinimumOrderValue) {
    return navigateToWithAdobeParam('/cart/', query);
  }
});
