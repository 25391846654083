/**
 * Removes all isocodes from URL and appends current lang isocode at beginning
 */
export function getPathCorrected(pathParts, currentIso, $isocodes) {
  const isoCodes = Object.values($isocodes);
  const pathPartsLangRemoved = pathParts.filter((part) => !isoCodes.includes(part));
  return [currentIso, ...pathPartsLangRemoved];
}

export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to) => {
  const { path, query, hash } = to;

  const { $isocodes } = useNuxtApp();
  const mainStore = useMainStore();

  if (!mainStore.getIsMultilang) {
    return null;
  }

  // Prepare
  const pathPartsCurrent = path.split('/').filter((part) => part !== '');
  const isoCurrent = Object.values($isocodes).find((iso) => iso === pathPartsCurrent[0]);
  const isoCorrected = $isocodes[mainStore.language];
  const pathPartsCorrected = getPathCorrected(pathPartsCurrent, isoCorrected, $isocodes);

  // Conditions
  const hasRouteIso = path.includes(`${isoCorrected}/`);
  const hasCorrectIso = pathPartsCurrent[0] === isoCorrected;
  const pathPartsLengthEquals = pathPartsCorrected.length === pathPartsCurrent.length;

  // Redirect if necessary
  if (!isoCurrent || !hasRouteIso || !hasCorrectIso || !pathPartsLengthEquals) {
    const pathNext = `/${pathPartsCorrected.join('/')}/`;

    if (pathNext === '//') {
      useNuxtApp().$log.info('Potential infinite redirect detected, skipping...');
    } else {
      const routeNext = {
        path: pathNext,
        query,
        hash,
      };

      return navigateTo(routeNext, {
        redirectCode: 302,
      });
    }
  }

  return null;
});
