const getProcessedPath = ({ fullPath, removeLangPrefix }: { fullPath?: boolean; removeLangPrefix?: boolean }) => {
  const $router = useRouter();
  const url = fullPath ? unref($router.currentRoute).fullPath : unref($router.currentRoute).path;

  if (!removeLangPrefix) {
    return url;
  }

  return removeLanguagePrefix(url);
};

/**
 * Get the current page url, enhanced with various commonly used tweaks/toggles.
 *
 * @param encode Returns path as encodeURI result.
 * @param fullPath Returns path as fullPath, including search params and location hash.
 * @param langPrefix Returns path with language prefix.
 */
export const useGetPageUrl = ({
  encode = false,
  fullPath = true,
  removeLangPrefix = true,
}: {
  encode?: boolean;
  fullPath?: boolean;
  removeLangPrefix?: boolean;
} = {}) => {
  return computed(() => {
    const path = getProcessedPath({ fullPath, removeLangPrefix });

    // Encoding is usually only required when passing the path to hybris
    if (encode) {
      return encodeURI(path);
    }

    return path;
  });
};
