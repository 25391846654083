<template>
  <div
    :id="id"
    data-mbox="true"
    :class="{ 'no-content-loaded': !mbox?.data }"
  >
    <Suspense>
      <component
        v-bind="mbox?.data"
        :is="DynamicComponent"
        v-if="DynamicComponent"
        :key="mbox?.data"
      />
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { kebabCase, pascalCase } from 'change-case';

export type AdobeTargetMboxProps = {
  id?: string;
  placement?: string;
};

const importGlob = useComponentLoaderGlob(import.meta.glob('@/components/molecules/adobe/*/*.vue'), {
  fallbackComponent: false,
});
const props = defineProps<AdobeTargetMboxProps>();

const mainStore = useMainStore();

const mbox = computed(() => mainStore.getMbox(props.id));
const DynamicComponent = computed(() => {
  const componentName = unref(mbox)?.type || '';
  return importGlob(`@/components/molecules/adobe/${kebabCase(componentName)}/${pascalCase(componentName)}.vue`);
});

const handleInsertReco = inject('handleInsertReco', () => {}) as Function;

watch(
  mbox,
  () => {
    handleInsertReco(mbox);
  },
  { immediate: true },
);
</script>
