export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const navigationStore = useNavigationStore();

  // Do not remove no-scrolling class when voucher modal is visible
  if (to.query.voucher) {
    return;
  }

  // Don't close off canvas facets. Closing is handled manually there.
  if (navigationStore.getIsOffCanvasMenuActive('facets')) {
    return;
  }

  navigationStore.closeOffCanvasMenu();
});
