<template>
  <div>
    <nuxt-layout>
      <Error400 v-if="error.statusCode === 400" />
      <Error404 v-else-if="error.statusCode === 404" />
      <Error500 v-else />
    </nuxt-layout>
  </div>
</template>

<script setup lang="ts">
import Error404 from '@/components/pages/error/Error404.vue';
import Error400 from '@/components/pages/error/Error400.vue';
import Error500 from '@/components/pages/error/Error500.vue';
import type { NuxtError } from '#app';

defineProps<{ error: NuxtError }>();
</script>
