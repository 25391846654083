import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class OrderApi extends AbstractHybrisApi {
  all(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/orders`,
    });
  }

  orderHistory(userId, fields, olderThanTS, newerThanTS, lang = null) {
    const currentTime = new Date().getTime();

    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/orderhistory`,
      params: {
        fields,
        newerThanTS,
        olderThanTS,
        currentTime,
        lang,
      },
    });
  }

  singleOrder(userId, code, lang = null) {
    return this.request({
      method: 'get',
      version: 'v3',
      url: `/users/${userId}/orderhistory/${code}`,
      params: {
        fields: 'FULL',
        lang,
      },
    });
  }

  receiptHistory(userId, fields, olderThanTS, newerThanTS, lang = null) {
    const currentTime = new Date().getTime();

    return this.request({
      method: 'get',
      version: 'v3',
      url: `/users/${userId}/receipthistory`,
      params: {
        fields,
        newerThanTS,
        olderThanTS,
        currentTime,
        lang,
      },
    });
  }

  singleReceipt(userId, code, orderDateShort, storeNumber, workstationId, lang = null) {
    return this.request({
      method: 'get',
      version: 'v3',
      url: `/users/${userId}/receipthistory/${code}`,
      params: {
        fields: 'FULL',
        orderDateShort,
        storeNumber,
        workstationId,
        lang,
      },
    });
  }

  initialize(userId, cartId, data, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/orders/initialize`,
      params: {
        fields: 'FULL',
        cartId,
        lang,
      },
      data,
    });
  }

  create(userId, cartId, transactionId, data, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/orders`,
      params: {
        fields: 'FULL',
        cartId,
        transactionId,
        lang,
      },
      data,
    });
  }

  find(userId, code) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/orders/${code}`,
      params: {
        fields: 'FULL',
      },
    });
  }

  buyAgainProducts(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/buyagain`,
      params: {
        fields: 'FULL',
      },
    });
  }
}
