<template>
  <picture
    v-if="imageSrc"
    :class="{
      'picture-copyright': copyright,
      'picture--sprite': imageSprite,
    }"
    class="picture"
  >
    <template v-for="(source, index) in imageSources">
      <source
        v-if="source.srcset?.webp"
        :key="`webp-${index}`"
        :data-srcset="lazy ? source.srcset.webp : null"
        :media="source.media"
        :srcset="lazy ? null : source.srcset.webp"
        type="image/webp"
        data-allow-mismatch
      />
      <source
        v-if="source.srcset?.jpeg"
        :key="`jpeg-${index}`"
        :data-srcset="lazy ? source.srcset.jpeg : null"
        :media="source.media"
        :srcset="lazy ? null : source.srcset.jpeg"
        type="image/jpeg"
        data-allow-mismatch
      />
    </template>

    <img
      :alt="alt"
      class="picture-img"
      :class="{
        lazyload: lazy,
        'image--round': roundDisplay,
        'image--rounded-corners': roundedCorners,
        'image--sprite': imageSprite,
        'image--fullsize': backgroundImageFullsize,
        'image--plp': isProductListingPage,
      }"
      :data-src="imageSrc"
      :fetchpriority="fetchpriority || 'auto'"
      :loading="lazy ? 'lazy' : 'eager'"
      :src="lazy ? placeholderImage : imageSrc"
      :title="title"
      :width="imageSize.width"
      :height="imageSize.height"
      :style="{ 'aspect-ratio': `${imageSize.width} / ${imageSize.height}` }"
      data-allow-mismatch
      @lazyloaded="setLoading(false)"
      @load="onLoad"
    />
    <figcaption
      v-if="copyright"
      class="picture-information"
    >
      <p class="picture-copyright">{{ copyright }}</p>
    </figcaption>
  </picture>
</template>

<script>
import { imageService, placeholderImageDataUri } from '@/utils/images';
import WindowResize from '@/mixins/WindowResize';

export default defineComponent({
  mixins: [WindowResize],
  props: {
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    copyright: {
      type: String,
      default: '',
    },
    roundDisplay: {
      type: Boolean,
    },
    src: {
      type: String,
      default: '',
    },
    imageSprite: {
      type: Boolean,
    },
    roundedCorners: {
      type: Boolean,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    transformation: {
      type: String,
      default: '',
    },
    cleanUrl: {
      type: Boolean,
      default: false,
    },
    backgroundImageFullsize: {
      type: Boolean,
    },
    fetchpriority: {
      type: [String, Boolean],
      default: 'auto',
    },
    isProductListingPage: {
      type: Boolean,
    },
    newsletter: {
      type: Boolean,
    },
    newsletterImages: {
      type: Object,
      default: () => ({}),
    },
    imagesBreakpoints: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['loaded'],
  data() {
    return {
      loading: true,
      placeholderImage: placeholderImageDataUri,
    };
  },
  computed: {
    imageTransform() {
      // Single image
      if (!this.imageSprite && !this.newsletter) {
        return this.transformation;
      }

      // Get current sprite image by viewport size
      const sourceSprite = this.imageSources.find(
        (source) => this.windowWidth >= source.min || this.windowWidth <= source.max,
      );

      return sourceSprite?.transformation;
    },

    imageSize() {
      return imageService.getTransformedSize(this.width, this.height, this.imageTransform);
    },

    imageSrc() {
      if (!this.src) {
        return undefined;
      }

      if (!this.cleanUrl) {
        return this.src;
      }

      return this.src.split('?t=')[0];
    },

    imageSources() {
      const hasImagesBreakpoints = Object.keys(this.imagesBreakpoints)?.length;

      // Single image
      if (!this.imageSprite && !this.newsletter && !hasImagesBreakpoints) {
        return [{ srcset: this.sourceSingle }];
      }

      // Sources defined manually, passed in imagesBreakpoints props
      if (hasImagesBreakpoints) {
        return this.sourcesDefault;
      }

      // Multiple image sources
      return this.newsletter ? this.sourcesNewsletter : this.sourcesSprite;
    },

    sourceSingle() {
      const shouldAddTransformation = this.src && !this.src.includes('?t=') && !this.imageSprite;

      // Square images with explicit transformation
      if (shouldAddTransformation && !this.roundDisplay && this.transformation) {
        return this.createSrcSets(this.src, null, this.transformation);
      }

      // Round images with implicit transformation
      if (shouldAddTransformation && this.roundDisplay) {
        if (this.width < 920 && this.width >= 450) {
          return this.createSrcSets(this.src, null, 'cmsimgsqr_450');
        }

        if (this.width < 450) {
          return this.createSrcSets(this.src, null, 'cmsimgsqr_360');
        }

        return this.createSrcSets(this.src, null, 'cmsimgsqr_920');
      }

      // Default, no transformation provided
      return this.createSrcSets(this.src);
    },

    sourcesDefault() {
      return Object.keys(this.imagesBreakpoints).map((breakpoint) => {
        const source = this.imagesBreakpoints[breakpoint];
        const { url, transform } = source;
        const mediaRule = source.rule ? source.rule : 'max';
        return this.createSource(mediaRule, breakpoint, url, null, transform);
      });
    },

    sourcesSprite() {
      return [
        this.createSource('min', 1140, this.src, null, 'cmssprite_l'),
        this.createSource('min', 768, this.src, null, 'cmssprite_m'),
        this.createSource('min', 476, this.src, null, 'cmssprite_s'),
        this.createSource('max', 475, this.src, null, 'cmssprite_s'),
      ];
    },

    sourcesNewsletter() {
      const entries = Object.entries(this.newsletterImages).reverse();

      return entries.map(([breakpoint, source], index) => {
        const isLast = index + 1 === entries.length;
        const mediaRuleType = isLast ? 'max' : 'min';

        return this.createSource(mediaRuleType, breakpoint, source?.default?.url, source?.retina?.url);
      });
    },
  },
  methods: {
    onLoad() {
      this.$emit('loaded');
      this.setLoading(false);
    },

    setLoading(value) {
      this.loading = value;
    },

    createSrcSetUrlParams(transform, format) {
      const params = this.removeEmptyParams({
        f: format,
        t: transform,
      });

      return new URLSearchParams(params).toString();
    },

    createSrcSetUrl(src, params, isRetina = false) {
      // Nuxt optimizes some images into base64 data urls, they must not get params or suffixes.
      if (src?.startsWith('data:')) {
        return src;
      }

      const join = src?.includes('?') ? '&' : '?';
      const suffix = isRetina ? ' 2x' : '';

      return `${src}${join}${params}${suffix}`;
    },

    createSrcSet(defaultSrc, retinaSrc, transform, format) {
      const params = this.createSrcSetUrlParams(transform, format);

      if (defaultSrc && retinaSrc) {
        return `${this.createSrcSetUrl(defaultSrc, params)}, ${this.createSrcSetUrl(defaultSrc, params, true)}`;
      }

      return this.createSrcSetUrl(defaultSrc, params) || this.createSrcSetUrl(defaultSrc, params, true);
    },

    createSrcSets(defaultSrc, retinaSrc = undefined, transform = undefined) {
      return {
        webp: this.createSrcSet(defaultSrc, retinaSrc, transform, 'webp'),
        jpeg: this.createSrcSet(defaultSrc, retinaSrc, transform, 'jpeg'),
      };
    },

    createSource(mediaRuleType, size, src, retinaSrc = undefined, transform = undefined) {
      return {
        srcset: this.createSrcSets(src, retinaSrc, transform),
        media: this.createMediaRule(mediaRuleType, size),
        [mediaRuleType]: size,
        transformation: transform,
      };
    },

    createMediaRule(mediaRuleType, mediaRuleValue) {
      if (!mediaRuleType || !mediaRuleValue) {
        return '';
      }

      return `(${mediaRuleType}-width: ${mediaRuleValue}px)`;
    },

    removeEmptyParams(params) {
      if (!params) {
        return {};
      }

      return Object.fromEntries(Object.entries(params).filter(([, value]) => value != null));
    },
  },
});
</script>

<style lang="scss" scoped>
img {
  display: block;
  height: auto;
  width: 100%;
  max-width: 100%;
  object-fit: cover;

  &.image--round {
    border-radius: 50%;
  }

  &.image--rounded-corners {
    border-radius: map-get($border-radiuses, 'l');
  }

  &.image--fullsize {
    height: 100%;
  }

  &.lazyload,
  &.lazyloading {
    background-color: palette(sand, light);
    background-image: url('#{$img-path}preloader.gif');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50px 50px;
  }
}

.picture-copyright {
  .image--rounded-corners {
    border-radius: map-get($border-radiuses, 'l') map-get($border-radiuses, 'l') 0 0;
  }
}

.picture-information {
  padding: $pad $pad $space $pad;
  background-color: palette(grey, lighter);
  font-size: map-get($font-sizes, m);
  line-height: map-get($font-line-heights, 'xs');

  @include bp(m) {
    font-size: map-get($font-sizes, l);
    line-height: map-get($font-line-heights, 's');
  }

  .picture-copyright {
    color: palette(content, secondary);
  }
}
</style>
