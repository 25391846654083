export const usePaybackStore = defineStore('payback', {
  state: () => ({
    paybackAuthorizationUrl: null,
    paybackSessionToken: null,
    paybackConsents: null,
  }),
  actions: {
    connectAccount({ host, redirectPath }) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Connect payback account....');

      const mainStore = useMainStore();
      const userStore = useUserStore();
      const cartStore = useCartStore();

      const language = mainStore.language;
      const userType = userStore.type;
      const cartId = cartStore?.cart?.id;

      return new Promise((resolve) =>
        $api.hybris.payback
          .connectAccount(userType, cartId, host, language, redirectPath)
          .then(() => {
            $log.debug('Payback Account connected.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while connecting payback account.', error);

            return resolve();
          }),
      );
    },

    disconnectAccount() {
      const { $api, $log } = useNuxtApp();

      $log.debug('Disconnect payback account....');

      const mainStore = useMainStore();
      const userStore = useUserStore();
      const cartStore = useCartStore();

      const language = mainStore.language;
      const userType = userStore?.type;
      const cartId = cartStore?.cart?.cartId;

      return new Promise((resolve) =>
        $api.hybris.payback
          .disconnectAccount(userType, cartId, language)
          .then(() => {
            $log.debug('Payback Account disconnected.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while disconnecting payback account.', error);

            return resolve();
          }),
      );
    },

    loadPaybackAuthorizationUrl({ redirectPath, paybackNumber }) {
      const { $api, $errorHandler, $log } = useNuxtApp();

      $log.debug('Loading new payback authorization url...');

      const mainStore = useMainStore();
      const userStore = useUserStore();
      const cartStore = useCartStore();

      const language = mainStore.language;
      const userType = userStore?.type;
      const cartId = cartStore?.cart?.cartId;

      const host = window.location.origin;

      return new Promise((resolve, reject) =>
        $api.hybris.payback
          .getPaybackAuthorizationUrl(userType, cartId, host, language, paybackNumber, redirectPath)
          .then(async (response) => {
            $log.debug('New Payback authorization url loaded.');

            this.paybackAuthorizationUrl = response.data.url;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading new payback authorization url.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    async openPaybackAuthorizationUrl({ redirectPath, paybackNumber }) {
      const windowReference = window.open();

      await this.loadPaybackAuthorizationUrl({ redirectPath, paybackNumber });

      windowReference.location = this.paybackAuthorizationUrl;
    },

    getPaybackSessionToken({ authCode, host, forceStrong, redirectPath }) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Getting payback session token...');

      const mainStore = useMainStore();
      const userStore = useUserStore();
      const cartStore = useCartStore();

      const language = mainStore.language;
      const userType = userStore?.type;
      const cartId = cartStore?.cart?.cartId;

      return new Promise((resolve, reject) =>
        $api.hybris.payback
          .getSessionToken(userType, cartId, authCode, forceStrong, language, host, redirectPath)
          .then(async (response) => {
            $log.debug('Payback session token set.');

            this.paybackSessionToken = response.data;

            const userStore = useUserStore();
            await userStore.loadUser(null);

            return resolve();
          })
          .catch(async (error) => {
            $log.error('Error while setting payback session token.', error);

            const userStore = useUserStore();
            await userStore.loadUser(null);

            return reject(error);
          }),
      );
    },

    getPaybackConsents() {
      const { $api, $log } = useNuxtApp();

      $log.debug('Getting consents from payback....');

      const mainStore = useMainStore();
      const userStore = useUserStore();

      const language = mainStore.language;
      const userType = userStore?.type;

      return new Promise((resolve) =>
        $api.hybris.payback
          .consents(userType, language)
          .then((response) => {
            $log.debug('Consents loaded successful.');
            this.paybackConsents = response.data;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loaded consents from payback.', error);

            return resolve();
          }),
      );
    },

    updatePaybackMarketingConsent({ accept }) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Updating payback marketing consent....');

      const mainStore = useMainStore();
      const userStore = useUserStore();

      const language = mainStore.language;
      const userType = userStore?.type;

      return new Promise((resolve) =>
        $api.hybris.payback
          .updateMarketingConsent(userType, accept, language)
          .then(async () => {
            $log.debug('Payback marketing consent updated.');
            await this.getPaybackConsents();

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating payback marketing consent.', error);

            return resolve();
          }),
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePaybackStore, import.meta.hot));
}
