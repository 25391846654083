import validate from "/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import _10_45ab_45tests_45global from "/build/middleware/10.abTests.global.js";
import _15_45datalayer_45global from "/build/middleware/15.datalayer.global.ts";
import _20_45page_45loading_45global from "/build/middleware/20.page-loading.global.js";
import _30_45check_45url_45sanitized_45global from "/build/middleware/30.check-url-sanitized.global.js";
import _40_45close_45off_45canvas_45menu_45global from "/build/middleware/40.close-off-canvas-menu.global.js";
import _50_45handle_45app_45urls_45global from "/build/middleware/50.handle-app-urls.global.js";
import _55_45handle_45page_45one_45global from "/build/middleware/55.handle-page-one.global.js";
import _60_45handle_45spelling_45global from "/build/middleware/60.handle-spelling.global.js";
import _65_45handle_45trailing_45slash_45global from "/build/middleware/65.handle-trailing-slash.global.js";
import _70_45multi_45routing_45global from "/build/middleware/70.multi-routing.global.js";
import _85_45user_45global from "/build/middleware/85.user.global.js";
import _90_45user_45agent_45global from "/build/middleware/90.user-agent.global.js";
import manifest_45route_45rule from "/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _10_45ab_45tests_45global,
  _15_45datalayer_45global,
  _20_45page_45loading_45global,
  _30_45check_45url_45sanitized_45global,
  _40_45close_45off_45canvas_45menu_45global,
  _50_45handle_45app_45urls_45global,
  _55_45handle_45page_45one_45global,
  _60_45handle_45spelling_45global,
  _65_45handle_45trailing_45slash_45global,
  _70_45multi_45routing_45global,
  _85_45user_45global,
  _90_45user_45agent_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "anonymous-editor": () => import("/build/middleware/anonymous-editor.js"),
  anonymous: () => import("/build/middleware/anonymous.js"),
  authenticated: () => import("/build/middleware/authenticated.js"),
  "campaign-unsubscribe": () => import("/build/middleware/campaign-unsubscribe.js"),
  "cart-payback-auth": () => import("/build/middleware/cart-payback-auth.ts"),
  "checkout-address": () => import("/build/middleware/checkout-address.js"),
  "checkout-delivery": () => import("/build/middleware/checkout-delivery.js"),
  "checkout-login": () => import("/build/middleware/checkout-login.js"),
  "checkout-payment-redirect-error": () => import("/build/middleware/checkout-payment-redirect-error.js"),
  "checkout-payment": () => import("/build/middleware/checkout-payment.js"),
  "checkout-summary": () => import("/build/middleware/checkout-summary.js"),
  checkout: () => import("/build/middleware/checkout.js"),
  newsletter: () => import("/build/middleware/newsletter.ts"),
  "requires-hard-login": () => import("/build/middleware/requires-hard-login.js"),
  "store-editor": () => import("/build/middleware/store-editor.js")
}