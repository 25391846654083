import AbstractHybrisApi from '@/api/AbstractHybrisApi';
import { OCC_USER_ID_ANONYMOUS } from '@/utils/occ-constants';
import { resourceNames } from '@/utils/analytics';

export default class AnalyticsApi extends AbstractHybrisApi {
  get(userId: string, cartId: string, orderId: string, filterOptions: Array<keyof typeof resourceNames>) {
    if (userId === OCC_USER_ID_ANONYMOUS && !cartId && !orderId) {
      return Promise.resolve({ data: {} });
    }

    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/analytics`,
      params: {
        cartId,
        orderId,
        filterOptions: filterOptions?.join(',') || null,
      },
    });
  }
}
