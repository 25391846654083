/**
 * Methods for saving and retrieving data from localStorage
 * data has to be an Object that is stringified on save and parsed on get
 *
 * Example:
 *
 * saveToLocalStorage('foo', {a: 1});
 * saveToLocalStorage('foo', {b: 2});
 *
 * const result = getFromLocalStorage('foo');
 * result === {a: 1, b: 2};
 */
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '@/utils/localStorage';

export default {
  methods: { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage },
};
