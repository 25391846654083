import lazysizes from 'lazysizes';

export default defineNuxtPlugin(() => {
  watchOnce(
    () => lazysizes.cfg,
    () => {
      lazysizes.cfg.loadMode = 0;
      lazysizes.cfg.expFactor = 0.4;
      lazysizes.cfg.hFac = 1;
    },
    { immediate: true },
  );
});
