// region Payment Modes
export const PAYMENT_MODE_PAYPAL = 'payPal';
export const PAYMENT_MODE_INVOICE = 'invoice';
export const PAYMENT_MODE_BANK = 'bank';
export const PAYMENT_MODE_CREDITCARD = 'creditcard';
export const PAYMENT_MODE_ADVANCE = 'advance';
export const PAYMENT_MODE_CASHONDELIVERY = 'cashOnDelivery';
export const PAYMENT_MODE_P24 = 'p24';
export const PAYMENT_MODE_ADYEN_PREFIX = 'adyen_';
export const PAYMENT_MODE_ADYEN_BANCONTACT = 'adyen_bancontact';
export const PAYMENT_MODE_ADYEN_ONLINEBANKING = 'adyen_p24';
export const PAYMENT_MODE_ADYEN_PAYCONIQ = 'adyen_payconiq';
export const PAYMENT_MODE_ADYEN_CREDITCARD = 'adyen_creditcard';
export const PAYMENT_MODE_ADYEN_TWINT = 'adyen_twint';
export const PAYMENT_MODE_ADYEN_MOBILEPAY = 'adyen_mobilepay';
export const PAYMENT_MODE_ADYEN_BLIK = 'adyen_blik';
// endregion

// region Adyen
export const ADYEN_COMPONENT_CARD = 'card';
export const ADYEN_COMPONENT_BANCONTACT = 'bcmc';
export const ADYEN_COMPONENT_BLIK = 'blik';
export const ADYEN_COMPONENT_ONLINEBANKING_PL = 'onlineBanking_PL';
export const ADYEN_ACTION_QRCODE = 'qrCode';
export const ADYEN_ACTION_3DS = 'threeDS2';
// endregion

export const getAfterpayConfig = (config) => ({
  fingerprinting: {
    script: config.public.afterpayFingerprintingScript,
    clientId: config.public.afterpayFingerprintingClientId,
  },
  directDebit: {
    script: config.public.afterpayDirectDebitScript,
    baseAddress: config.public.afterpayDirectDebitBaseAddress,
  },
});

export const getPaypalConfig = (config) => ({
  fraudnet: {
    script: config.public.paypalFraudnetScript,
    image: `${config.public.paypalFraudnetImage}&s=${config.public.paypalFraudnetIdentifier}`,
    identifier: config.public.paypalFraudnetIdentifier,
  },
});

export const getAdyenConfig = (config) => ({
  clientKey: config.public.adyenClientkey,
  environment: config.public.adyenEnvironment,
});

export const paymentRedirect = ({ method, url = '', params = [] }) => {
  if (method === 'POST') {
    const form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', url);
    form.setAttribute('target', '_self');

    for (const entry of params) {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', entry.key);
      hiddenField.setAttribute('value', entry.value);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
    return;
  }

  window.location.href = url;
};

export const paymentErrorRedirect = (error) => {
  setTimeout(() => {
    const singleError = error?.response?.data?.errors?.length === 1 ? error.response.data.errors[0] : null;

    switch (singleError?.subject) {
      case 'refused':
        window.location.href = '/checkout/payment/?refused=true';
        break;

      case 'cancelled':
        window.location.href = '/checkout/payment/?cancelled=true';
        break;

      default:
        window.location.href = '/checkout/payment/?error=true';
        break;
    }
  }, 2000);
};

export const isAfterpay = (paymentMode) => {
  return [PAYMENT_MODE_INVOICE, PAYMENT_MODE_BANK].includes(paymentMode);
};
