export function getRouteRedirectTo(to, { keepQuery = true, keepQueryOnlyAdobe = false } = {}) {
  if (!keepQuery || !to?.query || (keepQueryOnlyAdobe && !to?.query.adobe_mc)) {
    return to.path;
  }

  const formattedQuery = new URLSearchParams({
    ...to.query,
  });

  return `${to.path}?${formattedQuery.toString()}`;
}

// The external flag on the server is needed to cancel any further code execution, leading to a much faster redirect
export async function navigateToWithAdobeParam(path, query, options = { redirectCode: 303, external: import.meta.server }) {
  return navigateTo(getRouteRedirectTo({ path, query }, { keepQueryOnlyAdobe: true }), options);
}
