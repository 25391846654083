import { sanitizeTextInput } from '@/utils/sanitizer';
import { getLogo } from '@/utils/images';
import { englishWeekdays } from '@/utils/date';
import type {
  WithContext,
  Article,
  FAQPage,
  PetStore,
  LocalBusiness,
  Store,
  Organization,
  VideoObject,
} from 'schema-dts';

function useGetLangPrefixedURL() {
  const mainStore = useMainStore();
  const { $isocodes } = useNuxtApp();
  return mainStore.isMultilang ? `/${$isocodes[mainStore.language as keyof typeof $isocodes]}` : '';
}

function useGetLangPostfixedHost() {
  return `${useNuxtApp().$host}${useGetLangPrefixedURL()}`;
}

export function useFAQPage(items: Record<string, any>[]): WithContext<FAQPage> {
  const { $i18n, $router } = useNuxtApp();
  const { path } = unref($router.currentRoute);
  const langPostfixedHost = useGetLangPostfixedHost();
  const linkHref = `${langPostfixedHost}${path}`;
  const linkText = $i18n.t('buttons.markupHiddenLinkShowMore');

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map((item) => ({
      '@type': 'Question',
      name: item.headline,
      acceptedAnswer: {
        '@type': 'Answer',
        text: `${sanitizeTextInput(item.content)} <a href="${linkHref}">${linkText}</a>`,
      },
    })),
  };
}

export function useLocalBusiness(items: Record<string, any>[]): WithContext<LocalBusiness | Store | PetStore> {
  const { $i18n } = useNuxtApp();
  const langPostfixedHost = useGetLangPostfixedHost();

  const posItems = !items
    ? []
    : items?.map((store) => ({
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        '@id': `${langPostfixedHost}${store.url}#${store.id}`,
        url: `${langPostfixedHost}${store.url}`,
      }));

  return {
    '@context': 'https://schema.org',
    // @ts-ignore next line
    '@type': ['LocalBusiness', 'Store', 'PetStore'],
    '@id': `${langPostfixedHost}${$i18n.t('url.stores.index')}#haspos`,
    hasPos: posItems,
  };
}

function useOrganizationDefaultParam() {
  const { $i18n } = useNuxtApp();
  return $i18n.t('organization.legalName');
}

export function useOrganization(organizationName = useOrganizationDefaultParam()): WithContext<Organization> {
  const { $host } = useNuxtApp();

  const langPostfixedHost = useGetLangPostfixedHost();
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': '#organization',
    name: organizationName,
    url: `${langPostfixedHost}/`,
    logo: `${$host}${getLogo(useMainStore().language).src}`,
  };
}

export function useVideoObject(video: Record<string, any>): WithContext<VideoObject> {
  return {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: video.title,
    description: video.description,
    thumbnailUrl: `https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`,
    embedUrl: `https://youtu.be/${video.id}`,
    uploadDate: new Date().getFullYear().toString(),
    publisher: useOrganization(),
  };
}

export function useFullOrganization() {
  const { $i18n, $host } = useNuxtApp();
  const langPostfixedHost = useGetLangPostfixedHost();

  return {
    '@context': 'https://www.schema.org',
    '@type': 'Organization',
    '@id': '#organization',
    sameAs: $i18n.tm('organization.sameAs'),
    name: $i18n.t('organization.name'),
    url: `${langPostfixedHost}/`,
    legalName: $i18n.t('organization.legalName'),
    address: {
      '@type': 'PostalAddress',
      streetAddress: $i18n.t('organization.address.streetAddress'),
      addressLocality: $i18n.t('organization.address.addressLocality'),
      addressRegion: $i18n.t('organization.address.addressRegion'),
      addressCountry: $i18n.t('organization.address.addressCountry'),
      postalCode: $i18n.t('organization.address.postalCode'),
    },
    logo: {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      caption: $i18n.t('pages.og_sitename'),
      url: `${getLogo(useMainStore().language).src}`,
    },
    foundingDate: $i18n.t('organization.foundingDate'),
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: $i18n.t('organization.contactPoint.telephone'),
      email: $i18n.t('organization.contactPoint.email'),
      contactType: $i18n.t('organization.contactPoint.contactType'),
    },
  };
}

export function useWebSite() {
  const { $i18n } = useNuxtApp();
  const langPostfixedHost = useGetLangPostfixedHost();

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${langPostfixedHost}/`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${langPostfixedHost}${$i18n.t('url.search')}?text={text}`,
      'query-input': 'name=text',
    },
  };
}

export function useProduct(product: Record<string, any>) {
  const { $i18n } = useNuxtApp();

  let description = `
    ${$i18n.t('product.code')} ${product?.code || ''}
    ${product?.summary || ''}
    ${product?.description || ''}
    ${$i18n.t('product.distributingCompany')} ${product?.distributingCompany || ''}
    ${$i18n.t('product.producerNumber')} ${product?.producerNumber || ''}
    ${product?.feedingSuggestions?.[0]?.description || ''}
    ${$i18n.t('product.analyticContentMaterials')} ${product?.contentMaterials || ''}
    ${$i18n.t('product.composition')} ${product?.composition || ''}
  `;

  description = description
    .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags from string
    .replace(/\s+/g, ' ') // Remove multiple whitespaces and newlines from string
    .trim(); // Trim leading and trailing spaces from string

  const langPostfixedHost = useGetLangPostfixedHost();
  let availability = null;
  let brand = null;
  let image = null;
  let category = null;
  let isRelatedTo = null;
  let manufacturer = null;
  let shippingDetails = null;

  if (product.analytics?.availability) {
    switch (product.analytics.availability) {
      case 'IN_STOCK':
        availability = 'http://schema.org/InStock';
        break;
      case 'OUT_OF_STOCK':
        availability = 'https://schema.org/OutOfStock';
        break;
      case 'SOLD_OUT':
      default:
        availability = 'https://schema.org/SoldOut';
        break;
    }
  }

  if (product.images?.length) {
    image = [
      `${product.images[0].url}?t=prod_l`,
      `${product.images[0].url}?t=prod_l`,
      `${product.images[0].url}?t=prod_s`,
    ];
  }

  if (product.analytics?.brand && product.brandUrl) {
    brand = {
      name: product.analytics?.brand,
      url: `${langPostfixedHost}${product.brandUrl}`,
    };

    if (product?.breadCrumbs?.[1]?.title && product?.breadCrumbs?.[1]?.url) {
      category = {
        '@type': 'Thing',
        name: product.breadCrumbs[1].title,
        url: `${langPostfixedHost}${product.breadCrumbs[1].url}`,
      };
    }

    if (product?.variantSelectors) {
      isRelatedTo = product?.variantSelectors[0].variants.map((variant: Record<string, any>) => ({
        '@type': 'Product',
        name: variant.value,
        url: `${langPostfixedHost}${variant.url}`,
      }));
    }

    manufacturer = {
      '@type': 'Organization',
      name: product.distributingCompany,
    };
  }

  if (Object.keys(product?.shippingInformation || {}).length) {
    const defaultTimeUnitCode = 'DAY';
    const defaultTimeMin = 0;
    const defaultTimeMax = 0;

    shippingDetails = {
      '@type': 'OfferShippingDetails',
      shippingSettingsLink: $i18n.te('product.shippingSettingsLink')
        ? `${langPostfixedHost}${$i18n.t('product.shippingSettingsLink')}`
        : undefined,
      shippingRate: {
        '@type': 'MonetaryAmount',
        value: product.shippingInformation?.shippingFee?.value,
        currency: product.shippingInformation?.shippingFee?.currencyIso,
      },
      shippingDestination: {
        '@type': 'DefinedRegion',
        addressCountry: $i18n.t('organization.address.addressCountry'),
      },
      deliveryTime: {
        '@type': 'ShippingDeliveryTime',
        handlingTime: {
          '@type': 'QuantitativeValue',
          minValue: product.shippingInformation?.handlingTimeMin || defaultTimeMin,
          maxValue: product.shippingInformation?.handlingTimeMax || defaultTimeMax,
          unitCode: product.shippingInformation?.handlingTimeUnitCode || defaultTimeUnitCode,
        },
        transitTime: {
          '@type': 'QuantitativeValue',
          minValue: product.shippingInformation?.deliveryTimeMin || defaultTimeMin,
          maxValue: product.shippingInformation?.deliveryTimeMax || defaultTimeMax,
          unitCode: product.shippingInformation?.deliveryTimeUnitCode || defaultTimeUnitCode,
        },
      },
    };
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': `${langPostfixedHost}${product.url}`,
    name: product.analytics?.fullName,
    description,
    image,
    brand,
    sku: product.analytics?.id,
    gtin: product.analytics?.ean,
    category,
    isRelatedTo,
    manufacturer,
    offers: {
      '@type': 'Offer',
      url: `${langPostfixedHost}${product.url}`,
      priceCurrency: product.baseOptions[0]?.selected?.priceData?.currencyIso,
      price: product.baseOptions[0]?.selected?.priceData?.value,
      availability,
      itemCondition: 'https://schema.org/NewCondition',
      seller: useFullOrganization(),
      priceValidUntil: product?.priceValidUntil,
    },
    shippingDetails,
  };
}

export function useItemList(page: Record<string, any>, productList: Record<string, any>) {
  const langPostfixedHost = useGetLangPostfixedHost();
  const itemListElement = productList.products.map((productItem: Record<string, any>, index: number) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: productItem.name,
    url: `${langPostfixedHost}${productItem.url}`,
    image: [productItem.images[0]?.url],
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: page?.pageTitle,
    itemListElement,
  };
}

export function useStore(store: Record<string, any>) {
  const { $i18n, $host } = useNuxtApp();

  let hasOfferCatalog = {};
  let image = [];
  let openingHoursSpecification: Record<string, any>[] = [];
  const langPostfixedHost = useGetLangPostfixedHost();
  const storeUrl = `${langPostfixedHost}${$i18n.t('url.stores.detail', { id: store.partForUrlGen })}`;
  const streetAddress = store.address?.line2 ? `${store.address?.line1} ${store.address?.line2}` : store.address?.line1;

  if (store.posImages.length) {
    image = store.posImages.map((posImage: Record<string, any>) => posImage.url);
  } else {
    image.push($host + getLogo(useMainStore().language).src);
  }

  if (store.openingHours?.weekDayOpeningList?.length) {
    const weekDaySpecifications = store.openingHours.weekDayOpeningList
      .filter((day: Record<string, any>) => day.closed === false)
      .map((day: Record<string, any>, index: number) => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: englishWeekdays[index],
        opens: day.openingTime.formattedHour,
        closes: day.closingTime.formattedHour,
      }));

    openingHoursSpecification = [...weekDaySpecifications];
  }

  if (store.openingHours?.specialDayOpeningList?.length) {
    const specialDaySpecifications = store.openingHours.specialDayOpeningList
      .filter((day: Record<string, any>) => day.closed === false)
      .map((day: Record<string, any>) => ({
        '@type': 'OpeningHoursSpecification',
        opens: day.openingTime.formattedHour,
        closes: day.closingTime.formattedHour,
        validFrom: day.date.slice(0, 10),
        validThrough: day.date.slice(0, 10),
      }));

    openingHoursSpecification = [...openingHoursSpecification, ...specialDaySpecifications];
  }

  if (store.features?.entry?.length) {
    const offerCatalog = store.features?.entry
      .filter((feature: any) => feature.key.startsWith('service_'))
      .map((service: any) => ({
        '@type': 'Offer',
        itemOffered: {
          '@type': 'Service',
          name: service.value,
        },
      }));

    hasOfferCatalog = {
      '@type': 'OfferCatalog',
      name: 'Markt Services',
      itemListElement: [...offerCatalog],
    };
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'Store',
    image,
    '@id': storeUrl,
    name: store.displayName,
    address: {
      '@type': 'PostalAddress',
      streetAddress,
      addressLocality: store?.address?.town,
      postalCode: store?.address?.postalCode,
      addressCountry: store?.address?.country?.isocode,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: store.geoPoint.latitude,
      longitude: store.geoPoint.longitude,
    },
    url: storeUrl,
    priceRange: `${$i18n.t('product.currencySymbol')}${$i18n.t('product.currencySymbol')}`,
    telephone: store.address?.phone,
    openingHoursSpecification,
    hasOfferCatalog,
  };
}

export function useBreadcrumbList(
  page: Record<string, any>,
  store: Record<string, any> | null = null,
  breadcrumbs: Record<string, any> | null = null,
) {
  const { $i18n, $host } = useNuxtApp();
  const langPrefix = useGetLangPrefixedURL();
  const langPostfixedHost = useGetLangPostfixedHost();
  let breadcrumbsList = page?.content?.breadcrumbs?.length ? page.content.breadcrumbs : breadcrumbs;

  // Store detail breadcrumbs preparation
  if (store && breadcrumbsList) {
    const filteredBreadcrumbs = breadcrumbsList.filter((breadcrumb: any) => breadcrumb.url !== '/storefinder/detail/');

    breadcrumbsList = [
      ...filteredBreadcrumbs,
      {
        id: 'custom',
        url: $i18n.t('url.stores.detail', { id: store.partForUrlGen }),
        title: store.displayName,
      },
    ];
  }

  if (breadcrumbsList?.length) {
    //  Add homepage at the beginning of breadcrumbs if it is not there
    if (breadcrumbsList[0].url !== $i18n.t('url.homepage') && breadcrumbsList[0].title !== $i18n.t('url.title')) {
      breadcrumbsList = [
        {
          title: $i18n.t('pages.homepage'),
          url: $i18n.t('url.homepage'),
        },
        ...breadcrumbsList,
      ];
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbsList.map((breadcrumb: Record<string, any>, idx: number) => ({
        '@type': 'ListItem',
        position: idx + 1,
        name: breadcrumb.title,
        item: breadcrumb.url?.startsWith(langPrefix)
          ? `${$host}${breadcrumb.url}`
          : `${langPostfixedHost}${breadcrumb.url}`,
      })),
    };
  }

  return null;
}

export function useArticle(page: Record<string, any>) {
  const { $i18n } = useNuxtApp();
  const contentSlot = page.content?.content_slots?.a?.sections?.[0]?.grid?.items?.[0]?.components?.[0]?.data;
  // url comes with ?t=cmsimg_920 and i need to append the aspect ratios params instead of this
  const imageUrl = contentSlot?.image?.url?.split('?t')[0];
  const langPostfixedHost = useGetLangPostfixedHost();
  const schemaType =
    page.content?.meta?.schema?.mode?.toLowerCase?.() !== 'manual' ? page.content?.meta?.schema?.mode : 'Article';
  const organizationName = $i18n.t('organization.articlePublisher');

  const mainStore = useMainStore();

  const jsonLdData: WithContext<Article> = {
    '@context': 'https://schema.org',
    '@type': schemaType,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${langPostfixedHost}${page.url}`,
    },
    headline: page.metaTitle,
    description: page.metaDescription,
    datePublished: page.onlineFrom,
    keywords: page.keywords || '',
    inLanguage: mainStore.language,
    dateModified: page.content?.dateModified || '',
    publisher: useOrganization(organizationName),
  };

  let articleText = '';
  /**
   * need to iterate through all arrays to dynamically get the text
   * need to get the text from advice_intro and article_headline and text components
   * the text needs to remove \n and also replace double quotes with single quotes
   * to not break the validation for the schema.
   * this is as pretty as it can be
   */
  page.content?.content_slots?.a?.sections.forEach((section: Record<string, any>) => {
    section?.grid?.items.forEach((item: Record<string, any>) => {
      item?.components.forEach((component: Record<string, any>) => {
        if (component?.type === 'advice_intro') {
          articleText += `${component?.data?.headline} ${component?.data?.text}`;
        }

        if (component?.type === 'article_headline' || component?.type === 'text') {
          articleText += ` ${component?.data?.text}`;
        }
      });
    });
    return articleText;
  });

  if (articleText) {
    jsonLdData.articleBody = articleText.replace(/[\r\n]/gm, '').replace(/"/g, "'");
  }

  // the width and height was agreed with Mattias to be added as below for the images with the seo params
  if (imageUrl) {
    jsonLdData.image = [
      {
        '@type': 'ImageObject',
        url: contentSlot.image?.url, // original image url, width and height
        width: contentSlot.image?.width,
        height: contentSlot.image?.height,
      },
      {
        '@type': 'ImageObject',
        url: `${imageUrl}?t=seoimgsqr_527`,
        width: 527,
        height: 527,
      },
      {
        '@type': 'ImageObject',
        url: `${imageUrl}?t=seoimgsqr_100`,
        width: 100,
        height: 100,
      },
      {
        '@type': 'ImageObject',
        url: `${imageUrl}?t=seoimg_937`,
        width: 937,
        height: 527,
      },
      {
        '@type': 'ImageObject',
        url: `${imageUrl}?t=seoimg_703`,
        width: 703,
        height: 527,
      },
    ];
  }

  return jsonLdData;
}
