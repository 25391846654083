import { sanitizePhoneNumber } from '@/utils/sanitizer';
import { getBaseStoreConfig } from '@/utils/baseStoreConfig';

export const ADDRESS_TYPES = {
  DELIVERY: 'DELIVERY',
  PICKUP_STATION: 'PICK_UP_STATION',
};

export type DefaultAddressFormFields = {
  id: string | null;
  firstName: string;
  lastName: string;
  email: string;
  birthday: string | Date | null;
  country: string;
  postcode: string | number;
  city: string;
  street: string;
  houseNumber: string | number;
  addressAddition: string;
  phone: string;
  defaultAddress: boolean;
  billingAddress: boolean;
  shippingAddress: boolean;
  pickUpStation: boolean;
  pickUpStationCode: string;
  pickUpStationName: string;
  storeName?: string;
};

export const getHybrisDate = (dateString: string) => {
  if (!dateString) return null;

  // IE11 does not support input[type=date], so the dateString will not look like YYYY-MM-DD
  if (dateString.match(/\d+\.\d+\.\d+/)) {
    const splitString = dateString.split('.');
    // eslint-disable-next-line no-param-reassign
    dateString = `${splitString[2]}-${splitString[1]}-${splitString[0]}`;
  }

  const date = new Date(dateString);
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}T00:00:00+0000`;
};

export const getFormFieldDate = (dateString: string) => {
  if (!dateString) return null;

  const date = new Date(dateString);
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
};

export const getPhonePrefix = (baseStore: keyof typeof getBaseStoreConfig) => {
  const baseStoreConfigItem = getBaseStoreConfig(baseStore);
  const phoneGroup = baseStoreConfigItem.addressForm.groups.find((group) =>
    group.fields.find((field) => field.code === 'phone'),
  );
  return phoneGroup?.fields[0].prefix;
};

/**
 * Returns the default address form fields object
 */
export const getDefaultAddressFormFields = (): DefaultAddressFormFields => ({
  id: null as string | null,
  firstName: '',
  lastName: '',
  email: '',
  birthday: null as string | null,
  country: '',
  postcode: '',
  city: '',
  street: '',
  houseNumber: '',
  addressAddition: '',
  phone: '',
  defaultAddress: false,
  billingAddress: false,
  shippingAddress: true,
  pickUpStation: false,
  pickUpStationCode: '',
  pickUpStationName: '',
  storeName: '',
});

/**
 * Returns the default shelter address form fields object
 */
export const getDefaultShelterAddressFormFields = () => ({
  ...getDefaultAddressFormFields(),
  legalForm: '',
  associationRegisterNumber: '',
  shelterName: '',
  description: [],
  media: {} as { code?: string; newImage?: File; deleteImage?: boolean },
});

/**
 * Returns the default address form fields object
 */
export const getDefaultUserFormFields = () => ({
  uid: null,
  password: '',
  firstName: '',
  lastName: '',
});

/**
 * Maps a hybris address object to our frontend address form fields object
 */
export const getAddressFormFieldsFromHybrisAddressObject = (addressRef: MaybeRef<Record<string, any>>) => {
  let birthday: string | null = '';

  const address = unref(addressRef);

  if (address.dateOfBirth) {
    const newBirthday = address.dateOfBirth.split('T');
    birthday = getFormFieldDate(newBirthday[0]);
  }

  return {
    id: address.id,
    firstName: address.firstName,
    lastName: address.lastName,
    email: address.email,
    birthday,
    country: address.country.isocode,
    postcode: address.postalCode,
    city: address.town,
    street: address.line1,
    houseNumber: address.line2,
    addressAddition: address.remarks,
    phone: address.phone,
    defaultAddress: address.defaultAddress,
    billingAddress: address.billingAddress,
    shippingAddress: address.shippingAddress,
    pickUpStation: address.pickUpStation,
    pickUpStationCode: address.pickUpStationCode,
    pickUpStationName: address.pickUpStationName,
  };
};

/**
 * Maps our frontend address form fields object to a hybris address object
 */
export const getHybrisAddressObjectFromAddressFormFields = (address: Record<string, any>) => {
  let birthday: string | null = '';
  const phone = sanitizePhoneNumber(address.phone);

  if (address.birthday) {
    birthday = getHybrisDate(address.birthday);
  }

  return {
    visibleInAddressBook: true,
    id: address.id,
    firstName: address.firstName?.trim(),
    email: address.email,
    dateOfBirth: birthday,
    lastName: address.lastName?.trim(),
    line1: address.street?.trim(),
    line2: address.houseNumber?.trim(),
    country: {
      isocode: address.country,
    },
    postalCode: address.postcode?.trim(),
    town: address.city?.trim(),
    remarks: address.addressAddition?.trim() || address?.remarks,
    phone,
    defaultAddress: address.defaultAddress,
    billingAddress: address.billingAddress,
    shippingAddress: address.shippingAddress,
    pickUpStation: address.pickUpStation,
    pickUpStationCode: address.pickUpStationCode,
    pickUpStationName: address.pickUpStationName,
  };
};

/**
 * Returns the user hybris object from our checkout form fields object
 */
export const getHybrisUserObject = (fields: Record<string, any>) => ({
  uid: fields.billingAddress.email,
  password: fields.user.password,
  firstName: fields.billingAddress.firstName,
  lastName: fields.billingAddress.lastName,
});

/**
 * Checks if two hybris addresses are different
 */
export const areHybrisAddressesDifferent = (address1: Record<string, any>, address2: Record<string, any>) => {
  if (!address1 || !address2) {
    return false;
  }

  if (address1.firstName !== address2.firstName) return true;
  if (address1.lastName !== address2.lastName) return true;
  if (address1.postalCode !== address2.postalCode) return true;
  if (address1.town !== address2.town) return true;
  if (address1.line1 !== address2.line1) return true;
  if (address1.line2 !== address2.line2) return true;
  if (address1.country.isocode !== address2.country.isocode) return true;
  if (address1.remarks !== address2.remarks) return true;

  return false;
};

/**
 * Checks if two form addresses are different
 */
export const areFormAddressesDifferent = (address1: Record<string, any>, address2: Record<string, any>) => {
  if (!address1 || !address2) {
    return false;
  }

  if (address1.firstName !== address2.firstName) return true;
  if (address1.lastName !== address2.lastName) return true;
  if (address1.postcode !== address2.postcode) return true;
  if (address1.city !== address2.city) return true;
  if (address1.street !== address2.street) return true;
  if (address1.houseNumber !== address2.houseNumber) return true;
  if (address1.country !== address2.country) return true;
  if (address1.addressAddition !== address2.addressAddition) return true;

  return false;
};

export const getEllipsisEmail = (mail: string, start: number, end: number) => {
  if (!mail) return null;

  let newMail = mail;
  if (newMail?.length > end) {
    newMail = newMail.replace(newMail.substring(start, newMail.length), '...');
  }
  return newMail;
};

export const getDeliveryAddressFromPickupStation = (
  pickupStation: {
    country: { isocode: any; name: any };
    postalCode: any;
    town: any;
    line1: any;
    line2: any;
    pickUpStationCode: any;
    pickUpStationName: any;
  },
  billingAddress: { firstName: any; lastName: any },
) => {
  if (!pickupStation) {
    return null;
  }

  // Some fields are required, but not provided by the pickup station
  return {
    ...getDefaultAddressFormFields(),
    id: null,
    country: pickupStation.country.isocode,
    countryFullName: pickupStation.country.name,
    postcode: pickupStation.postalCode,
    city: pickupStation.town,
    street: pickupStation.line1,
    houseNumber: pickupStation.line2,
    firstName: billingAddress.firstName,
    lastName: billingAddress.lastName,
    pickUpStation: true,
    pickUpStationCode: pickupStation.pickUpStationCode,
    pickUpStationName: pickupStation.pickUpStationName,
  };
};
