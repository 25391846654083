import type { BaseStoreKeys } from '@/utils/baseStoreConfig';
import type { RuntimeConfig } from 'nuxt/schema';

export const languagesByBaseStoreMapping = (config: RuntimeConfig) => ({
  fressnapfAT: 'de',
  fressnapfBP: 'de',
  fressnapfCH: config.public.defaultLangCh as string,
  fressnapfDE: 'de',
  fressnapfLU: 'de',
  maxizooBE: config.public.defaultLangBe as string,
  maxizooFR: 'fr',
  maxizooIE: 'ie',
  maxizooPL: 'pl',
  maxizooDK: 'dk',
});

export const baseStoresUrlMapping = (config: RuntimeConfig) => ({
  [config.public.domainBp as string]: config.public.baseStoreBp,
  [config.public.domainDe as string]: config.public.baseStoreDe,
  [config.public.domainAt as string]: config.public.baseStoreAt,
  [config.public.domainLu as string]: config.public.baseStoreLu,
  [config.public.domainPl as string]: config.public.baseStorePl,
  [config.public.domainFr as string]: config.public.baseStoreFr,
  [config.public.domainDk as string]: config.public.baseStoreDk,
  [config.public.domainBe as string]: config.public.baseStoreBe,
  [config.public.domainCh as string]: config.public.baseStoreCh,
  [config.public.domainIe as string]: config.public.baseStoreIe,
});

export const getDomainsByBaseStore = (config: RuntimeConfig) => ({
  [config.public.baseStoreAt as string]: config.public.domainAt,
  [config.public.baseStoreBp as string]: config.public.domainBp,
  [config.public.baseStoreCh as string]: config.public.domainCh,
  [config.public.baseStoreDe as string]: config.public.domainDe,
  [config.public.baseStoreLu as string]: config.public.domainLu,
  [config.public.baseStoreBe as string]: config.public.domainBe,
  [config.public.baseStoreDk as string]: config.public.domainDk,
  [config.public.baseStoreFr as string]: config.public.domainFr,
  [config.public.baseStoreIe as string]: config.public.domainIe,
  [config.public.baseStoreIt as string]: config.public.domainIt,
  [config.public.baseStorePl as string]: config.public.domainPl,
});

export const getIsocodesByLocale = (config: RuntimeConfig) => ({
  nl_BE: config.public.isoNl,
  fr_BE: config.public.isoFr,
  de_CH: config.public.isoDe,
  fr_CH: config.public.isoFr,
});

export const possibleLanguagesMapping = (config: RuntimeConfig) => ({
  fressnapfCH: [config.public.isoDe, config.public.isoFr],
  maxizooBE: [config.public.isoNl, config.public.isoFr],
});

export const getBaseStore = (config: RuntimeConfig, host: string) => {
  const hostWithoutPort = host.split(':')[0];
  const baseStore = baseStoresUrlMapping(config)[hostWithoutPort] as BaseStoreKeys;

  if (!baseStore) {
    throw createError({
      statusCode: 500,
      statusMessage: `No baseStore found for domain '${host}'.`,
      fatal: true,
    });
  }

  return baseStore;
};

export const getLanguage = (
  baseStore: BaseStoreKeys,
  url: string,
  i18nCookie: string | undefined,
  config: RuntimeConfig,
) => {
  if (!baseStore) {
    return {};
  }

  const possibleLanguages = possibleLanguagesMapping(config);

  const isMultilang = baseStore in possibleLanguages;

  let language: string;

  // Value from cookie i18n_redirected overrules the others
  if (i18nCookie) {
    language = i18nCookie;
  } else {
    language = languagesByBaseStoreMapping(config)[baseStore];

    if (!language) {
      throw createError({
        statusCode: 500,
        statusMessage: `No language found for baseStore '${baseStore}'`,
        fatal: true,
      });
    }

    if (isMultilang) {
      // If url starts with language prefix, use that instead of the default language
      const startOfUrl = url.split('/')[1];

      if (possibleLanguages[<keyof typeof possibleLanguages>baseStore].includes(startOfUrl)) {
        language = `${startOfUrl}_${baseStore.slice(-2)}`;
      }
    }
  }

  return {
    language,
    isMultilang,
  };
};

export const getBaseStoreAndLanguage = (host: string, url: string, i18nCookie: string, config = useRuntimeConfig()) => {
  const baseStore = getBaseStore(config, host);
  const baseStoreConfig = getBaseStoreConfig(baseStore);
  const { language, isMultilang } = getLanguage(baseStore, url, i18nCookie, config);

  return {
    baseStore,
    baseStoreConfig,
    language,
    isMultilang,
  };
};
