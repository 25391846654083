import { resourceNames } from '@/utils/analytics';

export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const path = removeLanguagePrefix(to.path);
  const logoutUrl = useNuxtApp().$i18n.t('url.logout');

  if (path === logoutUrl) {
    return;
  }

  if (useUserStore().getIsAuthenticated) {
    await useDatalayerStore().loadAnalytics([resourceNames.USER, resourceNames.CART, resourceNames.WISHLIST]);
  }
});
