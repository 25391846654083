import { createDateObject } from '../utils/date';

export default {
  data: () => ({
    countdownEnd: false,
    timeLeft: {
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
    },
    interval: null,
  }),
  created() {
    const countdownDate = createDateObject(this.endDate);
    this.timeLeft = this.getTimeLeft(countdownDate);

    if (Object.values(this.timeLeft).every((timeUnit) => timeUnit === '00')) {
      this.countdownEnd = true;
      return;
    }

    if (!import.meta.client) {
      return;
    }

    this.interval = setInterval(() => {
      this.timeLeft = this.getTimeLeft(countdownDate);

      if (Object.values(this.timeLeft).every((timeUnit) => timeUnit === '00')) {
        this.countdownEnd = true;
        clearInterval(this.interval);
      }
    }, 1000);
  },
  beforeUnmount() {
    this.clearInterval();
  },
  methods: {
    getFormattedTimeUnit(timeUnit) {
      return timeUnit.toString().padStart(2, '0');
    },

    /**
     * Calculates the time left until a given countdown date.
     * @param {Date} countdownDate - The countdown date.
     * @returns {Object} - An object containing the formatted time units (day, hour, minute, second).
     */
    getTimeLeft(countdownDate) {
      const now = new Date().getTime();
      const datesDistance = countdownDate - now;

      if (datesDistance <= 1) {
        return {
          day: '00',
          hour: '00',
          minute: '00',
          second: '00',
        };
      }

      const MULTIPLIER_DAYS = 1000 * 60 * 60 * 24;
      const MULTIPLIER_HOURS = 1000 * 60 * 60;
      const MULTIPLIER_MINUTES = 1000 * 60;

      const days = Math.trunc(datesDistance / MULTIPLIER_DAYS);
      const hours = Math.trunc((datesDistance % MULTIPLIER_DAYS) / MULTIPLIER_HOURS);
      const minutes = Math.trunc((datesDistance % MULTIPLIER_HOURS) / MULTIPLIER_MINUTES);
      const seconds = Math.trunc((datesDistance % MULTIPLIER_MINUTES) / 1000);

      return {
        day: this.getFormattedTimeUnit(days),
        hour: this.getFormattedTimeUnit(hours),
        minute: this.getFormattedTimeUnit(minutes),
        second: this.getFormattedTimeUnit(seconds),
      };
    },
    clearInterval() {
      clearInterval(this.interval);
    },
  },
};
