<template>
  <fn-curalate-gallery
    :container-id="containerId"
    :filter="`productId:'${productCode}'`"
    :lazy-load="lazyLoad"
    :locale="locale"
    :data-name="reco_name"
    :data-audience="reco_audience"
  />
</template>

<script>
export default {
  props: {
    containerId: {
      type: String,
      required: true,
    },
    productCode: {
      type: [String, Number],
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    lazyLoad: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/prop-name-casing
    reco_name: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/prop-name-casing
    reco_audience: {
      type: String,
      default: null,
    },
  },
};
</script>
