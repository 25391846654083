<template>
  <div>
    <DynamicComponent v-bind="props" />
  </div>
</template>

<script setup lang="ts">
import { hydrateOnVisible, type HydrationStrategy } from 'vue';

const props = withDefaults(
  defineProps<{
    type: string;
    data: Record<string, any>;
    idPrefix?: string;
    pageMetrics?: Record<string, any>;
    transformation?: string;
    imageOptions?: Array<string>;
    hydrate?: HydrationStrategy;
  }>(),
  {
    idPrefix: '',
    pageMetrics: () => ({}),
    transformation: '',
    imageOptions: () => [],
    hydrate: hydrateOnVisible(),
  },
);

const DynamicComponent = useComponentLoader({
  props,
  hydrate: props.hydrate,
  componentResolver: (name) => import(`./../components/Cms${name}.vue`) as Promise<{ default: Component }>,
  componentNameOverrides: {
    image_text_panel: 'ErrorIntro',
    related_posts_table: 'LinkTeaserList',
    videos: 'VideoGallery',
    actionbox: 'ActionBox',
  },
});
</script>
