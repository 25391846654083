export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const cartStore = useCartStore();
  const userStore = useUserStore();
  const { query } = to;

  await Promise.all([
    cartStore.loadCart({ fields: 'FULL' }),
    cartStore.loadPaymentModes(),
    userStore.getIsAuthenticated ? userStore.loadAddresses() : null,
  ]);

  if (!cartStore.getReachedMinimumOrderValue) {
    return navigateToWithAdobeParam('/cart/', query);
  }

  if (!cartStore.getPhoneNumberIsValid) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getBillingAddress) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getDeliveryAddress) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (cartStore.getDeliveryAddressIsPickupStore) {
    try {
      await userStore.verifyAddress(cartStore.getDeliveryAddress);
    } catch (error) {
      return navigateToWithAdobeParam('/checkout/address/', { ...query, error: 'invalidPickupStation' });
    }
  }

  if (cartStore.getFullPaybackPayment) {
    return navigateToWithAdobeParam('/checkout/summary/', query);
  }

  return null;
});
