<template>
  <component
    :is="headlineType"
    :id="id || undefined"
    :class="[
      { 'heading--superhero': superhero },
      { 'heading--hero': hero },
      { 'heading--centered': centered },
      { 'heading--no-margin-bottom': noMarginBottom },
      { 'heading--half-margin-bottom': halfMarginBottom },
      { 'heading--same-size-mobile': sameSizeMobile },
      color ? 'heading--color-' + color : '',
      icon?.length !== 0 ? 'heading--icon' : '',
      size && size !== 'default' ? size : type,
    ]"
    :style="{ color: customColorCode }"
    class="heading"
  >
    <fn-icon
      v-if="icon?.length"
      :icon="icon"
      :size="iconSize"
    />
    <slot />
  </component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: string;
    superhero?: boolean;
    hero?: boolean;
    size?: string;
    iconSize?: string;
    color?: Array<string> | string;
    id?: string;
    icon?: Array<string> | string;
    centered?: boolean;
    customColorCode?: string;
    noMarginBottom?: boolean;
    halfMarginBottom?: boolean;
    sameSizeMobile?: boolean;
    dataType?: string;
  }>(),
  {
    type: 'div',
    size: undefined,
    iconSize: undefined,
    color: undefined,
    id: undefined,
    icon: undefined,
    customColorCode: undefined,
    dataType: 'div',
  },
);

const headlineType = computed(() => {
  if (props.type === 'headline' || props.type === 'article_headline') {
    return props.dataType;
  }
  return props.type;
});
</script>

<style lang="scss" scoped>
.heading {
  font-family: $font-primary;
  font-weight: bold;
  margin: 0 0 $pad 0;

  &:last-child {
    margin-bottom: 0;
  }

  &.superhero {
    @extend .heading;
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'hero');
    line-height: map-get($font-line-heights, 'hero');

    &.heading--same-size-mobile {
      font-weight: map-get($font-weights, 'black');
      font-size: map-get($font-sizes-headings, 'superhero');
      line-height: map-get($font-line-heights, 'superhero');
    }

    @include bp(m) {
      font-weight: map-get($font-weights, 'black');
      font-size: map-get($font-sizes-headings, 'superhero');
      line-height: map-get($font-line-heights, 'superhero');
    }
  }

  &.hero {
    @extend .heading;
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'xl');
    line-height: map-get($font-line-heights, 'xl');

    &.heading--same-size-mobile {
      font-weight: map-get($font-weights, 'black');
      font-size: map-get($font-sizes-headings, 'hero');
      line-height: map-get($font-line-heights, 'hero');
    }

    @include bp(m) {
      font-weight: map-get($font-weights, 'black');
      font-size: map-get($font-sizes-headings, 'hero');
      line-height: map-get($font-line-heights, 'hero');
    }
  }

  &.heading--icon {
    .icon {
      margin-right: $pad * 0.5;
    }
  }

  &.heading--centered {
    text-align: center;
  }

  &.heading--no-margin-bottom {
    margin-bottom: 0;
  }

  &.heading--half-margin-bottom {
    margin-bottom: $pad * 0.5;
  }

  .picture ~ &,
  .image ~ & {
    margin-top: $pad;
  }

  // Colors
  @each $palette, $tones in $palettes {
    @each $tone, $hex in $tones {
      @if $tone == 'base' {
        &.heading--color-#{$palette} {
          color: $hex;
        }
      } @else {
        &.heading--color-#{$palette}-#{$tone} {
          color: $hex;
        }
      }
    }
  }
}

h1,
.h1 {
  @extend .heading;
  font-family: $font-compressed;
  font-weight: map-get($font-weights, 'black');
  font-size: map-get($font-sizes-headings, 'l');
  line-height: map-get($font-line-heights, 'l');

  &.heading--same-size-mobile {
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'xl');
    line-height: map-get($font-line-heights, 'xl');
  }

  @include bp(m) {
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'xl');
    line-height: map-get($font-line-heights, 'xl');
  }
}

h2,
.h2 {
  @extend .heading;
  font-weight: map-get($font-weights, 'black');
  font-size: map-get($font-sizes-headings, 'm');
  line-height: map-get($font-line-heights, 'm');

  &.heading--same-size-mobile {
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'l');
    line-height: map-get($font-line-heights, 'l');
  }

  @include bp(m) {
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'l');
    line-height: map-get($font-line-heights, 'l');
  }
}

h3,
.h3 {
  @extend .heading;
  font-weight: map-get($font-weights, 'black');
  font-size: map-get($font-sizes-headings, 's');
  line-height: map-get($font-line-heights, 's');

  &.heading--same-size-mobile {
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'm');
    line-height: map-get($font-line-heights, 'm');
  }

  @include bp(m) {
    font-weight: map-get($font-weights, 'black');
    font-size: map-get($font-sizes-headings, 'm');
    line-height: map-get($font-line-heights, 'm');
  }
}

h4,
.h4 {
  @extend .heading;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes-headings, 'xs');
  line-height: map-get($font-line-heights, 'xs');

  &.heading--same-size-mobile {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes-headings, 's');
    line-height: map-get($font-line-heights, 's');
  }

  @include bp(m) {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes-headings, 's');
    line-height: map-get($font-line-heights, 's');
  }
}

h5,
.h5 {
  @extend .heading;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes-headings, 'xxs');
  line-height: map-get($font-line-heights, 'xxs');

  &.heading--same-size-mobile {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes-headings, 'xs');
    line-height: map-get($font-line-heights, 'xs');
  }

  @include bp(m) {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($font-sizes-headings, 'xs');
    line-height: map-get($font-line-heights, 'xs');
  }
}

h6,
.h6 {
  @extend .heading;
  font-weight: normal;
}

// Redesign headings
.h-medium {
  @extend .heading;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($headline, small);
  line-height: 1.2;

  &.heading--same-size-mobile {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($headline, medium);
    line-height: 1.2;
  }

  @include bp(s) {
    font-weight: map-get($font-weights, 'bold');
    font-size: map-get($headline, medium);
    line-height: 1.2;
  }
}
</style>
