export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to) => {
  const { fullPath } = to;
  const { $i18n, $log } = useNuxtApp();

  /* eslint-disable */
  const tagsPattern = /(\\u003c|\\x3c|&#|&lt|%253c|%3c|<)[\s\S]*(%253e|%3e|>)?/im;
  const dataTextPattern = /data:(?:text\/(?:html|css|plain))(?:;charset=(UTF-8|iso-8859-7))?,(?:.*)$/im;
  const dataBinPattern = /data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp|svg\+xml)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,(?:[a-zA-Z0-9\/+\n=]+)$/im;
  /* eslint-enable */

  const result = tagsPattern.test(fullPath) || dataTextPattern.test(fullPath) || dataBinPattern.test(fullPath);

  if (result) {
    $log.debug('Redirecting to homepage.');

    return navigateTo({
      path: $i18n.t('url.homepage'),
    });
  }

  return null;
});
