// Media queries
export const MEDIA_MAX_S = '(max-width: 475px)';
export const MEDIA_MAX_M = '(max-width: 767px)';
export const MEDIA_MAX_L = '(max-width: 1139px)';
export const MEDIA_MIN_S = '(min-width: 476px)';
export const MEDIA_MIN_M = '(min-width: 768px)';
export const MEDIA_MIN_L = '(min-width: 1140px)';

// Numeric values
// See https://www.browserstack.com/guide/ideal-screen-sizes-for-responsive-design
export const BREAKPOINT_MIN_XXS = 300;
export const BREAKPOINT_MIN_S = 475;
export const BREAKPOINT_MIN_M = 767;
export const BREAKPOINT_MIN_L = 1139;
export const BREAKPOINT_MAX_XXS = 301;
export const BREAKPOINT_MAX_XS = 400;
export const BREAKPOINT_MAX_S = 476;
export const BREAKPOINT_MAX_M = 768;
export const BREAKPOINT_MAX_L = 1120;
export const BREAKPOINT_MAX_XL = 1440;
export const BREAKPOINT_MAX_XXL = 1920;

export const BREAKPOINTS_MAX = {
  xxs: BREAKPOINT_MAX_XXS,
  xs: BREAKPOINT_MAX_XS,
  sm: BREAKPOINT_MAX_S,
  md: BREAKPOINT_MAX_M,
  lg: BREAKPOINT_MAX_L,
  xl: BREAKPOINT_MAX_XL,
  xxl: BREAKPOINT_MAX_XXL,
};

export const IFRAME_BREAKPOINTS = {
  height_s: BREAKPOINT_MIN_S,
  height_m: BREAKPOINT_MIN_M,
  height_l: BREAKPOINT_MIN_L,
  height_xxl: BREAKPOINT_MAX_XXL,
};
