import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class ContactFormApi extends AbstractHybrisApi {
  save(formId, entry, recaptchaResponse = null, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/contactform',
      data: {
        formId,
        fieldValues: {
          entry,
        },
        recaptchaResponse,
      },
      params: {
        lang,
      },
    });
  }

  saveWithFiles(userId, fields, files, lang = null) {
    const formData = new FormData();
    // append files
    files.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
    // append contact information
    formData.append('formWsDTOString', JSON.stringify(fields));
    return this.request({
      method: 'post',
      version: 'v2',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `/users/${userId}/contactform`,
      data: formData,
      params: {
        lang,
      },
    });
  }
}
