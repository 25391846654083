import { BREAKPOINT_MAX_M } from '@/utils/mediaQuery';

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  const mainStore = useMainStore();

  // Unfortunately, window.matchMedia is not reliable working when switching between different emulated
  // modes f.ex. desktop <-> mobile device, so when using window.matchMedia we get wrong results:
  // isMobile true on desktop and vice versa
  const mobileBreakpoint = BREAKPOINT_MAX_M;

  // Function to check and update the isMobileView state
  const updateIsMobileView = () => {
    mainStore.isMobileView = window.innerWidth <= mobileBreakpoint;
  };

  updateIsMobileView();

  window.addEventListener('resize', updateIsMobileView);

  window.addEventListener('beforeunload', () => {
    window.removeEventListener('resize', updateIsMobileView);
  });

  nuxtApp.$log.debug('Initialization mobile view finished.');
});
