import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class UserApi extends AbstractHybrisApi {
  find(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}`,
      params: {
        fields: 'FULL',
      },
    });
  }

  create(data, lang = null) {
    const reqData = {
      uid: data.uid,
      password: data.password,
    };

    if (data.registrationRecaptchaResponse) {
      reqData.recaptchaResponse = data.registrationRecaptchaResponse;
    }

    return this.request({
      method: 'post',
      version: 'v2',
      url: '/users',
      params: {
        fields: 'FULL',
        lang,
      },
      data: reqData,
    });
  }

  activateFriends(uid, loyaltyType, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${uid}/loyalty/activate`,
      params: {
        loyaltyType,
        lang,
      },
    });
  }

  deactivateFriends(uid, loyaltyType, lang = null) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${uid}/loyalty`,
      params: {
        loyaltyType,
        lang,
      },
    });
  }

  friendsLightQrCode(uid, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${uid}/cashdeskcode`,
      params: {
        lang,
        includePayback: false,
      },
    });
  }

  getLoyaltyStatus(uid, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${uid}/loyalty`,
      params: {
        lang,
      },
    });
  }

  update(userId, data) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}`,
      data,
    });
  }

  patch(userId, data) {
    return this.request({
      method: 'patch',
      version: 'v2',
      url: `/users/${userId}`,
      data,
    });
  }

  updateEmail(userId, newEmail, password, captcha = null, lang = null) {
    return this.request(
      {
        method: 'put',
        version: 'v3',
        url: `/users/${userId}/login`,
        data: {
          newLogin: newEmail,
          password,
          captcha,
        },
        params: {
          lang,
        },
      },
      false,
    );
  }

  updateGuestEmail(userId, newEmail, oldEmail, lang = null) {
    return this.request({
      method: 'patch',
      version: 'v2',
      url: `/users/${userId}/guest`,
      data: {
        newLogin: newEmail,
        oldLogin: oldEmail,
      },
      params: {
        lang,
      },
    });
  }

  updatePassword(userId, oldPassword, newPassword) {
    return this.request(
      {
        method: 'put',
        version: 'v3',
        url: `/users/${userId}/password`,
        data: {
          newPassword,
          oldPassword,
        },
      },
      false,
    );
  }

  updatePaybackNumber(userId, paybackNumber) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/paybackNumber`,
      params: {
        paybackNumber,
        fields: 'FULL',
      },
    });
  }

  deletePaybackNumber(userId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/paybackNumber`,
      params: {
        fields: 'FULL',
      },
    });
  }

  getTrackingInfo(userId, carrierId, postalCode, trackingId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/tracking`,
      params: {
        carrierId,
        trackingId,
        postalCode,
        fields: 'FULL',
      },
    });
  }

  setFavoriteStore(userId, storeNumber) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/favoriteStore`,
      params: {
        storeNumber,
      },
    });
  }

  resetFavoriteStore(userId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/favoriteStore`,
    });
  }

  getBVKeys(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/bazaarvoice/key`,
      params: {
        fields: 'DEFAULT',
      },
    });
  }

  addSavedPost(userId, pageId) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/savedposts/`,
      params: {
        pageId,
      },
    });
  }

  removeSavedPost(userId, pageId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/savedposts/${pageId}`,
    });
  }

  togglePublishWishlist(userId, publishStatus) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/togglePublishWishList`,
      params: {
        publishStatus,
      },
    });
  }
}
