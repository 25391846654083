import {
  OCC_USER_ID_CURRENT,
  OCC_USER_ID_GUEST,
  OCC_CART_ADDRESS_TYPE_DELIVERY,
  OCC_CART_ADDRESS_TYPE_BILLING,
} from '@/utils/occ-constants';
import { ADDRESS_TYPES, areHybrisAddressesDifferent } from '@/utils/addresses';
import { SHIPPING_TYPE_DROPSHIP, SHIPPING_TYPE_SPEDITION } from '@/utils/shipping';
import { parseGuestEmail } from '@/utils/cart';
import { ageCheck, formatDateStringToYYYYMMDD } from '@/utils/date';
import {
  PAYMENT_MODE_INVOICE,
  PAYMENT_MODE_BANK,
  PAYMENT_MODE_ADYEN_CREDITCARD,
  PAYMENT_MODE_ADYEN_BANCONTACT,
  PAYMENT_MODE_ADYEN_ONLINEBANKING,
} from '@/utils/payment';
import { isResponseError, GENERIC_NOT_FOUND_ERROR, CART_NOT_FOUND_ERROR } from '@/utils/apiErrors';
import {
  saveToLocalStorage,
  getFromLocalStorage,
  deleteFromLocalStorage,
  LOCAL_STORAGE_ADYEN_PAYLOAD,
} from '@/utils/localStorage';
import {
  DELIVERY_ADDRESS_TYPE_STORE,
  DELIVERY_INVOICE,
  DELIVERY_NEW,
  DELIVERY_PL_STATION,
} from '@/utils/deliveryConstants';
import { notifyApp } from '@/utils/communication-bridge';
import { resourceNames } from '@/utils/analytics';
import { useDeliveryHelpers } from '@/composables/deliveryHelpers';
import type { PaymentMode } from '@/types/misc';

export const useCartStore = defineStore('cart', {
  state: () => ({
    loading: false,
    cart: null as Record<string, any> | null | undefined,
    paymentModes: [] as Array<PaymentMode>,
    deliveryModes: [],
    paybackAuthUrl: null as string | null | undefined,
    pickupStations: [],
    deliveryOption: null,
    pickupAddress: {
      fnStore: null,
      station: null,
      query: null,
    },
    displayCompanyAddress: false,
    cartRecalculated: false,
    userCheckoutSteps: {
      stepAddress: false,
      stepPayment: false,
    },
    deliveryTypesStation: [] as string[],
    deliveryTypesStore: [] as string[],
  }),
  getters: {
    getCartId(state) {
      if (!state.cart) {
        return null;
      }

      const userStore = useUserStore();
      return userStore.getType === OCC_USER_ID_CURRENT ? state.getCode : state.getGuid;
    },
    getIsShelterCart(state) {
      return !!state.cart?.shelter;
    },
    getCode(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.code;
    },
    getGuid(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.guid;
    },
    getEmail(state) {
      if (!state.cart?.user) {
        return null;
      }

      return state.cart.user.uid;
    },
    getGuestEmail(state) {
      if (!state.cart?.user?.uid) {
        return null;
      }

      return parseGuestEmail(state.cart);
    },
    getIsGuest(state) {
      if (!state.cart?.user) {
        return null;
      }

      if (state.cart.user.name === OCC_USER_ID_GUEST) {
        return true;
      }

      return !!state.getGuestEmail;
    },
    getDifferentDeliveryAddress(state) {
      return areHybrisAddressesDifferent(state.getDeliveryAddress, state.getBillingAddress);
    },
    getDeliveryAddress(state) {
      if (!state.cart?.deliveryAddress) {
        return null;
      }

      return state.cart.deliveryAddress;
    },
    getDeliveryAddressIsPickupStore(state) {
      return state.getDeliveryAddress?.pickUpStation || state.getDeliveryAddress?.pickUpStationCode;
    },
    getBillingAddress(state) {
      if (!state.cart?.billingAddress) {
        return null;
      }

      return state.cart.billingAddress;
    },
    getCartCookieData(state) {
      return {
        guid: state.cart?.guid,
        code: state.cart?.code,
        user: state.cart?.user,
        totalUnitCount: state.cart?.totalUnitCount,
        deliveryMode: state.cart?.deliveryMode,
      };
    },
    getEntries(state) {
      if (!state.cart) {
        return [];
      }

      return state.cart.entries || [];
    },
    getMerchantEntries(state) {
      if (!state.cart) {
        return [];
      }

      return state.cart.merchantEntries || [];
    },
    getHasSpeditionEntry(state) {
      const entries = state.getEntries;

      if (!entries.length) {
        return false;
      }

      return !!entries.filter(
        (entry: { product: { deliveryType: string } }) =>
          entry.product.deliveryType === SHIPPING_TYPE_SPEDITION ||
          entry.product.deliveryType === SHIPPING_TYPE_DROPSHIP,
      ).length;
    },
    getPhoneNumberMissing(state) {
      const isPickupStationPL = state.getDeliveryMode?.code === DELIVERY_PL_STATION;
      const billingPhoneMissing = !state.getBillingAddress?.phone;
      const deliveryPhoneMissing = !state.getDeliveryAddress?.phone;

      // PuDo PL uses phone number from billing address
      if (isPickupStationPL) {
        return billingPhoneMissing;
      }

      if (!state.getHasSpeditionEntry) {
        return false;
      }

      if (deliveryPhoneMissing) {
        return true;
      }

      return !state.getDeliveryAddress.phone.length;
    },
    getPhoneNumberIsValid(state) {
      const config = useRuntimeConfig();
      const { baseStore } = useMainStore();

      // Currently only for PL
      if (baseStore !== config.public.baseStorePl) {
        return true;
      }

      // Not having a number is considered valid
      // Note: not using "getPhoneNumberMissing" due to spedition check inside
      if (!state.deliveryAddress?.phone) {
        return true;
      }

      return !(state.deliveryAddress.phone.length !== 9 || state.deliveryAddress.phone.startsWith('0'));
    },
    getBirthdayAdult(state) {
      if (!state.getBillingAddress?.dateOfBirth) {
        return false;
      }

      return ageCheck(state.getBillingAddress.dateOfBirth);
    },
    getEntryCount(state) {
      return state.getEntries.length;
    },
    getTotalUnitCount(state) {
      if (!state.cart) {
        return 0;
      }

      return state.cart.totalUnitCount;
    },
    getSubTotal(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.subTotal;
    },
    getDeliveryCost(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.deliveryCost;
    },
    getOrderDiscounts(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.orderDiscounts;
    },
    getTotalDiscounts(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.totalDiscounts;
    },
    getAllDiscounts(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.allDiscounts;
    },
    getFormerPriceTotal(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.formerPriceTotal;
    },
    getOrderSavings(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.orderSavings;
    },
    getProductSavings(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.productSavings;
    },
    getPositionSavings(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.positionSavings;
    },
    getTotalPrice(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.totalPrice;
    },
    getMissingMinimumOrderValue(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.missingMinimumOrderValue;
    },
    getMissingFreeShippingValue(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.missingFreeShippingValue;
    },
    getReachedMinimumOrderValue(state) {
      if (!state.cart) {
        return false;
      }
      if (!state.cart.missingMinimumOrderValue) {
        return true;
      }

      return state.getMissingMinimumOrderValue.value <= 0;
    },
    getAdditionalDeliveryCost(state) {
      if (!state.cart) {
        return false;
      }

      return state.cart.additionalDeliveryCost;
    },
    getMaximumOrderValueExceeded(state) {
      return state?.cart?.maximumOrderValueExceeded;
    },
    getAppliedVouchers(state) {
      if (!state.cart) {
        return [];
      }

      return state.cart.appliedVouchers;
    },
    getAppliedOrderPromotions(state) {
      if (!state.cart) {
        return [];
      }

      return state.cart.appliedOrderPromotions;
    },
    getAppliedProductPromotions(state) {
      if (!state.cart) {
        return [];
      }

      return state.cart.appliedProductPromotions;
    },
    getPaybackAmount(state) {
      if (!state.cart?.paybackAmount) {
        return null;
      }

      return state.cart.paybackAmount;
    },
    getPaybackNumber(state) {
      if (!state.cart?.payback?.paybackNumber) {
        return null;
      }

      return state.cart.payback.paybackNumber;
    },
    getHasPaybackNumber(state) {
      if (!state.cart?.payback?.paybackNumber) {
        return null;
      }

      return state.cart.payback.paybackNumber.length;
    },
    getPaybackTotalPoints(state) {
      if (!state.cart?.payback) {
        return null;
      }

      return state.cart.payback.paybackTotalPoints;
    },
    getPaybackBasicPoints(state) {
      if (!state.cart?.payback) {
        return null;
      }

      return state.cart.payback.paybackBasicPoints;
    },

    getPaybackExtraPoints(state) {
      if (!state.cart?.payback) {
        return null;
      }

      return state.cart.payback.paybackExtraPoints;
    },

    getPaybackAuthorized(state) {
      if (!state.cart?.payback) {
        return null;
      }

      return state.cart.payback.authorized;
    },
    getSuggestedPointsToRedeem(state) {
      if (!state.cart?.payback?.suggestedPointsToRedeem) {
        return null;
      }

      return state.cart.payback.suggestedPointsToRedeem;
    },
    getPointsToRedeem(state) {
      if (!state.cart?.payback?.pointsToRedeem) {
        return null;
      }

      return state.cart.payback.pointsToRedeem;
    },
    getPaybackAvailablePoints(state) {
      if (!state.cart?.payback) {
        return null;
      }

      return state.cart.payback.paybackAvailablePoints;
    },
    getPriceOfCartInPaybackPoints(state) {
      if (!state.cart?.payback) {
        return null;
      }

      return state.cart.payback.priceOfCartInPaybackPoints;
    },
    getPaybackCoupons(state) {
      if (!state.cart?.payback?.paybackCoupons) {
        return [];
      }

      return state.cart.payback.paybackCoupons;
    },
    getFullPaybackPayment(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.fullPaybackPayment;
    },
    getFinishedSteps(state) {
      const steps = [];
      const config = useRuntimeConfig();
      const mainStore = useMainStore();
      const isBasestoreCH = mainStore.baseStore === config.public.baseStoreCh;

      steps.push(1);

      if (state.getDeliveryAddress && state.getBillingAddress) {
        steps.push(2);

        if (isBasestoreCH || state.getPaymentMode) {
          steps.push(3);
        }

        if (state.getPaymentMode) {
          steps.push(4);

          if (isBasestoreCH) {
            steps.push(5);
          }
        }
      }

      return steps;
    },
    getPaymentMode(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.paymentMode;
    },
    getPaymentModes(state) {
      return state.paymentModes;
    },
    getDefaultPaymentMode(state) {
      const defaultPaymentMode = state.paymentModes.find((paymentMode) => paymentMode.selected === true);

      if (defaultPaymentMode) {
        return defaultPaymentMode;
      }

      return null;
    },
    getDeliveryMode(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.deliveryMode;
    },
    getDeliveryModes(state) {
      return state.deliveryModes;
    },
    getPaybackAuthUrl(state) {
      return state.paybackAuthUrl;
    },
    getNewsletterRegistrationData(state) {
      const email = state.getEmail;
      const billingAddress = state.getBillingAddress;
      const guestEmail = state.getGuestEmail;
      const isGuest = state.getIsGuest;

      const mainStore = useMainStore();
      const { language } = mainStore;
      const languageConfig = mainStore.configuration.language;

      if ((!email && !guestEmail) || !billingAddress) {
        return null;
      }

      let sessionLanguage = null;

      if (language && languageConfig?.available?.length === 1) {
        sessionLanguage = languageConfig.available[0].locale?.isocode;
      } else if (language && languageConfig?.available?.length > 1) {
        sessionLanguage = languageConfig.available.find((lang) => lang.locale === language)?.isocode?.toUpperCase();
      }

      return {
        address: {
          emailAddress: isGuest ? guestEmail : email,
        },
        email: '',
        consent: true,
        source: 'checkout',
        recipient: {
          firstname: billingAddress.firstName,
          lastname: billingAddress.lastName,
          birthday: billingAddress.dateOfBirth ? formatDateStringToYYYYMMDD(billingAddress.dateOfBirth) : undefined,
          sessionLanguage,
        },
      };
    },
    getEstimatedDeliveryDay(state) {
      if (!state.cart) {
        return null;
      }

      return state.cart.estimatedDeliveryDay;
    },
    getAdditionalDeliveryCostValue(state) {
      if (!state.cart) {
        return false;
      }
      return state.cart.additionalDeliveryCostValue;
    },
    getRegularDeliveryCostValue(state) {
      if (!state.cart) {
        return false;
      }
      return state.cart.regularDeliveryCostValue;
    },
    getPickupStations(state) {
      return state.pickupStations;
    },
    getPickupAddress(state) {
      return state.pickupAddress;
    },
    getCartDeliveryType(state) {
      const deliveryMode = state.cart?.deliveryMode?.code;
      const { deliveryTypeStorePerCountry, deliveryTypeStationPerCountry } = useDeliveryHelpers();

      const deliveryOptions = [...unref(deliveryTypeStationPerCountry), ...unref(deliveryTypeStorePerCountry)];

      if (deliveryOptions.includes(deliveryMode)) {
        return deliveryMode;
      }
      if (areHybrisAddressesDifferent(state.getDeliveryAddress, state.getBillingAddress)) {
        return DELIVERY_NEW;
      }
      return DELIVERY_INVOICE;
    },
    getEnabledDeliveryAddressTypes(state) {
      return state.cart?.enabledDeliveryAddressTypes || [];
    },
    getIsPickupStationDeliveryAddressTypeEnabled(state) {
      return state.getEnabledDeliveryAddressTypes.includes?.(ADDRESS_TYPES.PICKUP_STATION);
    },
    getIsClickAndCollectDeliveryAddressTypeEnabled(state) {
      return state.getEnabledDeliveryAddressTypes.includes?.(DELIVERY_ADDRESS_TYPE_STORE);
    },
    getCartRecalculated(state) {
      return state.cartRecalculated;
    },
    getHasCheckoutStepsHistory(state) {
      return state.userCheckoutSteps.stepAddress && state.userCheckoutSteps.stepPayment;
    },
    getDisplayCompanyAddress(state) {
      return state.displayCompanyAddress;
    },
    getIsDeliveryToStore(state) {
      const { deliveryTypeStorePerCountry } = useDeliveryHelpers();
      return unref(deliveryTypeStorePerCountry).includes(state.getCartDeliveryType);
    },
    getIsDeliveryToStation(state) {
      const { deliveryTypeStationPerCountry } = useDeliveryHelpers();
      return unref(deliveryTypeStationPerCountry).includes(state.getCartDeliveryType);
    },
  },
  actions: {
    initializeDeliveryTypes() {
      const { deliveryTypeStationPerCountry, deliveryTypeStorePerCountry } = useDeliveryHelpers();

      // Populate the state with computed values from the composable
      this.deliveryTypesStation = unref(deliveryTypeStationPerCountry);
      this.deliveryTypesStore = unref(deliveryTypeStorePerCountry);
    },
    async handleVoucherFromQuery() {
      const { $log, $router } = useNuxtApp();
      const voucherCode = $router.currentRoute.value.query.voucher;

      if (!voucherCode) return;

      $log.debug(`Voucher "${voucherCode}" found in current route.`);

      const mainStore = useMainStore();
      mainStore.voucherAdded = { status: 'loading' };

      if (!this.cart) {
        $log.debug(`No cart present, adding voucher cookie "${voucherCode}"`);

        // Always delete old voucher cookie before setting one, because only one is allowed
        this.removeVoucherCookie();

        // Set voucher cookie
        this.setVoucherCookie({ voucherCode: voucherCode?.toString() });

        return;
      }

      $log.debug(`cart present, adding voucher "${voucherCode}" from current route`);

      await this.loadCart({ fields: 'FULL' });

      // Check if there are vouchers, if yes, delete old first.
      if (this.getAppliedVouchers?.length) {
        await this.removeVoucher({ voucherCode: this.getAppliedVouchers[0].voucherCode });
      }

      // Add voucher
      await this.addVoucher({ voucherCode, cookie: true });
    },

    setVoucherCookie({ voucherCode }: { voucherCode: string }) {
      const { $log } = useNuxtApp();
      $log.debug(`adding voucher cookie with value "${voucherCode}"`);

      const voucherCookie = useCookie('voucher', {
        path: '/',
        maxAge: 60 * 60 * 24 * 7,
        httpOnly: false,
        secure: !import.meta.dev,
      });
      voucherCookie.value = voucherCode;

      const mainStore = useMainStore();
      mainStore.voucherAdded = { status: true };
    },

    removeVoucherCookie() {
      const { $log } = useNuxtApp();
      $log.debug('Removing voucher cookie');
      useCookie('voucher').value = null;
    },

    async loadCart(payload?: any) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Loading cart...');

      this.loading = true;

      const { fields = 'DEFAULT' } = payload || {};
      const { cartId = this.getCartId } = payload || {};
      const { skipAnalytics } = payload || false;
      const userType = useUserStore().getType;
      const mainStore = useMainStore();
      const { language, abTests } = mainStore;

      return new Promise((resolve, reject) => {
        if (!cartId) {
          this.loading = false;
          return resolve(true);
        }

        return $api.hybris.cart
          .find(userType, cartId, fields, language, abTests)
          .then((response: any) => {
            $log.debug('Cart loaded.');

            this.cart = response.data;
            this.setCartCookie();
            notifyApp('cart');

            if (!skipAnalytics) {
              const datalayerStore = useDatalayerStore();
              const analyticsResourceList = [resourceNames.CART];
              datalayerStore.loadAnalytics(analyticsResourceList);
            }

            return resolve(true);
          })
          .catch((error: any) => {
            if (isResponseError(error, 400, [GENERIC_NOT_FOUND_ERROR, CART_NOT_FOUND_ERROR])) {
              return this.createCart();
            }

            if (error.response.data.errors[0].type === 'UnsupportedSDNDDeliveryModeError') {
              return resolve(error);
            }

            $log.error('Error while loading cart.', error);

            this.$reset();
            this.removeCartCookie();
            notifyApp('cart');

            return reject(error);
          })
          .finally(() => (this.loading = false));
      });
    },

    async createCart(payload?: any) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Creating cart...');

      this.loading = true;

      const userType = useUserStore().getType;
      const mainStore = useMainStore();
      const { language, abTests } = mainStore;
      const { skipAnalytics } = payload || false;

      return new Promise((resolve, reject) =>
        $api.hybris.cart
          .create(userType, language, null, null, 'DEFAULT', abTests)
          .then(async (response: any) => {
            $log.debug('Cart created.');

            this.cart = response.data;
            this.setCartCookie();

            const voucherCodeFromCookie = useCookie('voucher').value;

            if (voucherCodeFromCookie) {
              await this.addVoucher({ voucherCode: voucherCodeFromCookie, cookie: true });
            }

            if (!skipAnalytics) {
              const datalayerStore = useDatalayerStore();
              const analyticsResourceList = [resourceNames.CART];
              datalayerStore.loadAnalytics(analyticsResourceList);
            }

            notifyApp('cart');

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while creating cart.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async deleteCart(payload: any) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Deleting cart...');

      this.loading = true;

      const userType = useUserStore().getType;
      const { abTests } = useMainStore();
      const { cartId = this.getCartId } = payload || {};
      const { skipAnalytics } = payload || false;

      if (!cartId) {
        this.loading = false;
        $log.debug('No cart to delete.');
        return Promise.resolve();
      }

      try {
        await $api.hybris.cart.delete(userType, cartId, abTests);

        $log.debug('Cart deleted.');

        this.$reset();
        this.removeCartCookie();
        notifyApp('cart');

        if (this.getAppliedVouchers?.length) {
          this.getAppliedVouchers.forEach(async (voucher: { voucherCode: string }) => {
            await this.removeVoucher({ voucherCode: voucher.voucherCode });
          });
        }

        if (!skipAnalytics) {
          const datalayerStore = useDatalayerStore();
          const analyticsResourceList = [resourceNames.CART];
          datalayerStore.loadAnalytics(analyticsResourceList);
        }

        return Promise.resolve();
      } catch (error: any) {
        $log.error('Error while deleting cart.');
        $log.error(error.response.data);
        $errorHandler.handleErrorModal(error);

        throw error;
      } finally {
        this.loading = false;
      }
    },

    async recalculateCart() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Recalculating cart...');

      this.loading = true;

      const userId = useUserStore().getType;
      const cartId = this.getCartId;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cart
          .recalculate(userId, cartId, abTests)
          .then(async () => {
            $log.debug('Cart recalculated.');

            await this.loadCart({ fields: 'FULL' });

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while recalculating cart.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    setCartCookie() {
      const cartCookie = useCookie<typeof this.getCartCookieData>('cart', {
        path: '/',
        maxAge: ONE_YEAR,
        httpOnly: false,
        secure: !import.meta.dev,
      });
      cartCookie.value = this.getCartCookieData;
    },

    removeCartCookie() {
      useCookie('cart').value = null;
    },

    async addEntry(payload: any) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding entry to cart...');

      this.loading = true;

      const { cartFields = 'FULL', productCode, quantity, shelterCode = null } = payload;

      let cartId = this.getCartId;
      const isCartExists = !!cartId;
      const mainStore = useMainStore();
      const { language } = mainStore;
      const userType = useUserStore().getType;

      if (!isCartExists) {
        await this.createCart({ skipAnalytics: true });
      }

      cartId = this.getCartId;

      return new Promise((resolve, reject) =>
        $api.hybris.cartEntry
          .create(userType, cartId, productCode, quantity, language, shelterCode)
          .then(async (response: any) => {
            $log.debug('Added entry to cart.');

            await this.loadCart({ fields: cartFields });

            return resolve(response.data);
          })
          .catch(async (error: any) => {
            $log.error('Error while adding entry to cart.', error);

            $errorHandler.handleErrorModal(error);

            await this.loadCart({ fields: cartFields });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async removeEntry({ entryNumber }: { entryNumber: number }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Removing entry from cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartEntry
          .delete(userType, cartId, entryNumber)
          .then(async () => {
            $log.debug('Removed entry from cart.');

            await this.loadCart({ fields: 'FULL' });

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while removing entry from cart.', error);

            $errorHandler.handleErrorModal(error);

            await this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async changeEntryQuantity({ entryNumber, quantity }: { entryNumber: number; quantity: number }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Changing cart entry quantity...');

      this.loading = true;

      const cartId = this.getCartId;
      const mainStore = useMainStore();
      const { language } = mainStore;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartEntry
          .update(userType, cartId, entryNumber, quantity, language)
          .then(async (response: any) => {
            $log.debug('Cart entry quantity changed.');

            this.loadCart({ fields: 'FULL' });

            if (response.data.statusCode !== 'success') {
              return reject(response.data);
            }

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while changing cart entry quantity.', error);

            $errorHandler.handleErrorModal(error);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async addVoucher({ voucherCode, cookie }: { voucherCode: any; cookie: any }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding voucher to cart...');
      $log.debug(voucherCode);

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const mainStore = useMainStore();
      const { language } = mainStore;

      return new Promise((resolve) =>
        $api.hybris.cartVoucher
          .create(userType, cartId, voucherCode, language)
          .then(async () => {
            $log.debug('Added voucher to cart.');

            if (cookie && !useCookie('voucher').value) {
              mainStore.voucherAdded = { status: true };
            }
            this.removeVoucherCookie();

            this.loadCart({ fields: 'FULL' });

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while adding voucher to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            if (cookie || !useCookie('voucher').value) {
              mainStore.voucherAdded = { status: false, error: error.response.data?.errors?.[0] };
            }
            this.removeVoucherCookie();

            this.loadCart({ fields: 'FULL' });

            return resolve(false);
          })
          .finally(() => {
            this.loading = false;
          }),
      );
    },

    async removeVoucher({ voucherCode }: { voucherCode: any }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Removing voucher from cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartVoucher
          .delete(userType, cartId, encodeURIComponent(voucherCode))
          .then(async () => {
            $log.debug('Removed voucher from cart.');

            this.loadCart({ fields: 'FULL' });

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while removing voucher from cart.', error);

            $errorHandler.handleErrorModal(error);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async addPaybackNumber({ number }: { number: number }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding payback number to cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .setPaybackNumber(userType, cartId, number, abTests)
          .then((response: any) => {
            $log.debug('Added payback number to cart.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while adding payback number to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            await this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async removePaybackNumber() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Removing payback number from cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .removePaybackNumber(userType, cartId, abTests)
          .then((response: any) => {
            $log.debug('Removed payback number from cart.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while removing payback number from cart.', error);

            $errorHandler.handleErrorModal(error);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async addPaybackCoupon({ couponCode }: { couponCode: string }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding payback coupon to cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .addPaybackCoupon(userType, cartId, couponCode, abTests)
          .then((response: any) => {
            $log.debug('Added payback coupon to cart.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while adding payback coupon to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async removePaybackCoupon({ couponCode }: { couponCode: string }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Removing payback coupon from cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .removePaybackCoupon(userType, cartId, couponCode, abTests)
          .then((response: any) => {
            $log.debug('Removed payback coupon from cart.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while removing payback coupon from cart.', error);

            $errorHandler.handleErrorModal(error);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async loadPaybackAuthorizationUrl() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading payback authorization url...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const host = window.location.origin;

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .getPaybackAuthUrl(userType, cartId, host)
          .then(async (response: any) => {
            $log.debug('Payback authorization url loaded.');

            this.paybackAuthUrl = response.data.url;

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while loading payback authorization url.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    async setPaybackAuthCode({ authCode, host }: { authCode: string; host: string }) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Setting payback auth code...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .setPaybackAuthCode(userType, cartId, authCode, host, abTests)
          .then(async (response: any) => {
            $log.debug('Payback auth code set.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while setting payback auth code.', error);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          }),
      );
    },

    async addPaybackRedemption({ points }: { points: any }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding payback redemption to cart...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .setPaybackRedemptionPoints(userType, cartId, points, abTests)
          .then((response: any) => {
            $log.debug('Added payback redemption to cart.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while adding payback redemption to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async enablePaybackRedemption() {
      const windowReference = window.open();

      await this.loadPaybackAuthorizationUrl().then(() => {
        if (windowReference) {
          windowReference.location = this.getPaybackAuthUrl as string;
        }
      });

      this.loading = false;
    },

    async disablePaybackRedemption() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Disabling payback redemption...');

      this.loading = true;

      const cartId = this.getCartId;
      const userType = useUserStore().getType;
      const { abTests } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.cartPayback
          .removePaybackAuth(userType, cartId, abTests)
          .then((response: any) => {
            $log.debug('Disabled payback redemption.');

            this.cart = response.data;
            notifyApp('cart');

            return resolve(true);
          })
          .catch(async (error: any) => {
            $log.error('Error while disabling payback redemption.', error);

            $errorHandler.handleErrorModal(error);

            this.loadCart({ fields: 'FULL' });

            return reject(error);
          })
          .finally(() => (this.loading = false)),
      );
    },

    async merge(newCustomer: boolean) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Merging carts...');

      const mainStore = useMainStore();
      const { language, abTests } = mainStore;
      const userType = useUserStore().getType;
      const anonymousCartGuid = this.getGuid;
      const hasAnonymousCartEntries = (this.getEntryCount || this.getTotalUnitCount) > 0;

      let findCartResponse = null;
      let toMergeCartGuid = null;
      let currentCart = null;

      if (newCustomer) {
        // Create cart if user is a new customer
        await $api.hybris.cart
          .create(userType, language, null, null, 'DEFAULT', abTests)
          .then((response: any) => {
            $log.debug('Cart created for new customer.');
            toMergeCartGuid = response.data.guid;
            currentCart = response.data;
          })
          .catch((error: any) => {
            $log.error('Error while creating cart for new customer.', error);

            $errorHandler.handleErrorModal(error);
            return Promise.reject(error);
          });
      } else {
        // Get existing cart
        try {
          findCartResponse = await $api.hybris.cart.find(userType, OCC_USER_ID_CURRENT, 'DEFAULT', null, abTests);
          toMergeCartGuid = findCartResponse?.data?.guid;
          currentCart = findCartResponse?.data;
        } catch (error: any) {
          $log.error('Error while merging carts, can usually be ignored if you do not see the error modal.', error);

          if (!isResponseError(error, 400, [GENERIC_NOT_FOUND_ERROR, CART_NOT_FOUND_ERROR])) {
            $errorHandler.handleErrorModal(error);
            return Promise.reject(error);
          }
        }
      }

      // Use existing cart if anonymous cart is empty
      if (currentCart && !hasAnonymousCartEntries) {
        $log.debug('Merging carts skipped because anonymous cart is empty.');

        this.cart = currentCart;
        this.setCartCookie();
        notifyApp('cart');

        return Promise.resolve();
      }

      // Merge both carts, falling back gracefully if any of them doesn't exist
      return $api.hybris.cart
        .create(userType, language, anonymousCartGuid, toMergeCartGuid, 'FULL', abTests)
        .then((response: any) => {
          $log.debug('Merged carts.');

          this.cart = response.data;
          this.setCartCookie();
          notifyApp('cart');

          return Promise.resolve();
        })
        .catch((error: any) => {
          $log.error('Error while merging carts.', error);

          $errorHandler.handleErrorModal(error);

          return Promise.reject(error);
        });
    },

    async processCheckoutStep1({
      deliveryAddress,
      billingAddress,
      user,
    }: {
      deliveryAddress: Record<string, any>;
      billingAddress: Record<string, any>;
      user: Record<string, any>;
    }) {
      const { $log } = useNuxtApp();
      $log.debug('Processing checkout step 1...');

      const isAuthenticated = useUserStore().getIsAuthenticated;
      const userStore = useUserStore();

      if (isAuthenticated) {
        $log.debug('User is authenticated.');

        const promise = billingAddress.id
          ? this.setBillingAddress({ addressId: billingAddress.id })
          : this.addBillingAddress(billingAddress);

        return promise
          .then((address: any) => {
            if (areHybrisAddressesDifferent(billingAddress, deliveryAddress)) {
              return deliveryAddress.id
                ? this.setDeliveryAddress({ addressId: deliveryAddress.id })
                : this.addDeliveryAddress(deliveryAddress);
            }

            if (billingAddress.id) {
              return this.setDeliveryAddress({ addressId: billingAddress.id });
            }

            return address
              ? this.setDeliveryAddress({ addressId: address.id })
              : this.addDeliveryAddress(billingAddress);
          })
          .then(() => userStore.loadUser())
          .then(() => userStore.loadAddresses())
          .then(() => this.loadCart({ fields: 'FULL' }))
          .then(() => this.setCurrentDeliveryMode());
      }

      $log.debug('User is a guest.');

      const hasGuestEmail = this.getGuestEmail;
      const oldGuestUserEmail = this.cart?.user?.uid;

      if (hasGuestEmail) {
        await userStore.updateGuestEmail({ newEmail: user.uid, oldEmail: oldGuestUserEmail });
      }

      if (!this.getIsGuest && !hasGuestEmail) {
        await this.setEmail({ email: user.uid });
      }

      return this.addBillingAddress(billingAddress)
        .then(() => this.addDeliveryAddress(deliveryAddress))
        .then(() => this.loadCart())
        .then(() => this.setCurrentDeliveryMode());
    },

    async processCheckoutStep2({
      paymentMode,
      dateOfBirth,
      bankData,
      adyenData,
    }: {
      paymentMode: string;
      dateOfBirth: string;
      bankData: any;
      adyenData: any;
    }) {
      const { $log } = useNuxtApp();
      $log.debug('Processing checkout step 2...');

      const isAuthenticated = useUserStore().getIsAuthenticated;

      if (paymentMode === PAYMENT_MODE_BANK) {
        const cartPaymentCookie = useCookie('cart-payment', {
          path: '/',
          maxAge: 60 * 30, // 30 minutes
          httpOnly: false,
          secure: !import.meta.dev,
        });
        cartPaymentCookie.value = bankData;
      }

      if (
        [PAYMENT_MODE_ADYEN_CREDITCARD, PAYMENT_MODE_ADYEN_BANCONTACT, PAYMENT_MODE_ADYEN_ONLINEBANKING].includes(
          paymentMode,
        )
      ) {
        saveToLocalStorage(LOCAL_STORAGE_ADYEN_PAYLOAD, adyenData);
      }

      if (dateOfBirth && [PAYMENT_MODE_INVOICE, PAYMENT_MODE_BANK].includes(paymentMode)) {
        const address = this.getBillingAddress;

        if (isAuthenticated) {
          const userStore = useUserStore();
          return userStore.patchAddress({ id: address.id, dateOfBirth });
        }

        return this.addBillingAddress({
          ...address,
          dateOfBirth,
        });
      }

      return Promise.resolve();
    },

    processCheckoutStep3(data: any) {
      const { $log } = useNuxtApp();
      $log.debug('Processing checkout step 3...');

      let placeOrderData = data;

      if (!this.getFullPaybackPayment) {
        if (this.getPaymentMode === PAYMENT_MODE_BANK) {
          const cartPaymentData = useCookie<Record<string, any>>('cart-payment').value;

          if (cartPaymentData) {
            placeOrderData = {
              ...placeOrderData,
              ...cartPaymentData,
            };
          }
        }

        if (
          [PAYMENT_MODE_ADYEN_CREDITCARD, PAYMENT_MODE_ADYEN_BANCONTACT, PAYMENT_MODE_ADYEN_ONLINEBANKING].includes(
            this.getPaymentMode,
          )
        ) {
          const creditCardData = getFromLocalStorage(LOCAL_STORAGE_ADYEN_PAYLOAD);

          if (creditCardData) {
            placeOrderData = {
              ...placeOrderData,
              ...creditCardData,
            };
          }
        }
      }

      const newsletterRegistrationData = data.newsletter ? this.getNewsletterRegistrationData : null;

      if (newsletterRegistrationData) {
        const mainStore = useMainStore();

        if (mainStore.getNewsletterRecaptchaEnabled) {
          (newsletterRegistrationData as any).recaptchaResponse = data.recaptchaResponse;
        }

        $log.debug('Add newsletter...');
        const action = mainStore.getFeature('ccaSubscriptionApiEnabled')
          ? useSubscriptionStore().register
          : useNewsletterStore().register;

        action && action(newsletterRegistrationData);
      }

      if (placeOrderData.recaptchaResponse) {
        delete placeOrderData.recaptchaResponse;
      }

      // in case of BLIK payment we don't call the /initialize call on the step 4 page,
      // just redirect to the Adyen component page, this is why we return a rejected promise,
      // to be aligned with the other payment methods
      if (this.getPaymentMode === PAYMENT_MODE_ADYEN_BLIK) {
        $log.debug('Initiate redirect to BLIK page');
        const error = { response: { data: { errors: [{ type: 'blikPayment' }] } } };
        return Promise.reject(error);
      }

      return this.initializeOrder(placeOrderData);
    },

    async setEmail({ email }: { email: string }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding email to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cart
          .setEmail(userType, cartId, email)
          .then(() => {
            $log.debug('Added email to cart.');

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while adding email to cart.', error);

            // Email is already set and cannot be overwritten
            if (error.response.status === 401) {
              return resolve(true);
            }

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },

    async addDeliveryAddress(deliveryAddress: Record<string, any>) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding delivery address to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartAddress
          .create(userType, cartId, OCC_CART_ADDRESS_TYPE_DELIVERY, deliveryAddress)
          .then((response: any) => {
            $log.debug('Added delivery address to cart.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            $log.error('Error while adding delivery address to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            error.form = 'deliveryAddress';

            return reject(error);
          }),
      );
    },

    async setDeliveryAddress({ addressId }: { addressId: string }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Setting delivery address to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartAddress
          .set(userType, cartId, OCC_CART_ADDRESS_TYPE_DELIVERY, addressId)
          .then(() => {
            $log.debug('Set delivery address to cart.');

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while setting delivery address to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            error.form = 'deliveryAddress';

            return reject(error);
          }),
      );
    },

    async addBillingAddress(billingAddress: Record<string, any>) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding billing address to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartAddress
          .create(userType, cartId, OCC_CART_ADDRESS_TYPE_BILLING, billingAddress)
          .then((response: any) => {
            $log.debug('Added billing address to cart.');

            return resolve(response.data);
          })
          .catch((error: any) => {
            $log.error('Error while adding billing address to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            error.form = 'billingAddress';

            return reject(error);
          }),
      );
    },

    async setBillingAddress({ addressId }: { addressId: string }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Setting billing address to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartAddress
          .set(userType, cartId, OCC_CART_ADDRESS_TYPE_BILLING, addressId)
          .then(() => {
            $log.debug('Set billing address to cart.');

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while setting billing address to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            error.form = 'billingAddress';

            return reject(error);
          }),
      );
    },

    async setDeliveryMode({ deliveryMode }: { deliveryMode: any }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Setting delivery mode to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartDeliveryMode
          .add(userType, cartId, deliveryMode)
          .then(() => {
            $log.debug('Set delivery mode to cart.');

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while setting delivery mode to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },

    async setCurrentDeliveryMode() {
      // Get all possible deliveryModes from cart and set the first one that is returned. (Not sure why tho)
      await this.loadDeliveryModes();

      // For standard shipping, deliveryModes = []. This is only filled for special modes like free or heavy shipping.
      const deliveryMode = this.getDeliveryMode?.code ?? (this.getDeliveryModes?.[0] as any)?.code;

      if (deliveryMode) {
        await this.setDeliveryMode({ deliveryMode });
      }
    },

    async setPaymentMode({ paymentMode }: { paymentMode: PaymentMode }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Setting payment mode to cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartPaymentMode
          .set(userType, cartId, paymentMode)
          .then(() => {
            $log.debug('Set payment mode to cart.');

            if (this.cart) {
              this.cart.paymentMode = paymentMode;
            }

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while setting payment mode to cart.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },

    deletePaymentMode() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Removing payment mode of cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartPaymentMode
          .delete(userType, cartId)
          .then(() => {
            $log.debug('Payment mode removed of cart.');

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while removing payment mode of cart.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },

    async initializeOrder(data: any) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Placing order...');

      const cartId = data?.cartId || this.getCartId;
      const userStore = useUserStore();
      const userType = userStore.getType;
      const mainStore = useMainStore();
      const { language } = mainStore;

      if (!this.getDeliveryMode) {
        await this.setCurrentDeliveryMode();
      }

      return new Promise((resolve, reject) =>
        $api.hybris.order
          .initialize(userType, cartId, data, language)
          .then((response: any) => {
            $log.debug('Initialized order.');

            const order = response.data;

            this.cart = null;
            this.removeCartCookie();
            useCookie('cart-payment').value = null;
            deleteFromLocalStorage(LOCAL_STORAGE_ADYEN_PAYLOAD);
            notifyApp('cart');
            notifyApp('onCheckoutSuccessful');

            return resolve(userStore.getIsAuthenticated ? order.code : order.guid);
          })
          .catch((error: any) => {
            $log.error('Error while initializing order.', error);

            if (
              !['invoice', 'bank'].includes(this.getPaymentMode) ||
              error?.response?.data?.errors?.[0]?.type !== CUSTOMER_RISK_CHECK_ERROR
            ) {
              $errorHandler.handleErrorModal(error, false);
            }

            return reject(error);
          }),
      );
    },

    async placeOrder({ data = {}, transactionId = undefined }: { data: Record<string, any>; transactionId?: number }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Placing order...');

      const cartId = data?.cartId || this.getCartId;
      const userStore = useUserStore();
      const userType = userStore.getType;
      const mainStore = useMainStore();
      const { language } = mainStore;

      if (!this.getDeliveryMode) {
        await this.setCurrentDeliveryMode();
      }

      return new Promise((resolve, reject) =>
        $api.hybris.order
          .create(userType, cartId, transactionId, data, language)
          .then((response: any) => {
            $log.debug('Placed order.');

            const order = response.data;

            this.cart = null;
            this.removeCartCookie();
            useCookie('cart-payment').value = null;
            deleteFromLocalStorage(LOCAL_STORAGE_ADYEN_PAYLOAD);
            notifyApp('cart');
            notifyApp('onCheckoutSuccessful');

            return resolve(userStore.getIsAuthenticated ? order.code : order.guid);
          })
          .catch((error: any) => {
            $log.error('Error while placing order.', error);

            if (!ORDER_RETRY_ERRORS_FOR_PAYMENTS.includes(error?.response?.data?.errors?.[0].type)) {
              $errorHandler.handleErrorModal(error, false);
            }

            return reject(error);
          }),
      );
    },

    /**
     * Autofill tries to set already existing user addresses as the cart addresses.
     */
    async autoFillCart() {
      const { $log } = useNuxtApp();
      const { defaultCountry } = useMainStore();
      const userStore = useUserStore();
      $log.debug('Auto filling cart...');

      if (!userStore.getIsAuthenticated) {
        return false;
      }

      if (this.getBillingAddress && this.getDeliveryAddress && this.getPaymentMode) {
        return true;
      }

      try {
        await Promise.all([userStore.loadAddresses(), this.loadPaymentModes()]);
      } catch (error: any) {
        $log.debug('Auto filling cart failed: Could not loading addresses, payment or delivery modes.');
      }

      if (!userStore.getHasAddresses) {
        return false;
      }

      const defaultDeliveryAddress = userStore.getDefaultShippingAddress || userStore.getDefaultAddress;
      const billingAddress = userStore.getBillingAddress;
      const paymentMode = userStore.getDefaultPaymentMode;

      const shouldSetBillingAddress = !this.getBillingAddress && userStore.getBillingAddress;
      const shouldSetDeliveryAddress =
        !this.getDeliveryAddress &&
        defaultDeliveryAddress &&
        defaultDeliveryAddress?.country.isocode === defaultCountry;
      const shouldSetPaymentMode = !this.getPaymentMode && !this.getFullPaybackPayment && paymentMode;

      await Promise.all([
        shouldSetBillingAddress ? this.setBillingAddress({ addressId: billingAddress.id }) : null,
        shouldSetDeliveryAddress ? this.setDeliveryAddress({ addressId: defaultDeliveryAddress.id }) : null,
        shouldSetPaymentMode ? this.setPaymentMode({ paymentMode }) : null,
      ]);

      // Available delivery modes depend on the users addresses, so we call it separately
      await this.setCurrentDeliveryMode();

      // Reload cart after successful autofill
      await this.loadCart({ fields: 'FULL' });

      return true;
    },

    async loadPaymentModes() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading payment modes of cart...');

      const cartId = this.getCartId;
      const mainStore = useMainStore();
      const { language } = mainStore;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartPaymentMode
          .all(userType, cartId, language)
          .then((response: any) => {
            $log.debug('Loaded payment modes of cart.');

            this.paymentModes = response.data.paymentModes;

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while loading payment modes of cart.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    async loadDeliveryModes() {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading delivery modes of cart...');

      const cartId = this.getCartId;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartDeliveryMode
          .all(userType, cartId)
          .then((response: any) => {
            $log.debug('Loaded delivery modes of cart.');

            this.deliveryModes = response.data.deliveryModes;

            return resolve(true);
          })
          .catch((error: any) => {
            $log.error('Error while loading delivery modes of cart.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    resetPickupStations() {
      const { $log } = useNuxtApp();
      $log.debug('Resetting pickup stations...');
      this.pickupStations = [];
    },

    async loadPickupStations({
      city,
      postalCode,
      streetName,
      houseNumber,
    }: {
      city: string;
      postalCode: string;
      streetName: string;
      houseNumber: string;
    }) {
      this.resetPickupStations();

      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading pickup stations...');
      const userType = useUserStore().getType;

      return new Promise((resolve) =>
        $api.hybris.pickupStation
          .find(userType, city, postalCode, streetName, houseNumber)
          .then((response: any) => {
            $log.debug('Pickup stations loaded.');

            this.pickupStations = response.data;

            return resolve(response.data);
          })
          .catch((error: any) => {
            $log.error('Error while loading pickup stations.', error);

            $errorHandler.handleErrorModal(error);

            return resolve(error);
          }),
      );
    },

    getStoresForCart({
      query,
      latitude,
      longitude,
      radius,
      filterProperties,
    }: {
      query: any;
      latitude: any;
      longitude: any;
      radius: any;
      filterProperties: any;
    }) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Getting stores for cart...');
      const cartId = this.getCartId;
      const language = useMainStore().language;
      const userId = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartStore
          .find(userId, cartId, language, query, latitude, longitude, radius, filterProperties)
          .then((response: any) => {
            $log.debug('Get stores for cart.');
            return resolve(response.data);
          })
          .catch((error: any) => {
            $log.debug('Error while getting stores for cart.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },

    findCartStoreAvailability(storeId = null) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Loading store availability for the cart...');
      const cartId = this.getCartId;
      const language = useMainStore().language;
      const userType = useUserStore().getType;

      return new Promise((resolve, reject) =>
        $api.hybris.cartStore
          .findSingle(userType, cartId, language, storeId)
          .then((response: any) => {
            $log.debug('Loaded the store availability for the cart.', response.data);

            return resolve(response.data.productsAvailability);
          })
          .catch((error: any) => {
            $log.error('Error while loading the store availability for the cart.');
            $log.error(error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
