export const util = import.meta.server ? await import('node:util') : undefined;

const logLevels = {
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
  log: 4,
};

/**
 * Get the current log level on the client.
 *
 * Must be available as early as possible, but RuntimeConfig loads too late and can't be used everywhere.
 * `import.meta.env` would need a rebuild to update values, and `process.env` is not available on the client.
 */
const getClientLogLevel = () => {
  if (typeof window === 'undefined') {
    return;
  }

  // Try global nuxt instance first, has correct value on client even if config was changed
  if (window.$nuxt) {
    return window.$nuxt.$config.public.logLevel;
  }

  // If global nuxt instance is not yet available, retrieve log level
  // from same object which is used by nuxt to hydrate the RuntimeConfig
  return window.__NUXT__.config.public.logLevel;
};

/**
 * Get the current log level on the server.
 *
 * It must be available as early as possible, but RuntimeConfig loads too late and can't be used everywhere.
 * `import.meta.env` requires a rebuild when changing values.
 */
// eslint-disable-next-line no-restricted-syntax
const getServerLogLevel = () => process.env.NUXT_PUBLIC_LOG_LEVEL;

/**
 * Get the current log level.
 *
 * Can be used isomorphic and returns the correct value without needing a rebuild
 */
const getLogLevel = () => (import.meta.client ? getClientLogLevel() : getServerLogLevel()) || 'error';

const isEnabled = (method) => logLevels[method] <= logLevels[getLogLevel()];

/**
 * Creates a bound logging function which includes a logging prefix,
 * or a noop function when logging is disabled.
 *
 * @param {string} method
 * @returns {function(...any)}
 */
const createLogMethod = (method) => {
  if (isEnabled(method)) {
    // eslint-disable-next-line no-console
    return Function.prototype.bind.call(console[method], console, `[storefront]`);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prettier/prettier
  return (...args) => { };
};

/**
 * IIFEs are used to immediately invoke the log methods (log, debug, info, etc.)
 * and return bound functions to the call site. This ensures that the correct
 * point of invocation is shown in the console, rather than logging a line
 * within this logger utility itself.
 */
export const logger = {
  isEnabled,
  getLogLevel,
  log: (() => createLogMethod('log'))(),
  debug: (() => createLogMethod('log'))(),
  info: (() => createLogMethod('info'))(),
  warn: (() => createLogMethod('warn'))(),
  error: (() => createLogMethod('error'))(),
  inspect: (value) => {
    if (import.meta.client) {
      return JSON.stringify(value, null, 2);
    } else {
      // Nitro API routes are super funky when even thinking about importing ANYTHING, so fallback for good measure
      return util?.inspect(value, { colors: true }) ?? JSON.stringify(value, null, 2);
    }
  },
};
