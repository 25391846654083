import { Comment, Text } from 'vue';

// Solution copied from here:
// https://mokkapps.de/vue-tips/check-if-slot-is-empty
// https://www.telerik.com/blogs/checking-vue-3-slots-emptiness

/**
 * Determines whether a slot is empty for Vue 3: https://github.com/vuejs/vue-next/issues/3056
 * @param {Function} slot - The slot $slot.name
 * @returns {Boolean}
 */

// Adapted from https://github.com/vuejs/vue-next/blob/ca17162e377e0a0bf3fae9d92d0fdcb32084a9fe/packages/runtime-core/src/helpers/renderSlot.ts#L77

export function hasSlotContent(slot: any, slotProps = {}) {
  if (!slot) return false;

  return slot(slotProps).some((vnode: any) => {
    if (vnode.type === Comment) return false;

    if (Array.isArray(vnode.children) && !vnode.children.length) return false;

    return vnode.type !== Text || (typeof vnode.children === 'string' && vnode.children.trim() !== '');
  });
}

/**
 * Determines whether a VNode is empty
 * @param {Function} vnodes - The vndode(s)
 * @returns {Boolean}
 */

export function isVNodeEmpty(vnodes: VNode | VNode[] | undefined | null): boolean {
  return (
    !!vnodes && (Array.isArray(vnodes) ? vnodes.every((vnode) => vnode.type !== Comment) : vnodes.type !== Comment)
  );
}
