import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class SubscriptionApi extends AbstractHybrisApi {
  status(userId = 'current') {
    return this.request({
      method: 'get',
      url: `/users/${userId}/subscription`,
      params: {
        channel: 'EMAIL',
      },
      version: 'v2',
    });
  }

  publicStatus(token) {
    return this.request({
      method: 'get',
      url: '/subscription',
      headers: {
        'newsletter-token': encodeURIComponent(token),
      },
      params: {
        channel: 'EMAIL',
      },
      version: 'v2',
    });
  }

  register(data, lang = null) {
    data.channel = data.channel ?? 'EMAIL';
    data.source = data.source ?? 'WEBSHOP';

    return this.request({
      method: 'post',
      url: '/subscription/email',
      params: {
        lang,
      },
      data,
      version: 'v2',
    });
  }

  publicUnsubscribe(payload, channel = 'EMAIL') {
    return this.request({
      method: 'delete',
      url: '/subscription',
      headers: {
        'newsletter-token': payload.newsletterToken,
      },
      params: {
        channel,
        type: payload.type,
      },
      version: 'v2',
    });
  }

  saveInterests(userId, interests) {
    return this.request({
      method: 'put',
      url: `/users/${userId}/subscription/interests`,
      data: {
        interests,
      },
      version: 'v2',
    });
  }

  savePublicInterests(newsletterToken, interests) {
    return this.request({
      method: 'put',
      url: '/subscription/interests',
      headers: {
        'newsletter-token': newsletterToken,
      },
      data: {
        interests,
      },
      version: 'v2',
    });
  }

  saveFavoriteStores(userId, stores) {
    return this.request({
      method: 'put',
      url: `/users/${userId}/subscription/stores`,
      data: {
        stores,
      },
      version: 'v2',
    });
  }

  savePublicFavoriteStores(newsletterToken, stores) {
    return this.request({
      method: 'put',
      url: '/subscription/stores',
      headers: {
        'newsletter-token': newsletterToken,
      },
      data: {
        stores,
      },
      version: 'v2',
    });
  }

  saveProfile(userId, recipient) {
    return this.request({
      method: 'put',
      url: `/users/${userId}/subscription/profile`,
      data: {
        recipient,
      },
    });
  }

  savePublicProfile(newsletterToken, recipient) {
    return this.request({
      method: 'put',
      url: '/subscription/profile',
      headers: {
        'newsletter-token': newsletterToken,
      },
      data: {
        recipient,
      },
      version: 'v2',
    });
  }
}
