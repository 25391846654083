<template>
  <div class="sections">
    <fn-section
      v-for="(section, index) in sections"
      :id="section.id ? section.id : 'section-' + index"
      :key="`section-${index}`"
      :display-view="section.display_view"
      :constrained="section.constrained"
      :centered="section.centered"
      :padding-top="section.padding_top"
      :padding-bottom="section.padding_bottom"
      :no-padding="(!section.padding_bottom || !section.padding_top) && section.no_padding"
      :shifted="section.shifted"
      :pushed="section.pushed"
      :background-color="section.background_color"
      :foreground-color="section.foreground_color"
      :headline="section.headline"
      :icon="section.icon"
      :no-padding-x="
        subSections ||
        section.sub_sections ||
        section.no_padding_x ||
        ((section.padding_bottom || section.padding_top) && section.no_padding)
      "
      :display-setting="section.display_setting"
      :display-user-setting="section.display_user_setting"
      :bordered="section.bordered"
      :custom-bg-color-code="section.custom_bg_color ? section.custom_bg_color_code : null"
      :section-image-right="
        section.background_image_options && section.background_image_options.includes('use_section_image_right')
      "
      :section-image-left="
        section.background_image_options && section.background_image_options.includes('use_section_image_left')
      "
      :toggleable="section.section_toggleable ? section.section_toggleable : false"
      :visible-height="section.visible_height ? section.visible_height : undefined"
      :toggle-button-pos="section.toggle_button_position ? section.toggle_button_position : undefined"
      :enable-smile="section.enable_smile"
      :image-options="section.background_image_options"
    >
      <template
        v-if="section.background_image"
        #image
      >
        <fn-picture
          :src="section.background_image.url"
          :alt="section.background_image.alt"
          :title="section.background_image.title"
          :width="section.background_image.width"
          :height="section.background_image.height"
          :background-image-fullsize="section.background_image_fullsize ? section.background_image_fullsize : false"
          :image-sprite="
            section.background_image_options && section.background_image_options.includes('use_image_sprite')
          "
        />
      </template>
      <template #default>
        <fn-cms-grid-loader
          :items="section.grid.items"
          :page-metrics="pageMetrics"
          :image-options="section.background_image_options"
        />
      </template>
    </fn-section>
  </div>
</template>

<script>
export default {
  props: {
    sections: {
      type: Array,
      required: true,
    },
    subSections: {
      type: Boolean,
    },
    pageMetrics: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
