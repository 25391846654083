import AbstractApi from './AbstractApi';

export default class AbstractHybrisFnApi extends AbstractApi {
  constructor(client, logger, config, cookies) {
    super(client, logger, config, cookies);
    this.baseUrl = config.public.hybrisFnBaseUrl;
    this.baseStore = '';
  }

  getURL(endpoint) {
    return `${this.baseUrl}/${this.baseStore}${endpoint}`;
  }

  setBaseStore(baseStore) {
    this.baseStore = baseStore;

    return this;
  }
}
