export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const { path, query } = to;

  const cartStore = useCartStore();
  const userStore = useUserStore();

  await cartStore.loadCart({ fields: 'FULL' });

  if (!cartStore.getReachedMinimumOrderValue) {
    return navigateToWithAdobeParam('/cart/', query);
  }

  if (userStore.getIsAuthenticated && path === '/checkout/start/') {
    return navigateTo(getRouteRedirectTo({ path: '/cart/', query }, true, true));
  }

  if (userStore.getIsAuthenticated) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  return null;
});
