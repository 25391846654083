export const useNewsletterStore = defineStore('newsletter', {
  state: () => ({
    newsletterRegistration: null,
    newsletterSubscription: null,
    cryptId: null,
    hasNewsletterSubscription: false,
    newsletterSubscriptionChecked: false,
    showConfirmation: false,
  }),
  getters: {
    getComputedHasNewsletterSubscription: (state) => state.newsletterSubscription !== null,
  },
  actions: {
    async register(payload) {
      const { $api, $log } = useNuxtApp();
      const { language, getFeature } = useMainStore();

      $log.debug('Register newsletter...');

      if (!payload.source) payload.source = 'WEBSHOP';

      const registerNewsletter = getFeature('ccaSubscriptionApiEnabled')
        ? $api.hybris.subscription.register(payload, language)
        : $api.hybris.newsletter.register(payload, language);

      try {
        const response = await registerNewsletter;
        $log.debug('Registered newsletter entry...');
        this.newsletterSubscription = payload;
        return response;
      } catch (error) {
        $log.error('Error while registering newsletter.');
        $log.error(error);
        throw error;
      }
    },

    async unsubscribe(payload) {
      const { $api, $log } = useNuxtApp();
      const { getFeature } = useMainStore();
      $log.debug('Delete newsletter subscription...');

      const unsubscribeNewsletter = getFeature('ccaSubscriptionApiEnabled')
        ? $api.hybris.subscription.publicUnsubscribe(payload)
        : $api.hybris.newsletter.unsubscribe(payload);

      try {
        const response = await unsubscribeNewsletter(payload);
        $log.debug('Subscription entry deleted...');
        this.newsletterSubscription = response;
        return response;
      } catch (error) {
        $log.error('Error while deleting subscription');
        $log.error(error);
        throw error;
      }
    },

    async subscription(payload) {
      const { $api, $log } = useNuxtApp();
      $log.debug('Getting newsletter subscription...');

      try {
        const response = await $api.hybris.newsletter.subscription(payload);
        $log.debug('Subscription entry...');
        this.newsletterSubscription = response.data;
        this.cryptId = payload;
        return response;
      } catch (error) {
        $log.error('Error while getting subscription');
        $log.error(error);
        throw error;
      }
    },

    async update({ data }) {
      const { $api, $log } = useNuxtApp();
      $log.debug('Update newsletter subscription...');

      const cryptId = this.cryptId || useUserStore()?.user?.cryptId;

      try {
        await $api.hybris.newsletter.update(cryptId, data);
        $log.debug('Updated subscription entry...');
      } catch (error) {
        $log.error('Error while updating subscription');
        $log.error(error);
        throw error;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNewsletterStore, import.meta.hot));
}
