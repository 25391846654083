export default defineNuxtPluginWithTiming(import.meta.url, () => {
  const $router = useRouter();

  $router.beforeEach((to, from, next) => {
    if (to.query.modal && from.name) {
      /**
       * Opens global iframe modal
       * We need to remove the modal=true query after loading the iframe modal
       * otherwise it opens an iframe in an iframe, every time we click on a link within the iframe
       * which also prevents the scroll on anchors.
       * showContentOnly is used in the default.vue where we don't display the header if this is set
       */
      const queryParams = new URLSearchParams(to.query as Record<string, any>);
      queryParams.delete('modal');
      queryParams.append('showContentOnly', 'true');

      useMainStore().iframeModalUrl = `${to.path}?${queryParams.toString()}${to.hash}`;

      next(false);
    } else {
      next();
    }
  });
});
