import { useDataCache } from '#nuxt-multi-cache/composables';
import { useWithTiming, useWithTimingLogBuilder } from '@/utils/timing';
import { isResponseError } from '@/utils/apiErrors';
import { getPageCategory, getPagePath, getPageType } from '@/utils/datalayer';
import AuthApi from '@/api/storefront/AuthApi';
import AddressApi from '@/api/hybris/AddressApi';
import AdoptionApi from '@/api/hybris/AdoptionApi';
import AnalyticsApi from '@/api/hybris/AnalyticsApi';
import BookingApi from '@/api/hybris/BookingApi';
import AutoCompleteApi from '@/api/hybris/AutoCompleteApi';
import CampaignApi from '@/api/hybris/CampaignApi';
import CartAddressApi from '@/api/hybris/CartAddressApi';
import CartApi from '@/api/hybris/CartApi';
import CartDeliveryModeApi from '@/api/hybris/CartDeliveryModeApi';
import CartEntryApi from '@/api/hybris/CartEntryApi';
import CartPaymentModeApi from '@/api/hybris/CartPaymentModeApi';
import CartPaybackApi from '@/api/hybris/CartPaybackApi';
import CartStoreApi from '@/api/hybris/CartStoreApi';
import CartVoucherApi from '@/api/hybris/CartVoucherApi';
import ConfigurationApi from '@/api/hybris/ConfigurationApi';
import ContactFormApi from '@/api/hybris/ContactFormApi';
import ContentTileApi from '@/api/hybris/ContentTileApi';
import CountryApi from '@/api/hybris/CountryApi';
import FileApi from '@/api/hybris/FileApi';
import ForgottenPasswordApi from '@/api/hybris/ForgottenPasswordApi';
import NavigationNodesHybrisApi from '@/api/hybris/NavigationNodesApi';
import OrderApi from '@/api/hybris/OrderApi';
import PageHybrisApi from '@/api/hybris/PageApi';
import PaybackApi from '@/api/hybris/PaybackApi';
import Personalization from '@/api/hybris/Personalization';
import PetApi from '@/api/hybris/PetApi';
import PickupStationApi from '@/api/hybris/PickupStationApi';
import ProductApi from '@/api/hybris/ProductApi';
import SeoContentApi from '@/api/hybris/SeoContentApi';
import SheltersApi from '@/api/hybris/SheltersApi';
import StoreApi from '@/api/hybris/StoreApi';
import UserApi from '@/api/hybris/UserApi';
import NewsletterApi from '@/api/hybris/NewsletterApi';
import SubscriptionApi from '@/api/hybris/SubscriptionApi';
import RaffleApi from '@/api/hybris/RaffleApi';
import RedirectApi from '@/api/hybris/RedirectApi';
import VotingApi from '@/api/hybris/VotingApi';
import WishlistApi from '@/api/hybris/WishlistApi';
import WishPetApi from '@/api/hybris/WishPetApi';
import CustomerApi from '@/api/hybris/fn/CustomerApi';

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  const { $axios, $domain, $host, $log, $i18n, $router, $pinia, $config } = nuxtApp;
  const mainStore = useMainStore();
  const isAppview = useIsAppView();

  // Nitro API routes are super funky when even thinking about importing ANYTHING, so we provide useWithTiming similarly
  // to axios/log. Note that we pass via config to prevent changing all API constructors.
  const config = { ...$config, useWithTiming, useWithTimingLogBuilder, useDataCache };
  const apiInstances = {};

  const api = {
    hybris: {
      get address() {
        if (!apiInstances[AddressApi]) {
          apiInstances[AddressApi] = new AddressApi($axios, $log, config, isAppview);
        }
        return apiInstances[AddressApi];
      },
      get adoption() {
        if (!apiInstances[AdoptionApi]) {
          apiInstances[AdoptionApi] = new AdoptionApi($axios, $log, config, isAppview);
        }
        return apiInstances[AdoptionApi];
      },
      get analytics() {
        if (!apiInstances[AnalyticsApi]) {
          apiInstances[AnalyticsApi] = new AnalyticsApi($axios, $log, config, isAppview);
        }
        return apiInstances[AnalyticsApi];
      },
      get booking() {
        if (!apiInstances[BookingApi]) {
          apiInstances[BookingApi] = new BookingApi($axios, $log, config, isAppview);
        }
        return apiInstances[BookingApi];
      },
      get autoComplete() {
        if (!apiInstances[AutoCompleteApi]) {
          apiInstances[AutoCompleteApi] = new AutoCompleteApi($axios, $log, config, isAppview);
        }
        return apiInstances[AutoCompleteApi];
      },
      get campaign() {
        if (!apiInstances[CampaignApi]) {
          apiInstances[CampaignApi] = new CampaignApi($axios, $log, config, isAppview);
        }
        return apiInstances[CampaignApi];
      },
      get cartAddress() {
        if (!apiInstances[CartAddressApi]) {
          apiInstances[CartAddressApi] = new CartAddressApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartAddressApi];
      },
      get cart() {
        if (!apiInstances[CartApi]) {
          apiInstances[CartApi] = new CartApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartApi];
      },
      get cartDeliveryMode() {
        if (!apiInstances[CartDeliveryModeApi]) {
          apiInstances[CartDeliveryModeApi] = new CartDeliveryModeApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartDeliveryModeApi];
      },
      get cartEntry() {
        if (!apiInstances[CartEntryApi]) {
          apiInstances[CartEntryApi] = new CartEntryApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartEntryApi];
      },
      get cartPaymentMode() {
        if (!apiInstances[CartPaymentModeApi]) {
          apiInstances[CartPaymentModeApi] = new CartPaymentModeApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartPaymentModeApi];
      },
      get cartPayback() {
        if (!apiInstances[CartPaybackApi]) {
          apiInstances[CartPaybackApi] = new CartPaybackApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartPaybackApi];
      },
      get cartStore() {
        if (!apiInstances[CartStoreApi]) {
          apiInstances[CartStoreApi] = new CartStoreApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartStoreApi];
      },
      get cartVoucher() {
        if (!apiInstances[CartVoucherApi]) {
          apiInstances[CartVoucherApi] = new CartVoucherApi($axios, $log, config, isAppview);
        }
        return apiInstances[CartVoucherApi];
      },
      get configuration() {
        if (!apiInstances[ConfigurationApi]) {
          apiInstances[ConfigurationApi] = new ConfigurationApi($axios, $log, config, isAppview);
        }
        return apiInstances[ConfigurationApi];
      },
      get contactForm() {
        if (!apiInstances[ContactFormApi]) {
          apiInstances[ContactFormApi] = new ContactFormApi($axios, $log, config, isAppview);
        }
        return apiInstances[ContactFormApi];
      },
      get contentTiles() {
        if (!apiInstances[ContentTileApi]) {
          apiInstances[ContentTileApi] = new ContentTileApi($axios, $log, config, isAppview);
        }
        return apiInstances[ContentTileApi];
      },
      get country() {
        if (!apiInstances[CountryApi]) {
          apiInstances[CountryApi] = new CountryApi($axios, $log, config, isAppview);
        }
        return apiInstances[CountryApi];
      },
      get file() {
        if (!apiInstances[FileApi]) {
          apiInstances[FileApi] = new FileApi($axios, $log, config, isAppview);
        }
        return apiInstances[FileApi];
      },
      get forgottenPassword() {
        if (!apiInstances[ForgottenPasswordApi]) {
          apiInstances[ForgottenPasswordApi] = new ForgottenPasswordApi($axios, $log, config, isAppview);
        }
        return apiInstances[ForgottenPasswordApi];
      },
      get navigationNodes() {
        if (!apiInstances[NavigationNodesHybrisApi]) {
          apiInstances[NavigationNodesHybrisApi] = new NavigationNodesHybrisApi($axios, $log, config, isAppview);
        }
        return apiInstances[NavigationNodesHybrisApi];
      },
      get newsletter() {
        if (!apiInstances[NewsletterApi]) {
          apiInstances[NewsletterApi] = new NewsletterApi($axios, $log, config, isAppview);
        }
        return apiInstances[NewsletterApi];
      },
      get order() {
        if (!apiInstances[OrderApi]) {
          apiInstances[OrderApi] = new OrderApi($axios, $log, config, isAppview);
        }
        return apiInstances[OrderApi];
      },
      get page() {
        if (!apiInstances[PageHybrisApi]) {
          apiInstances[PageHybrisApi] = new PageHybrisApi($axios, $log, config, isAppview);
        }
        return apiInstances[PageHybrisApi];
      },
      get payback() {
        if (!apiInstances[PaybackApi]) {
          apiInstances[PaybackApi] = new PaybackApi($axios, $log, config, isAppview);
        }
        return apiInstances[PaybackApi];
      },
      get personalization() {
        if (!apiInstances[Personalization]) {
          apiInstances[Personalization] = new Personalization($axios, $log, config, isAppview);
        }
        return apiInstances[Personalization];
      },
      get pet() {
        if (!apiInstances[PetApi]) {
          apiInstances[PetApi] = new PetApi($axios, $log, config, isAppview);
        }
        return apiInstances[PetApi];
      },
      get pickupStation() {
        if (!apiInstances[PickupStationApi]) {
          apiInstances[PickupStationApi] = new PickupStationApi($axios, $log, config, isAppview);
        }
        return apiInstances[PickupStationApi];
      },
      get product() {
        if (!apiInstances[ProductApi]) {
          apiInstances[ProductApi] = new ProductApi($axios, $log, config, isAppview);
        }
        return apiInstances[ProductApi];
      },
      get raffle() {
        if (!apiInstances[RaffleApi]) {
          apiInstances[RaffleApi] = new RaffleApi($axios, $log, config, isAppview);
        }
        return apiInstances[RaffleApi];
      },
      get redirect() {
        if (!apiInstances[RedirectApi]) {
          apiInstances[RedirectApi] = new RedirectApi($axios, $log, config, isAppview);
        }
        return apiInstances[RedirectApi];
      },
      get seocontent() {
        if (!apiInstances[SeoContentApi]) {
          apiInstances[SeoContentApi] = new SeoContentApi($axios, $log, config, isAppview);
        }
        return apiInstances[SeoContentApi];
      },
      get shelters() {
        if (!apiInstances[SheltersApi]) {
          apiInstances[SheltersApi] = new SheltersApi($axios, $log, config, isAppview);
        }
        return apiInstances[SheltersApi];
      },
      get store() {
        if (!apiInstances[StoreApi]) {
          apiInstances[StoreApi] = new StoreApi($axios, $log, config, isAppview);
        }
        return apiInstances[StoreApi];
      },
      get subscription() {
        if (!apiInstances[SubscriptionApi]) {
          apiInstances[SubscriptionApi] = new SubscriptionApi($axios, $log, config, isAppview);
        }
        return apiInstances[SubscriptionApi];
      },
      get user() {
        if (!apiInstances[UserApi]) {
          apiInstances[UserApi] = new UserApi($axios, $log, config, isAppview);
        }
        return apiInstances[UserApi];
      },
      get voting() {
        if (!apiInstances[VotingApi]) {
          apiInstances[VotingApi] = new VotingApi($axios, $log, config, isAppview);
        }
        return apiInstances[VotingApi];
      },
      get wishlist() {
        if (!apiInstances[WishlistApi]) {
          apiInstances[WishlistApi] = new WishlistApi($axios, $log, config, isAppview);
        }
        return apiInstances[WishlistApi];
      },
      get wishpet() {
        if (!apiInstances[WishPetApi]) {
          apiInstances[WishPetApi] = new WishPetApi($axios, $log, config, isAppview);
        }
        return apiInstances[WishPetApi];
      },
    },
    hybrisFn: {
      customer: new CustomerApi($axios, $log, config, isAppview),
    },
    storefront: {
      auth: new AuthApi($axios, $log, config, $host),
    },
    setAccessToken(accessToken) {
      /* @improvement: In the long run, this should be defined on each API and not here */
      const noTokenList = [
        'configuration',
        'country',
        'misc',
        'navigationNodes',
        'newsletter',
        'page',
        'product',
        'redirect',
        'store',
      ];

      Object.entries(this.hybris).forEach(([key, hybrisApi]) => {
        if (!noTokenList.includes(key)) {
          hybrisApi.setAccessToken(accessToken);
        }
      });

      $log.debug('Added access token to api:', $log.inspect(accessToken));
    },
    setBaseStore(baseStore) {
      Object.entries(this.hybris).forEach(([, hybrisApi]) => {
        hybrisApi.setBaseStore(baseStore);
      });

      Object.entries(this.hybrisFn).forEach(([, hybrisFnApi]) => {
        hybrisFnApi.setBaseStore(baseStore);
      });

      $log.debug('Added base store to api:', $log.inspect(baseStore));
    },
    getHeader() {
      $log.debug('Loading header...');
      const { revId, ts } = $router.currentRoute.value.query;
      return this.hybris.page.find('header', revId, ts, mainStore.language, true, config.public.wordpressApiVersion);
    },
    getFooter() {
      $log.debug('Loading footer...');
      const { revId, ts } = $router.currentRoute.value.query;
      return this.hybris.page.find('footer', revId, ts, mainStore.language, true, config.public.wordpressApiVersion);
    },
    getPage(path, cache = true) {
      $log.debug('Loading page...');
      const mainStore = useMainStore();
      const { revId, ts } = $router.currentRoute.value.query;

      let pathCleaned = path;

      if (pathCleaned.startsWith('/')) {
        pathCleaned = pathCleaned.substring(1);
      }

      if (pathCleaned === '') {
        pathCleaned = 'homepage';
      }

      return this.hybris.page
        .find(pathCleaned, revId, ts, mainStore.language, cache, config.public.wordpressApiVersion)
        .then((response) => {
          if (!response?.data?.content) {
            return {};
          }

          if (response.data.author) {
            delete response.data.author;
          }

          const datalayerStore = useDatalayerStore();

          datalayerStore.filter = null;
          datalayerStore.products = null;
          datalayerStore.search = null;
          datalayerStore.error = null;

          const page = response.data;

          if (typeof page.content === 'string') {
            try {
              page.content = JSON.parse(page.content);
            } catch (error) {
              $log.error('Error occurred while JSON.parse()', error);
            }
          }

          const hreflangInfo = page.hrefLangData || [];

          if (hreflangInfo.length) {
            mainStore.hrefLang = hreflangInfo;
          } else {
            mainStore.hrefLang = null;
          }

          if (page.content.author) {
            delete page.content.author;
          }

          if (!page) {
            throw createError({
              statusMessage: $i18n.t('pages.error.message', 404),
              statusCode: 404,
            });
          }

          datalayerStore.addPageProperties({
            category: getPageCategory(page),
            pageType: getPageType(page),
            path: getPagePath(page),
          });

          return page;
        })
        .catch(async (exception) => {
          $log.error('Page loading failed.', exception.message);

          if (isResponseError(exception, 503)) {
            return navigateTo(`https://${$domain}/maintenance/`, { external: true });
          }

          // Check if there is an actual redirect for the given path.
          if (exception?.response?.status === 404) {
            const result = await this.getRedirect(path);

            if (!result) {
              return;
            }

            return navigateTo(result.redirectTo, {
              external: true,
              redirectCode: result.statusCode,
            });
          }

          throw createError({
            statusMessage: import.meta.dev
              ? exception.toString()
              : $i18n.t('pages.error.message', [exception?.response?.status]),
            statusCode: exception?.response?.status,
          });
        });
    },
    getNodes(path, sorted = false) {
      $log.debug(`Loading navigation nodes...`);
      const newPath = path.replace(/(\/)$/g, '');

      return this.hybris.navigationNodes
        .find(`${newPath}/`, mainStore.language, sorted)
        .then((response) => {
          $log.debug('Navigation Nodes loaded.');

          return response.data;
        })
        .catch((error) => {
          $log.error('Navigation Nodes loading failed.', error.message);
          return {};
        });
    },
    getRedirect(path) {
      return this.hybris.redirect
        .find(`/${path}`)
        .then((response) => response.data)
        .catch((error) => {
          if (error.response && error.response.status !== 404) {
            $log.error('Redirect loading failed.', error.response);
          }
          return false;
        });
    },
  };

  api.setBaseStore(mainStore.baseStore);

  $pinia.use(() => ({ $api: api }));

  return {
    provide: {
      api,
    },
  };
});
