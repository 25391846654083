/**
 * Repeatedly executes a callback until it returns a truthy-value.
 *
 * @param condition
 * @param times
 * @param interval
 */
export async function repeatUntil(condition: any, times = 25, interval = 200): Promise<any> {
  const nuxtApp = useNuxtApp();

  return new Promise((resolve, reject) => {
    let counter = 0;

    const tryAgain = () => {
      counter += 1;

      nuxtApp.runWithContext(() => {
        const conditionValue = typeof condition === 'function' ? condition(counter) : condition;

        if (conditionValue) {
          resolve(conditionValue);
        } else if (counter >= times) {
          reject(conditionValue);
        } else {
          setTimeout(tryAgain, interval);
        }
      });
    };
    tryAgain();
  });
}
