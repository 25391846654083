<template>
  <fn-section constrained="l">
    <h1>
      {{ error?.message }}
    </h1>
    <nuxt-link to="/">
      {{ $t('pages.error.buttons.back') }}
    </nuxt-link>
  </fn-section>
</template>

<script setup lang="ts">
const error = useError();
</script>

<style lang="scss" scoped>
.section--error .p-content {
  padding: 0;
}
</style>
