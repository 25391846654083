<template>
  <div
    v-if="loading"
    class="loading-text"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    initialText: {
      type: String,
      required: true,
    },
    textAfter6Seconds: {
      type: String,
      required: true,
    },
    textAfter20Seconds: {
      type: String,
      required: true,
    },
  },
  emits: ['loadingTakesTooLong'],
  data() {
    return {
      interval: null,
      seconds: 0,
    };
  },
  computed: {
    text() {
      if (this.seconds > 20) {
        this.clearInterval();
        this.$emit('loadingTakesTooLong');

        return this.textAfter20Seconds;
      }

      if (this.seconds > 6) {
        return this.textAfter6Seconds;
      }

      return this.initialText;
    },
  },
  watch: {
    loading(value) {
      if (value) {
        this.startInterval();
      } else {
        this.clearInterval();
      }
    },
  },
  mounted() {
    if (this.loading) {
      this.startInterval();
    }
  },
  beforeUnmount() {
    this.clearInterval();
  },
  methods: {
    increaseSeconds() {
      this.seconds += 1;
    },

    startInterval() {
      this.interval = setInterval(this.increaseSeconds, 1000);
    },

    clearInterval() {
      clearInterval(this.interval);
      this.seconds = 0;
    },
  },
};
</script>
