export const getTrackingId = (trackingId, name) => {
  if (!trackingId) {
    return undefined;
  }

  if (!name) {
    return trackingId;
  }

  // Append the name if trackingId ends with pipe separator
  if (trackingId[trackingId.length - 1] === '|') {
    return `${trackingId}${name}`;
  }

  // Append the name but with minus as separator
  return `${trackingId}-${name}`;
};
