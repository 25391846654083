
// @ts-nocheck


export const localeCodes =  [
  "de",
  "at",
  "lu",
  "pl",
  "fr",
  "nl_BE",
  "fr_BE",
  "de_CH",
  "fr_CH",
  "ie",
  "dk"
]

export const localeLoaders = {
  "de": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "at": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "lu": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "pl": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "fr": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "nl_BE": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "fr_BE": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "de_CH": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "fr_CH": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "ie": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }],
  "dk": [{ key: "../lang/languages.ts", load: () => import("../lang/languages.ts?hash=5a15e55c&locale=de" /* webpackChunkName: "locale__build_lang_languages_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "language": "de-DE",
      "isCatchallLocale": true,
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "at",
      "language": "de-AT",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "lu",
      "language": "de-LU",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "pl",
      "language": "pl-PL",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "nl_BE",
      "language": "nl-BE",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "fr_BE",
      "language": "fr-BE",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "de_CH",
      "language": "de-CH",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "fr_CH",
      "language": "fr-CH",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "ie",
      "language": "en-IE",
      "files": [
        "/build/lang/languages.ts"
      ]
    },
    {
      "code": "dk",
      "language": "da-DK",
      "files": [
        "/build/lang/languages.ts"
      ]
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "language": "de-DE",
    "isCatchallLocale": true,
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "at",
    "language": "de-AT",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "lu",
    "language": "de-LU",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "pl",
    "language": "pl-PL",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "nl_BE",
    "language": "nl-BE",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "fr_BE",
    "language": "fr-BE",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "de_CH",
    "language": "de-CH",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "fr_CH",
    "language": "fr-CH",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "ie",
    "language": "en-IE",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  },
  {
    "code": "dk",
    "language": "da-DK",
    "files": [
      {
        "path": "/build/lang/languages.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
