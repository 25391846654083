import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartStoreApi extends AbstractHybrisApi {
  find(
    userId,
    cartId,
    lang = null,
    query = null,
    latitude = null,
    longitude = null,
    radius = 20000,
    filterProperties = [],
  ) {
    return this.request({
      method: 'post',
      version: 'latest',
      url: `/users/${userId}/carts/${cartId}/storeAvailability`,
      params: {
        fields: 'FULL',
        lang,
      },
      data: {
        query,
        latitude,
        longitude,
        radius,
        filterProperties,
      },
    });
  }

  findSingle(userId, cartId, lang, storeId, latitude = null, longitude = null, radius = 20000, filterProperties = []) {
    return this.request({
      method: 'get',
      version: 'latest',
      url: `/users/${userId}/carts/${cartId}/storeAvailabilitySingle/${storeId}`,
      params: {
        fields: 'FULL',
        lang,
      },
      data: {
        latitude,
        longitude,
        radius,
        filterProperties,
      },
    });
  }
}
