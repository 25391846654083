/**
 * Removes Unicode control characters from input while preserving
 * Carriage Return, Shift In and Newline characters.
 */
export const removeUnicodeControlChars = (input: string) => {
  // eslint-disable-next-line no-control-regex
  const unicodeControlCharsRegex = /[\u0000-\u0008\u000B-\u001F\u007F-\u009F\u2028\u2029\uFFFD]/gu;
  return input.replace(unicodeControlCharsRegex, '');
};

/**
 * Replaces Unicode control characters
 *   - return (CR)
 *   - shift in (SI)
 *   - newline (LF)
 * with an escape sequence.
 * Used on textarea form fields where it is allowed to enter these characters,
 * but they must be converted to a new line in order to keep user text format.
 */
export const replaceUnicodeToEscapeSeq = (input: string) => {
  const UNICODE_CR = '\u000D'; // Carriage Return
  const UNICODE_SI = '\u000F'; // Shift In
  const UNICODE_LF = '\u000A'; // Newline

  const regexExp = RegExp(`[${UNICODE_CR}${UNICODE_SI}${UNICODE_LF}]`, 'gu');
  return input.replace(regexExp, '\n');
};

export const sanitizeTextInput = (input: any, expandedMode = false) => {
  if (!input || typeof input !== 'string') {
    return '';
  }

  const sanitizedInput = removeUnicodeControlChars(input)
    .replaceAll(/(<([^>]+)>)/gi, '')
    .replaceAll(/\n/g, ' ')
    .replaceAll('<', '')
    .replaceAll('>', '')
    .replaceAll(encodeURI('<'), '')
    .replaceAll(encodeURI('<'), '');

  return expandedMode ? sanitizedInput.replace(/[.+?!@#$%^&*(){}[]|[\]\\]/g, '') : sanitizedInput;
};

/**
 * Escape special characters like * + ? ^ $ { } ( ) | [ ] \
 * @param {string} value
 * @returns string
 */
export const sanitizeRegex = (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const isValidJson = (input: any) => {
  if (!input || typeof input !== 'string' || input === '') {
    return false;
  }
  try {
    return JSON.parse(input);
  } catch (e) {
    return false;
  }
};

export const sanitizePhoneNumber = (value?: string) => {
  // Remove all special characters and letters
  const alphanumericOnly = value?.replace(/[^0-9]/g, '');

  // Remove leading zeroes
  const sanitizedValue = alphanumericOnly?.replace(/^0+/, '');

  return sanitizedValue || '';
};

// e.g. "Foo   Bar" => "Foo Bar"
export const sanitizeExtraSpaces = (str: string) => str.replace(/\s+/g, ' ').trim();
