export function useGetRobotsContent(robotsMeta: string[]) {
  return computed(() => {
    if (!robotsMeta?.length) {
      return false;
    }

    if (robotsMeta.includes('noindex')) {
      return robotsMeta.join(', ');
    }

    const robotsImage = ['max-snippet:-1', 'max-image-preview:large'];

    return [...robotsMeta, ...robotsImage].join(', ');
  });
}
