import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class ProductApi extends AbstractHybrisApi {
  find(productCode, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/products/${productCode}?fields=FULL`,
      cache: false,
      params: {
        lang,
      },
    });
  }

  search(
    query,
    sort,
    currentPage,
    elevateIds,
    searchIds,
    lang = null,
    excludedFacets,
    facetSortOrder,
    fields = 'FULL',
    pageSize,
    startsWithUrl = null,
    buyAgainIds = [],
    abTests = null,
  ) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/products/search',
      params: {
        fields,
        query,
        sort,
        currentPage,
        pageSize,
        elevateIds,
        searchIds,
        lang,
        excludedFacets,
        facetSortOrder,
        startsWithUrl,
        buyAgainIds,
      },
      cache: false,
      abTests,
    });
  }

  suggestions(term, max = 10, lang = null, abTests = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/products/suggestions',
      params: {
        max,
        term,
        lang,
      },

      abTests,
    });
  }

  topSellers(limit = 5, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/products/topSellers',
      params: {
        limit,
        lang,
      },
    });
  }

  topSearchPhrases(lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/products/topSearchPhrases',
      params: {
        lang,
      },
    });
  }

  preliminaryTotalCost(products, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/products/preliminaryTotalCost',
      params: {
        lang,
      },
      data: products,
    });
  }

  reserve(token, data, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/products/reserve',
      headers: {
        Authorization: `${token.token_type || 'Bearer'} ${token.access_token}`,
      },
      params: {
        lang,
      },
      data,
      cache: false,
    });
  }
}
