const regex = /%[0-9a-f]{2}/i;

export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to) => {
  const { path, query } = to;

  if (path !== '/') {
    const routeNext = {
      path: path.toLowerCase(),
      query,
    };

    const hasPathChanged = path !== routeNext.path;
    const isNumericPath = path.match(regex);

    if (hasPathChanged && !isNumericPath) {
      useNuxtApp().$log.debug(
        `redirecting because of spelling middleware: oldPath: ${path}, newPath: ${routeNext.path}`,
      );

      return navigateTo(routeNext, {
        redirectCode: 301,
      });
    }
  }

  return null;
});
