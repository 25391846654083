/* eslint-disable max-len */
import { resourceNames } from '@/utils/analytics';
import {
  UNAUTHORIZED_ERROR,
  REQUIRES_HARD_LOGIN_ERROR,
  CUSTOMER_NOT_SUFFICIENT_ERROR,
  PASSWORD_UPDATE_REQUIRED,
} from '@/utils/apiErrors';
import { TOKEN_EXPIRED, TOKEN_MUST_BE_REFRESHED, TOKEN_NOT_FOUND, TOKEN_DATE_VALID } from '@/utils/token';
import { LOYALTY_TYPES } from '@/utils/loyalty';
import { PET_TYPE_HORSE, PET_TYPE_CAT, PET_TYPE_DOG } from '@/utils/pet-types';
import { OCC_USER_ID_CURRENT } from '@/utils/occ-constants';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    addresses: [],
    pets: [],
    token: null,
    tealiumAudiences: [],
    trackerUtm: null,
    softLogin: false,
    userPetTypes: null,
    cryptId: null,
    isStoreEditor: false,
    editableStores: [],
    storesSelectables: null,
    posAvailabilitySearch: null,
    favoriteStoreLocal: null,
    favoriteStoreLocalPostalCode: null,
    loadingWasPrevented: false,
    passwordUpdateRequired: false,
    friendsLightActivated: false,
    friendsLightQrCode: null,
    friendsLightError: false,
    friendsStatus: null,
    termsOfServiceAccepted: false,
    shelterInfo: null,
    shelter: null,
  }),
  actions: {
    setPet(pet) {
      const petArray = this.user?.pets?.length ? this.user.pets : this.pets || [];
      const index = petArray.findIndex((p) => p.uid === pet.uid);

      if (index === -1) {
        petArray.push(pet);
      } else {
        petArray.splice(index, 1, pet);
      }
    },
    initializeToken: async function (nuxtApp) {
      const { $log } = nuxtApp;
      const mainStore = useMainStore();

      $log.debug('Initializing token...');

      if (this.getTokenState === TOKEN_NOT_FOUND) {
        $log.debug('No token found.');
      } else if (this.getTokenState === TOKEN_MUST_BE_REFRESHED) {
        $log.debug('Expired with refresh token:', this.token);
        const authType = mainStore.isAppview ? 'app' : 'basic';
        await this.refreshToken({ refreshToken: this.token.refresh_token, type: authType });
      } else if (this.getTokenState === TOKEN_EXPIRED) {
        $log.debug('Expired without refresh token:', this.token);
        this.$reset();
      } else if (this.getTokenState === TOKEN_DATE_VALID) {
        $log.debug('Invalid token date.');
        // Only remove old anonymous token if is not appview
        const removeAnonymous = !this.softLogin && !this.getIsAuthenticated && !mainStore.isAppview;

        if (removeAnonymous) {
          $log.debug('Removing anonymous token...');
          useCookie('token').value = null;
        }
      }

      return Promise.resolve();
    },
    refreshToken: async function ({ refreshToken, type }) {
      const { $log, $api } = useNuxtApp();

      $log.debug('Loading refresh token...');

      try {
        const response = await $api.storefront.auth.refresh(refreshToken, type);
        $log.debug('Refresh token loaded.');

        this.token = response.data;
      } catch (error) {
        $log.error('Error while loading refresh token.', error);
        useCookie('token').value = null;
        this.$reset();
      }
    },
    login: async function ({ username, password, type, grantEcoConsent, recaptchaResponse, newCustomer = false }) {
      const nuxtApp = useNuxtApp();
      const { $log, $api, $i18n, $domain } = nuxtApp;
      const mainStore = useMainStore();

      $log.debug('Logging in...');

      const wasSoftLogin = this.softLogin;
      const cdcSynchronousFlows = mainStore.configuration?.cdcSynchronousFlows;
      const baseSite = mainStore.baseStore;

      return new Promise((resolve, reject) =>
        $api.storefront.auth
          .login(username, password, type, cdcSynchronousFlows, baseSite, grantEcoConsent, recaptchaResponse)
          .then(async (response) => {
            $log.debug('Login successful.');

            this.token = response.data;
            this.softLogin = false;

            await nextTick();

            try {
              await this.loadUser({ id: OCC_USER_ID_CURRENT });

              await Promise.all([
                !wasSoftLogin ? useCartStore().merge(newCustomer) : null,
                useMainStore().loadAccountNodes(nuxtApp),
                this.loadPetTypes(),
              ]);

              const hasConnectedPBAccount = this.getHasConnectedPBAccount;

              if (hasConnectedPBAccount) {
                await usePaybackStore().getPaybackSessionToken({
                  authCode: '',
                  host: `https://${$domain}`,
                  forceStrong: false,
                });
              }
            } catch (error) {
              if (error?.response?.data?.errors?.[0]?.type === 'WrongBaseStoreError') {
                this.$reset();
              }

              // Rethrow instead of reject to log the actual error in the outer .catch()
              throw error;
            }

            return resolve(true);
          })
          .catch((error) => {
            $log.error('Error while logging in.', error);

            if (isResponseError(error, 403, PASSWORD_UPDATE_REQUIRED)) {
              $log.debug('User password update required.');
              this.passwordUpdateRequired = true;
              return navigateTo($i18n.t('url.password.update'));
            }

            return reject(error);
          }),
      );
    },
    logout: async function (invalidToken) {
      if (invalidToken) {
        return null;
      }

      const { $log, $api } = useNuxtApp();

      $log.debug('Logging out....');

      return new Promise((resolve) =>
        $api.storefront.auth
          .logout(this.token)
          .then(() => {
            $log.debug('Logout successful.');
            return resolve();
          })
          .catch((error) => {
            $log.error('Error while logging out.', error);
            resolve();
          }),
      );
    },
    /**
     * @param payload {any}
     */
    loadUser: async function (payload = undefined) {
      const nuxtApp = useNuxtApp();
      const { $log, $api, $errorHandler, $sentry } = nuxtApp;

      $log.debug('Loading current user...');

      const { id = this.getType } = payload || {};
      const { skipAnalytics } = payload || false;
      const { preventSoftLoginModal } = payload || false;
      const { analyticsResourceList } = payload || [resourceNames.USER];

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .find(id)
          .then(async (response) => {
            $log.debug('Current user loaded.');

            // The saved posts coming from occ have a strange nested structure. This simplifies it.
            response.data.savedPosts = response.data.savedPosts?.map((post) => ({
              ...post.contentArticlePage,
              title: post.title,
              thumbnail: post.thumbnail,
            }));

            this.user = response.data;

            $sentry?.setUser(this.user.id ? { id: this.user.id } : null);

            if (response.data.isShelterAccount) {
              await this.loadShelterInfo();
            }

            notifyApp('user');

            if (response.data.isStoreEditor && response.data.editableStores) {
              this.editableStores = response.data.editableStores;
            }

            if (!skipAnalytics) {
              await nuxtApp.runWithContext(async () => {
                const datalayerStore = useDatalayerStore();
                await datalayerStore.loadAnalytics(analyticsResourceList);
              });
            }

            nuxtApp.runWithContext(() => {
              this.setUidCookie();
              this.setNameCookie();
            });

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading current user.', error);

            if (isResponseError(error, 401, UNAUTHORIZED_ERROR)) {
              $log.debug('Reset application because of corrupted token.');
              useMainStore().resetLogout();
              return reject(error);
            }

            if (isResponseError(error, 401, INVALID_TOKEN_ERROR)) {
              $log.debug('Reset application because of corrupted token.');
              useMainStore().resetLogout();
            }

            if (isResponseError(error, 401, REQUIRES_HARD_LOGIN_ERROR)) {
              this.softLogin = true;
            }

            $errorHandler.handleErrorModal(error);
            return reject(error);
          }),
      );
    },
    loadShelterInfo: async function () {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Loading shelter info...');

      try {
        const res = await $api.hybris.shelters.info(this.getType);
        this.shelterInfo = {
          description: res.data.description || [{ description: '', locale: 'de' }],
          media: res.data.media || {},
          legalForm: res.data.legalForm || '',
          associationRegisterNumber: res.data.associationRegisterNumber || '',
          shelterName: res.data.name || '',
        };
      } catch (error) {
        $log.error('Error while loading shelter info.', error);

        if (isResponseError(error, 401, UNAUTHORIZED_ERROR)) {
          $log.debug('Reset application because of corrupted token.');
          this.$reset();
          throw error;
        }

        if (isResponseError(error, 401, REQUIRES_HARD_LOGIN_ERROR)) {
          this.softLogin = true;
        }

        $errorHandler.handleErrorModal(error);
        throw error;
      }
    },
    create: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Creating User...');

      const { language } = useMainStore();

      try {
        const response = await $api.hybris.user.create(data, language);
        $log.debug('User successfully created.');
        this.user = response.data;

        const loginData = {
          username: data.uid,
          password: data.password,
          newCustomer: data?.newCustomer,
        };

        if (data.loginRecaptchaResponse) {
          loginData.recaptchaResponse = data.loginRecaptchaResponse;
        }

        await this.login(loginData);
      } catch (error) {
        $log.error('Error while creating User.', error);

        if (
          error.response?.data?.errors?.[0]?.message !== 'user.creation.failed' &&
          error.response?.data?.errors?.[0]?.type !== 'AuthenticationServiceError'
        ) {
          $errorHandler.handleErrorModal(error, false);
        }

        throw error;
      }
    },
    update: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Updating user...');

      try {
        await $api.hybris.user.update(this.getType, data);
        $log.debug('User updated.');

        const analyticsResourceList = [resourceNames.USER, resourceNames.CART, resourceNames.WISHLIST];
        await this.loadUser({ analyticsResourceList });
      } catch (error) {
        $log.error('Error while updating user.', error);

        $errorHandler.handleErrorModal(error, false);

        throw error;
      }
    },
    patch: async function (payload) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Patching user...');

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .patch(this.getType, payload.data)
          .then(async () => {
            $log.debug('User patched.');

            const skipAnalytics = payload.options?.skipAnalytics || false;
            const analyticsResourceList = [resourceNames.USER, resourceNames.CART, resourceNames.WISHLIST];
            this.loadUser({ skipAnalytics, analyticsResourceList });

            return resolve(true);
          })
          .catch((error) => {
            $log.error('Error while patching user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    updateShelterInfo: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Updating shelter info...');

      return new Promise((resolve, reject) =>
        $api.hybris.shelters
          .update(this.getType, data)
          .then(async () => {
            $log.debug('Shelter info updated.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating shelter info.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    verifyAddress: async function (data) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Verifying address...');

      return new Promise((resolve, reject) =>
        $api.hybris.address
          .verify(this.getType, data)
          .then((response) => {
            if (response.data.decision === 'REVIEW' || response.data.decision === 'REJECT') {
              $log.debug('Address was rejected.');
              return reject(response.data);
            }

            $log.debug('Address verified.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while verifying address.', error);

            return resolve();
          }),
      );
    },
    loadAddresses: async function () {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading addresses of current user...');

      return new Promise((resolve, reject) =>
        $api.hybris.address
          .all(this.getType)
          .then((response) => {
            $log.debug('Addresses loaded.');

            this.addresses = response.data.addresses;
            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading addresses of current user.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    createAddress: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding address to user...');

      return new Promise((resolve, reject) =>
        $api.hybris.address
          .create(this.getType, data)
          .then((response) => {
            $log.debug('Address added.');

            return resolve(response.data);
          })
          .catch((error) => {
            $log.error('Error while adding address to user.', error);

            if (error.response) {
              $errorHandler.handleErrorModal(error, false);
            }

            return reject(error);
          }),
      );
    },
    updateAddress: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Updating address of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.address
          .update(this.getType, data.id, data)
          .then(() => {
            $log.debug('Address updated.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating address of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    patchAddress: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Patching address of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.address
          .patch(this.getType, data.id, data)
          .then(() => {
            $log.debug('Address patched.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while patching address of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    deleteAddress: async function ({ addressId }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Deleting address of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.address
          .delete(this.getType, addressId)
          .then(() => {
            $log.debug('Address deleted.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while deleting address of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    loadPets: async function () {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading pets of current user...');

      return new Promise((resolve, reject) =>
        $api.hybris.pet
          .all(this.getType)
          .then((response) => {
            $log.debug('Pets loaded.');

            this.pets = response.data.pets;
            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading pets of current user.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    createPet: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Adding pet to user...');

      return new Promise((resolve, reject) =>
        $api.hybris.pet
          .create(this.getType, data)
          .then((petId) => {
            $log.debug('Pet added.');

            return resolve(petId);
          })
          .catch((error) => {
            $log.error('Error while adding pet to user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    updatePet: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Updating pet of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.pet
          .update(this.getType, data.uid, data)
          .then((response) => {
            $log.debug('Pet updated.');

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while updating pet of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    patchPet: async function (data) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Patching pet of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.pet
          .patch(this.getType, data.uid, data)
          .then(() => {
            $log.debug('Pet patched.');

            return resolve(true);
          })
          .catch((error) => {
            $log.error('Error while updating pet of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    deletePet: async function ({ uid }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Deleting pet of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.pet
          .delete(this.getType, uid)
          .then(async () => {
            $log.debug('Pet deleted.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while deleting pet of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    updateEmail: async function ({ newEmail, password, recaptchaResponse }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Updating email of user...');
      const { language } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .updateEmail(this.getType, newEmail, password, recaptchaResponse, language)
          .then(async () => {
            $log.debug('Email updated.');

            await this.$reset();
            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating email of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    updateGuestEmail: async function ({ newEmail, oldEmail }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Updating guest email of user...');
      const { language } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .updateGuestEmail(this.getType, newEmail, oldEmail, language)
          .then(async () => {
            $log.debug('Guest Email updated.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating guest email of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    updatePassword: async function ({ oldPassword, newPassword }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Updating password of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .updatePassword(this.getType, oldPassword, newPassword)
          .then(async () => {
            $log.debug('Password updated.');

            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating password of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    forgotPassword: async function ({ email, redirect, recaptchaResponse }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Requesting forgot password token...');
      const { language } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.forgottenPassword
          .token(email, language, redirect, recaptchaResponse)
          .then(async () => {
            $log.debug('Forgot password token requested.');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while requesting forgot password token.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    resetPassword: async function ({ newPassword, token }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Resetting password...');

      return new Promise((resolve, reject) =>
        $api.hybris.forgottenPassword
          .reset(newPassword, token)
          .then(async () => {
            $log.debug('Password reset.');

            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while resetting password.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    updatePaybackNumber: async function ({ number }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Updating payback number of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .updatePaybackNumber(this.getType, number)
          .then((response) => {
            $log.debug('Payback number updated.');

            this.user = response.data;
            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while updating payback number of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    deletePaybackNumber: async function () {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Deleting payback number of user...');

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .deletePaybackNumber(this.getType)
          .then((response) => {
            $log.debug('Payback number deleted.');

            this.user = response.data;
            notifyApp('user');

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while deleting payback number of user.', error);

            $errorHandler.handleErrorModal(error, false);

            return reject(error);
          }),
      );
    },
    setTokenCookie: function () {
      useCookie('token', {
        path: '/',
        maxAge: ONE_YEAR,
        secure: !import.meta.dev,
      }).value = this.token;
    },
    setUidCookie: function () {
      useCookie('uid', {
        path: '/',
        maxAge: ONE_YEAR,
        secure: !import.meta.dev,
      }).value = this.getUid;
    },
    setNameCookie: function () {
      if (!this.user?.defaultAddress?.lastName) {
        return;
      }

      const cookie = useCookie('name', {
        path: '/',
        maxAge: ONE_YEAR,
        secure: import.meta.production,
      });

      cookie.value = {
        firstName: this.user?.defaultAddress?.firstName || '',
        lastName: this.user?.defaultAddress?.lastName || '',
      };
    },
    setNameFromCookie: function () {
      const name = unref(useCookie('name'));

      if (name?.lastName) {
        this.user = {
          ...this.user,
          defaultAddress: {
            ...name,
          },
        };
      }
    },
    setFavoriteStore: async function ({ storeNumber }) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      if (!this.getIsAuthenticated || this.softLogin) {
        return saveToLocalStorage('favoriteStoreNumber', { storeNumber });
      }
      $log.debug('Setting favorite store...');
      return new Promise((resolve, reject) =>
        $api.hybris.user
          .setFavoriteStore(this.getType, storeNumber)
          .then((response) => {
            $log.debug(`Favorite store was set to ${storeNumber}`);

            return resolve(response.data);
          })
          .catch((error) => {
            $log.error('Error while setting favorite store', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    setFavoriteStorePostCode: function (postalCode) {
      this.favoriteStoreLocalPostalCode = { postalCode };
      return saveToLocalStorage('favoriteStorePostCode', { postalCode });
    },
    setFavoriteStoreLocal: function (store) {
      this.favoriteStoreLocal = store;
    },
    resetFavoriteStore: async function () {
      deleteFromLocalStorage('favoriteStorePostCode');
      deleteFromLocalStorage('favoriteStoreNumber');
      deleteFromLocalStorage('favoriteStore');
      this.favoriteStoreLocal = null;
      this.favoriteStoreLocalPostalCode = null;

      if (!this.getIsAuthenticated) {
        return null;
      }

      const { $log, $api, $errorHandler } = useNuxtApp();

      $log.debug('Removing favorite store...');
      return new Promise((resolve, reject) =>
        $api.hybris.user
          .resetFavoriteStore(this.getType)
          .then((response) => {
            $log.debug('Favorite store information was reset');
            notifyApp('user');

            return resolve(response.data);
          })
          .catch((error) => {
            $log.error('Error while resetting favorite store', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    loadTrackingInfo: async function ({ carrierId, postalCode, trackingId }) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Loading order tracking information...');

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .getTrackingInfo(this.getType, carrierId, postalCode, trackingId)
          .then((response) => {
            $log.debug('Tracking Information for current order loaded.');
            notifyApp('user');

            return resolve(response.data);
          })
          .catch((error) => {
            $log.error('Error while loading Tracking Information.', error);

            return reject(error);
          }),
      );
    },
    loadPetTypes: async function () {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading pet types...');
      const { language } = useMainStore();

      return new Promise((resolve, reject) =>
        $api.hybris.pet
          .types(this.getType, language)
          .then((response) => {
            $log.debug('Pets types loaded.');

            this.userPetTypes = response.data;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading pet types', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    loadSmartProductRecos: async function (searchParams) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading Smart product recommendations...');

      const mainStore = useMainStore();
      const abTests = mainStore.abTests.tests;
      const params = [
        searchParams.query,
        searchParams.querySorting,
        null,
        null,
        null,
        searchParams.queryLanguage,
        null,
        null,
        'FULL',
        searchParams.queryLimit,
        null,
        [],
        abTests,
      ];

      return new Promise((resolve, reject) =>
        $api.hybris.product
          .search(...params)
          .then((response) => {
            $log.debug('Smart product recommendations loaded.');

            return resolve(response.data.products);
          })
          .catch((error) => {
            $log.error('Error while loading Smart product recommendations', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    loadBuyAgainProducts: async function (resolveOnError = false) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Loading buy again products...');
      const userType = this.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.order
          .buyAgainProducts(userType)
          .then((response) => {
            $log.debug('Buy again products loaded.');

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while loading buy again products', error);

            if (resolveOnError || error.response.data.errors[0].type === CUSTOMER_NOT_SUFFICIENT_ERROR) {
              return resolve({}); // the response is an object now
            }

            $errorHandler.handleErrorModal(error);
            return reject(error);
          }),
      );
    },
    updateEditableStore: async function (storeToUpdate) {
      const { $log, $api } = useNuxtApp();
      const userToken = this.token;
      const userId = this.getCryptId;

      $log.debug('Updating editable store...');
      try {
        await $api.hybris.store.updateStore(userToken, userId, storeToUpdate);
        $log.debug('Store updated successfully...');
      } catch (error) {
        $log.error('Error updating editable store');
        $log.error(error);
        throw error;
      }
    },
    loadTealiumAudiences: async function () {
      if (!import.meta.client) {
        return Promise.resolve();
      }

      const cookie = useCookie('no-adobe');

      if (cookie?.value) {
        return Promise.resolve();
      }

      const { $log } = useNuxtApp();

      $log.debug('Loading Tealium audiences...');

      return repeatUntil(() => window?._satellite?.getVar)
        .then(() => {
          this.tealiumAudiences = window._satellite.getVar('TealiumAudiences') || [];
          $log.debug('Tealium audiences loaded:', this.tealiumAudiences);
        })
        .catch((error) => {
          this.tealiumAudiences = [];
          if (error) {
            logger.error('Failed to load Tealium audiences:', error);
          }
        });
    },
    loadAbTests: async function () {
      if (!import.meta.client) {
        return Promise.resolve();
      }

      const cookie = useCookie('no-adobe');

      if (cookie?.value) {
        return Promise.resolve();
      }

      const { $log } = useNuxtApp();
      const mainStore = useMainStore();

      $log.debug('Loading AB Tests...');

      return repeatUntil(() => {
        const abTestsCookie = unref(useCookie('FN-UX'));

        if (!abTestsCookie || mainStore.isAppview) {
          mainStore.setAbTests(undefined);
          return;
        }

        const enabledAbTests = {};

        for (const [name, enabled] of Object.entries(abTestsCookie)) {
          mainStore.abTests[name] = !!enabled;

          if (mainStore.abTests[name]) {
            enabledAbTests[name] = mainStore.abTests[name];
          }
        }

        mainStore.setAbTests(enabledAbTests);

        return true;
      })
        .then(() => {
          $log.debug('AB tests loaded:', mainStore.abTests);
        })
        .catch((error) => {
          if (error) {
            logger.error('Failed to load AB tests:', error);
          }
        });
    },
    addSavedPost: async function (pageId) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Adding saved post...');
      const userType = this.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .addSavedPost(userType, pageId)
          .then(async (response) => {
            $log.debug('Added saved post.', response);
            await this.loadUser();

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while adding saved post', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    removeSavedPost: async function (pageId) {
      const { $log, $api, $errorHandler } = useNuxtApp();
      $log.debug('Removing saved post...');
      const userType = this.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .removeSavedPost(userType, pageId)
          .then(async (response) => {
            $log.debug('Removed saved post.', response);

            await this.loadUser();

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while removing saved post', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          }),
      );
    },
    setSoftloginState: async function () {
      const { $log, $api } = useNuxtApp();
      const config = useRuntimeConfig();
      const apiPath = config.public.hybrisBaseUrl;
      const isProdEnv = apiPath.includes('api.os');
      const isAppview = useCookie('appview')?.value === 1;
      const router = useRouter();

      if (
        isProdEnv ||
        isAppview ||
        unref(router.currentRoute).query?.triggerSoftlogin !== '1' ||
        this.softLogin ||
        !this.getIsAuthenticated
      ) {
        return;
      }

      const user = this.user;
      try {
        await $api.hybrisFn.customer.setSoftLogin(user.uid);
        $log.debug('SoftLogin state set.');
      } catch (error) {
        $log.error('Error occurred while trying to set softLogin state.');
      }
    },
    activateFriends: async function ({ loyaltyType }) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Activating friends of type:', loyaltyType);
      const userType = this.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .activateFriends(userType, loyaltyType)
          .then(async (response) => {
            $log.debug(`Friends of type ${loyaltyType} activated.`);

            if (loyaltyType === LOYALTY_TYPES.FRIENDS_LIGHT) {
              this.friendsStatus = response.data;
              this.friendsLightActivated = true;
            }

            if (loyaltyType === LOYALTY_TYPES.LITTLE_FRIENDS) {
              this.friendsStatus = response.data;
            }

            return resolve(response);
          })
          .catch((error) => {
            $log.error('Error while activating friends of type:', loyaltyType);
            $log.error(error);

            const reactivationMessage = 'Customer already has active loyalty program';
            if (error.response.status === 400 && error.response.data.errors[0].message === reactivationMessage) {
              return resolve();
            }

            if (loyaltyType === LOYALTY_TYPES.FRIENDS_LIGHT) {
              this.friendsLightError = true;
            }

            return reject(error);
          }),
      );
    },
    deactivateFriends: async function (loyaltyType) {
      const { $log, $api } = useNuxtApp();
      $log.debug('Deactivating friends of type:', loyaltyType);
      const userType = this.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .deactivateFriends(userType, loyaltyType)
          .then(async () => {
            $log.debug(`Friends of type ${loyaltyType} deactivated.`);
            this.loadLoyaltyStatus();

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while deactivating friends of type:', loyaltyType);
            $log.error(error);

            return reject(error);
          }),
      );
    },
    retrieveFriendsLightQrCode: async function () {
      const { $log, $api } = useNuxtApp();
      $log.debug('Fetching friends light QR Code...');
      const userType = this.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .friendsLightQrCode(userType)
          .then((response) => {
            if (!response || response.status !== 200 || !response.data) {
              this.friendsLightError = true;
              return reject(new Error(response));
            }

            $log.debug('Friend light QR Code fetched.');
            this.friendsLightQrCode = response.data.code;
            return resolve(response.data.code);
          })
          .catch((error) => {
            $log.error('Error while fetching QR Code', error);
            this.friendsLightError = true;

            return reject(error);
          }),
      );
    },
    loadLoyaltyStatus() {
      const { $log, $api } = useNuxtApp();
      $log.debug('Start fetching friends status');

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .getLoyaltyStatus(this.getType)
          .then((response) => {
            $log.debug('Successfully fetched friends status');
            $log.debug(response.data);
            this.friendsStatus = response.data;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while fetching friends status', error);
            return reject(error);
          }),
      );
    },
  },
  getters: {
    getDefaultPaymentMode(state) {
      if (!state.user) return null;
      return state.user.defaultPaymentMode;
    },
    getIsAuthenticated(state) {
      return !!state.token?.refresh_token;
    },
    getType() {
      return this.getIsAuthenticated ? OCC_USER_ID_CURRENT : OCC_USER_ID_ANONYMOUS;
    },
    getTokenState(state) {
      return getTokenState(state.token);
    },
    getDisplayUid(state) {
      if (!state.user) return null;
      return state.user.displayUid;
    },
    getUid(state) {
      if (!state.user) return OCC_USER_ID_ANONYMOUS;
      return state.user?.uid;
    },
    getHasAddresses(state) {
      return state.addresses?.length;
    },
    getAddress: (state) => (addressId) => {
      return state.addresses?.find((address) => address.id === addressId);
    },
    getDefaultAddress(state) {
      if (!state.user) return null;
      const { defaultAddress } = state.user;

      return defaultAddress || state.addresses?.[0];
    },
    getShelterInfo(state) {
      return state.user?.isShelterAccount ? state.shelterInfo : null;
    },
    getBillingAddress(state) {
      if (!state.user) return null;

      if (state.user.defaultAddress) {
        return state.user.defaultAddress;
      }

      return null;
    },
    getDefaultShippingAddress(state) {
      if (!state.user) return null;

      if (state.user.defaultShippingAddress) {
        return state.user.defaultShippingAddress;
      }

      return null;
    },
    getPets(state) {
      if (state.pets?.length) {
        return state.pets;
      }
      if (state.user?.pets?.length) {
        return state.user.pets;
      }
      return [];
    },
    getPetsFilteredByType(state) {
      return state.pets.filter((pet) => pet.type !== PET_TYPE_HORSE && pet.type !== 'OTHERS');
    },
    getClubPets(state) {
      if (!state.user?.pets) {
        return [];
      }
      const { pets } = state.user;
      const mainStore = useMainStore();
      const kittenclub = mainStore.getKittenclub;
      return pets.filter(
        (pet) =>
          (pet.petType === PET_TYPE_DOG && pet.memberships?.includes('WPC')) ||
          (kittenclub && pet.petType === PET_TYPE_CAT && pet.memberships?.includes('KITTEN_CLUB')),
      );
    },
    getBillingAgreement(state) {
      return state.user?.billingAgreement || null;
    },
    getUserFullNameOrUid(state) {
      // returns the users full name if set in default address or during checkout.
      // otherwise returns the uid which is the mail address of the user.
      // - user.uid can be an email address or 'anonymous'? Why?
      // - user.name can be 'Anonymous'? Why? A real name is NOT stored in user.name, but in user.defaultAddress.(fir|la)stName
      // - in softlogin status, getters.uid is 'anonymous', but $cookies.get('uid') is an email address
      // - in softlogin status, user now also has a full name stored in defaultAddress.(fir|la)stName and $cookies.get('name')
      // - to make things worse, there is also a user.displayUid
      if (state.user && (state.getUid !== 'anonymous' || state.user.name !== 'Anonymous')) {
        if (this.getDefaultAddress) {
          return `${state.getDefaultAddress.firstName} ${state.getDefaultAddress.lastName}`;
        }
        if (state.user.name) {
          return state.user.name;
        }
        return state.getUid;
      }
      return null;
    },
    getUserFirstNameOrUid(state) {
      if (state.user && (state.getUid !== 'anonymous' || state.user.name !== 'Anonymous')) {
        if (state.getDefaultAddress) {
          return `${state.getDefaultAddress.firstName}`;
        }
        if (state.user.name) {
          return state.user.name;
        }
        return state.getUid;
      }
      return null;
    },
    getUserFirstName(state) {
      if (state.user && (state.getUid !== 'anonymous' || state.user.name !== 'Anonymous')) {
        if (state.getDefaultAddress) {
          return `${state.getDefaultAddress.firstName}`;
        }
        if (state.user.name) {
          return state.user.name;
        }
      }
      return null;
    },
    getUserFavoriteStore(state) {
      if (!state.user) return null;
      return state.user?.favoriteStore;
    },
    getFavoriteStoreLocalPostalCode(state) {
      return state.favoriteStoreLocalPostalCode;
    },
    getCryptId(state) {
      if (!state.user) return null;
      return state.user.cryptId;
    },
    getReviewUserToken(state) {
      if (!state.user) return null;
      return state.user.usertoken;
    },
    getHasConnectedPBAccount(state) {
      if (!state.user) return null;
      return state.user.paybackAccountConnected;
    },
    getValidPaybackSessionToken(state) {
      if (!state.user) return null;
      return state.user.validPaybackSessionToken;
    },
    getHasFriendsBenefit(state) {
      if (!state.user) return null;
      return !!state.user?.savingsPlan?.active;
    },
    getFriendsBenefitExpirationDate(state) {
      if (!state.user) return null;
      return state.user?.savingsPlan?.dateExpire;
    },
    getSavedPosts(state) {
      if (!state.user) return [];
      return state.user.savedPosts;
    },
    getFriendsLightQrCode(state) {
      if (!state.friendsLightQrCode) {
        return null;
      }

      return state.friendsLightQrCode.toString();
    },
    getUser(state) {
      return state.user;
    },
    getPosAvailabilitySearch(state) {
      return state.posAvailabilitySearch;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
