<template>
  <div
    v-if="isShown"
    :id="id ? id : undefined"
    class="section"
    :class="[
      {
        'section--shifted': shifted,
        'section--shifted-negative': shiftedNegative,
        'section--no-padding': noPadding,
        'section--no-padding-x': noPaddingX,
        'section--centered': centered,
        'section--app-hidden': isAppview && displayViewValue === 'hide-app',
        'section--web-hidden': !isAppview && displayViewValue === 'hide-web',
        'section--has-smile': enableSmile,
        'section--has-smile-upper': upperSmile,
        'section--has-smile-negative': enableSmileNegative,
        'section--overflown': overflow,
      },
      constrained ? 'section--constrained-' + constrained : '',
      backgroundColor && !backgroundColorMobile ? 'section--bg-' + backgroundColor : '',
      foregroundColor && !foregroundColorMobile ? 'section--fg-' + foregroundColor : '',
      backgroundColor && backgroundColorMobile ? 'section--desktop-bg-' + backgroundColor : '',
      foregroundColor && foregroundColorMobile ? 'section--desktop-fg-' + foregroundColor : '',
      backgroundColorMobile ? 'section--mobile-bg-' + backgroundColorMobile : '',
      foregroundColorMobile ? 'section--mobile-fg-' + foregroundColorMobile : '',
      paddingTop ? 'section--pt-' + paddingTop : '',
      paddingBottom ? 'section--pb-' + paddingBottom : '',
      displaySetting ? displaySetting : '',
      bordered ? 'section--bordered' : '',
    ]"
    :style="{ backgroundColor: customBgColorCode }"
  >
    <div
      v-if="upperSmile"
      :class="[
        { 'section-smile-upper': upperSmile },
        backgroundColor && !backgroundColorMobile ? 'section--smile-' + backgroundColor : '',
      ]"
      :style="{ '--bg-color': customBgColorCode }"
    >
      <svg viewBox="0 0 1249 130">
        <path d="M1249.8,0v129.3c0,0-130.6,0-261.3-28.5C899,81.2,683.8,1,480.5,1C204.8,1,0,79.6,0,79.6V0H1249.8z" />
      </svg>
    </div>
    <div class="section-content-wrapper">
      <div
        v-if="$slots.image"
        class="section-image"
        :class="[
          {
            'section--image-single': singleImage,
            'section--image-right': sectionImageRight,
            'section--image-left': sectionImageLeft,
          },
        ]"
      >
        <slot name="image" />
      </div>

      <div class="section-content">
        <slot />
      </div>

      <div
        v-if="$slots.imageMobile"
        class="section-image-mobile"
      >
        <slot name="imageMobile" />
      </div>
    </div>
    <div
      v-if="enableSmile || enableSmileNegative"
      ref="smile"
      :class="[
        { 'section-smile': enableSmile },
        { 'section-smile-negative': enableSmileNegative },
        { 'section--smile-custom': customBgColorCode },
        backgroundColor && !backgroundColorMobile ? 'section--smile-' + backgroundColor : '',
      ]"
      :style="{
        '--bg-color': customBgColorCode,
      }"
    >
      <template v-if="enableSmile">
        <svg viewBox="0 0 1679.79 178.26">
          <path
            d="M1679.79,0V4.47s-175.58,0-351.15,38.34C1208.19,69.13,919,178.27,645.84,178.27,275.19,178.27,0,71.22,0,71.22V0Z"
          />
        </svg>
      </template>
      <template v-if="enableSmileNegative">
        <svg viewBox="0 0 1440 111">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M300.985 24.27C150.489 0 0 0 0 0V111H1439.82V42.2456C1439.82 42.2456 1203.95 109.986 886.237 109.986C652.137 109.986 404.226 40.92 300.985 24.27Z"
            fill="white"
          />
        </svg>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    constrained: {
      type: String,
      default: null,
    },
    centered: {
      type: Boolean,
    },
    shifted: {
      type: Boolean,
    },
    shiftedNegative: {
      type: Boolean,
    },
    paddingTop: {
      type: String,
      default: null,
    },
    paddingBottom: {
      type: String,
      default: null,
    },
    noPadding: {
      type: Boolean,
    },
    noPaddingX: {
      type: Boolean,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    foregroundColor: {
      type: String,
      default: null,
    },
    backgroundColorMobile: {
      type: String,
      default: null,
    },
    foregroundColorMobile: {
      type: String,
      default: null,
    },
    headline: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    displaySetting: {
      type: String,
      default: null,
    },
    displayView: {
      type: [String, Array],
      default: null,
    },
    displayUserSetting: {
      type: [String, Array],
      default: null,
    },
    bordered: {
      type: Boolean,
    },
    customBgColorCode: {
      type: String,
      default: null,
    },
    sectionImageRight: {
      type: Boolean,
    },
    sectionImageLeft: {
      type: Boolean,
    },
    enableSmile: {
      type: Boolean,
      default: false,
    },
    enableSmileNegative: {
      type: Boolean,
      default: false,
    },
    upperSmile: {
      type: Boolean,
      default: false,
    },
    singleImage: {
      type: Boolean,
      default: false,
    },
    overflow: {
      type: Boolean,
    },
    imageOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapWritableState(useMainStore, {
      sectionSmileHeight: 'sectionSmileHeight'
    }),

    ...mapState(useMainStore, {
      isAppview: 'isAppview',
    }),

    ...mapState(useUserStore, {
      isAuthenticated: 'getIsAuthenticated',
      isSoftLoggedIn: 'softLogin',
    }),

    isShown() {
      if (!this.displayUserSetting || this.displayUserSetting === 'default' || this.displayUserSetting.length < 1) {
        return true;
      }
      if (this.displayUserSetting === 'user' && this.isAuthenticated && !this.isSoftLoggedIn) {
        return true;
      }
      return (
        (this.displayUserSetting === 'guest' && !this.isAuthenticated) ||
        (this.displayUserSetting === 'guest' && this.isSoftLoggedIn)
      );
    },

    isDisplayViewArray() {
      return Array.isArray(this.displayView);
    },

    displayViewValue() {
      return this.isDisplayViewArray ? this.displayView[0] : this.displayView;
    },
  },
  mounted() {
    if (this.enableSmile) {
      this.sectionSmileHeight = this.$refs.smile?.clientHeight;
    }
  },
});
</script>

<style lang="scss" scoped>
.section {
  position: relative;
  padding: 0 $grid-margin-width * 0.5;
  min-height: 20px;

  .section-content {
    position: relative;
  }

  .section-header {
    margin-bottom: $pad;

    .headline--icon {
      display: flex;
      align-items: baseline;
      font-size: 24px;

      .icon {
        margin-right: $pad;
      }
    }
  }

  .section-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    :deep(picture, img) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &.section--image-right {
      :deep(picture, img) {
        right: 0;
        position: absolute;
        width: auto;
      }
    }

    &.section--image-left {
      :deep(picture, img) {
        left: 0;
        position: absolute;
        width: auto;
      }
    }

    &.section--image-single {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      overflow: hidden;
      width: 50%;

      :deep(picture, img) {
        object-fit: cover;
        height: auto;
        right: 1rem;
        position: absolute;
        width: auto;
        max-width: 400px;

        @include bp('l', max) {
          right: 0;
        }
      }

      @include bp(m, max) {
        display: none;
      }
    }
  }

  .section-image-mobile {
    display: none;

    :deep(picture, img) {
      object-fit: cover;
      width: auto;
      height: 100%;
    }

    @include bp(m, max) {
      display: flex;
      padding-top: $pad * 2;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      :deep(picture, img) {
        max-width: 50vw;
        min-width: 250px;
      }
    }
  }

  .section-smile {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .section-smile-upper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    path {
      fill: $color-white;
    }
  }

  .section-smile-negative {
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  &.section--has-smile {
    margin-bottom: 11%;
  }

  &.section--has-smile-negative {
    overflow: hidden;
  }

  &.section--constrained-s {
    @include bp(m) {
      .section-content {
        max-width: $content-min-width;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.section--constrained-m {
    @include bp(m) {
      .section-content {
        max-width: math.div($content-max-width, math.div($grid-total-columns, 10));
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.section--constrained-l {
    @include bp(m) {
      .section-content {
        max-width: $content-max-width;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &.section--constrained-xl {
    @include bp(xl-wp) {
      .section-content {
        max-width: map-get($breakpoints, 'xl-wp');
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.section--constrained-xxl {
    @include bp(xxl) {
      .section-content {
        max-width: map-get($breakpoints, 'xxl');
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.section--shifted {
    margin-top: 120px;
    padding-bottom: 1px;

    .section-content {
      top: -120px;
      margin-bottom: -120px;
    }
  }

  &.section--shifted-negative {
    margin-bottom: 120px;
    padding-top: 1px;

    .section-content {
      bottom: -120px;
      margin-top: -120px;
    }
  }

  // Padding Top
  &.section--pt-xxs {
    padding-top: $pad * 0.5;

    .section-image-mobile {
      margin-bottom: -$pad * 0.5;
    }
  }

  &.section--pt-xs {
    padding-top: $pad;

    .section-image-mobile {
      margin-bottom: -$pad * 0.5;
    }
  }

  &.section--pt-s {
    padding-top: $pad * 2;

    .section-image-mobile {
      margin-bottom: -$pad * 2;
    }
  }

  &.section--pt-m {
    padding-top: $pad * 4;

    .section-image-mobile {
      margin-bottom: -$pad * 4;
    }
  }

  &.section--pt-l {
    padding-top: $pad * 6;

    .section-image-mobile {
      margin-bottom: -$pad * 6;
    }
  }

  &.section--pt-xl {
    padding-top: $pad * 8;

    .section-image-mobile {
      margin-bottom: -$pad * 8;
    }
  }

  // Padding Bottom
  &.section--pb-xxs {
    padding-bottom: $pad * 0.5;
  }

  &.section--pb-xs {
    padding-bottom: $pad;
  }

  &.section--pb-s {
    padding-bottom: $pad * 2;
  }

  &.section--pb-m {
    padding-bottom: $pad * 4;
  }

  &.section--pb-l {
    padding-bottom: $pad * 6;
  }

  &.section--pb-xl {
    padding-bottom: $pad * 8;
  }

  // No padding
  &.section--no-padding {
    padding: 0;
    min-height: 0;

    :deep(.grid-container) {
      overflow: hidden;
    }
  }

  &.section--no-padding-x {
    padding-left: 0;
    padding-right: 0;

    :deep(.grid-container) {
      overflow: hidden;
    }
  }

  &.section--no-padding-y {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }

  &.section--bordered {
    border-radius: map-get($border-radiuses, 'l');
  }

  &.section--no-padding-x.section--bordered,
  &.section--no-padding-x .section--bordered {
    @include bp(m, max) {
      border-radius: 0;
    }
  }

  &.section--centered {
    text-align: center;
  }

  &.section--web-hidden,
  &.section--app-hidden {
    display: none;
  }

  &.section--overflown {
    :deep(.grid-container) {
      overflow: visible;
    }
  }

  // Colors
  @each $palette, $tones in $palettes {
    @each $tone, $hex in $tones {
      @if $tone == 'base' {
        &.section--bg-#{$palette} {
          background-color: $hex;
        }
        &.section--fg-#{$palette} {
          color: $hex;
        }
        &.section--mobile-bg-#{$palette} {
          @include bp(m, max) {
            background-color: $hex;
          }
        }
        &.section--mobile-fg-#{$palette} {
          @include bp(m, max) {
            color: $hex;
          }
        }
        &.section--desktop-bg-#{$palette} {
          @include bp(m) {
            background-color: $hex;
          }
        }
        &.section--desktop-fg-#{$palette} {
          @include bp(m) {
            color: $hex;
          }
        }
        & .section--smile-#{$palette} svg {
          fill: $hex;
        }
      } @else {
        &.section--bg-#{$palette}-#{$tone} {
          background-color: $hex;
        }
        &.section--fg-#{$palette}-#{$tone} {
          color: $hex;
        }
        &.section--mobile-bg-#{$palette}-#{$tone} {
          @include bp(m, max) {
            background-color: $hex;
          }
        }
        &.section--mobile-fg-#{$palette}-#{$tone} {
          @include bp(m, max) {
            color: $hex;
          }
        }
        &.section--desktop-bg-#{$palette}-#{$tone} {
          @include bp(m) {
            background-color: $hex;
          }
        }
        &.section--desktop-fg-#{$palette}-#{$tone} {
          @include bp(m) {
            color: $hex;
          }
        }
        & .section--smile-#{$palette}-#{$tone} svg {
          fill: $hex;
        }
      }
    }
  }

  .left-aligned {
    text-align: left;
  }

  .mid-aligned {
    text-align: center;
  }

  .right-aligned {
    text-align: right;
  }

  & .section--smile-custom svg {
    fill: var(--bg-color);
  }

  &.section--has-smile-upper {
    padding-top: 130px;

    @include bp(s, max) {
      padding-top: 50px;
    }

    @include bp(s, max) {
      padding-top: 80px;
    }
  }
}
</style>
