import type { LocaleObject } from 'vue-i18n-routing';

export const useIsoLang = () => {
  const { $i18n } = useNuxtApp();
  const mainStore = useMainStore();
  const config = useRuntimeConfig();

  const isoLang = (unref($i18n.locales) as Array<LocaleObject>).find((locale) =>
    mainStore.language === config.public.isoDe
      ? locale.code.toLowerCase() === mainStore.defaultCountry.toLowerCase()
      : locale.code.toLowerCase() === mainStore.language.toLowerCase(),
  )?.language;

  return isoLang;
};
