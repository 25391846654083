export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  // Don't delete payment-mode for AppView: app opens another window to handle payment-provider step,
  // the deletion prevents successful checkout with f.ex. CreditCard
  if (!useMainStore().isAppview) {
    try {
      await useCartStore().deletePaymentMode();
    } catch (error) {
      return navigateTo({
        path: '/checkout/payment/',
        query: {
          ...to.query,
          error: 'true',
        },
      });
    }
  }

  return navigateTo({
    path: '/checkout/payment/',
    query: {
      ...to.query,
      cancelled: 'true',
    },
  });
});
