export const CUSTOMER_NOT_SUFFICIENT_ERROR = 'CustomerNotSufficientError';
export const GENERIC_NOT_FOUND_ERROR = 'notFound';
export const CART_NOT_FOUND_ERROR = 'Cart not found.';
export const UNAUTHORIZED_ERROR = 'UnauthorizedError';
export const REQUIRES_HARD_LOGIN_ERROR = 'RequireHardLoginError';
export const PASSWORD_UPDATE_REQUIRED = 'passwordUpdateRequired';
export const INVALID_TOKEN_ERROR = 'InvalidTokenError';
export const CUSTOMER_RISK_CHECK_ERROR = 'CustomerRiskCheckError';

// possible BE errors which doesn't mean the order fails, just need more time to place the order in BE side
export const ORDER_RETRY_ERRORS_FOR_PAYMENTS = [
  'JaloObjectNoLongerValidError',
  'retryLater',
  'LockTimeoutError',
  'LockedModelRemovedError',
];

/**
 * Checks whether a response error contains a given status and error message.
 * @param error {object}
 * @param status {number}
 * @param messages {string|string[]} Error messages string or array
 * @returns {boolean}
 */
export const isResponseError = (error, status, messages = []) => {
  const errors = error?.response?.data?.errors || Array(error?.response?.data);
  const errorMessages = Array.isArray(messages) ? messages : [messages];

  // Check status code
  const statusMatches = error?.response?.status === status;

  // Check error message, handles Promise-wrapped
  // errors (e.reason) and normal errors (e.message)
  const messageMatches =
    !errorMessages.filter((item) => item).length ||
    errors?.some(
      (e) =>
        errorMessages.includes(e?.reason) ||
        errorMessages.includes(e?.message) ||
        errorMessages.includes(e?.type) ||
        // The only case should be 'ecoconsent.missing' during login
        errorMessages.includes(e?.error_description),
    );

  return statusMatches && messageMatches;
};
