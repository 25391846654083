<template>
  <div
    id="loading-icon"
    class="loading-icon"
    :class="{
      'loading-icon--light': light,
    }"
  />
</template>

<script>
export default {
  props: {
    light: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-icon {
  display: block;
  width: auto;
  height: 100%;
  max-width: 70px;
  max-height: 70px;
  aspect-ratio: 1 / 1;
  background-image: url('#{$img-path}preloader.gif');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 11;

  &.loading-icon--light {
    background-image: url('/img/preloader-light.gif');
  }
}
</style>
