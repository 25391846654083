export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  if (to.query.code) {
    const { $domain } = useNuxtApp();
    const { setPaybackAuthCode } = useCartStore();

    try {
      await setPaybackAuthCode({
        authCode: to.query.code as string,
        host: `https://${$domain}`,
      });

      return navigateTo('/cart/payback-success/');
    } catch (error) {
      return navigateTo('/cart/payback-error/');
    }
  } else if (to.query.error) {
    return navigateTo('/cart/payback-error/');
  }

  return navigateTo('/cart/');
});
