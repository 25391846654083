export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, () => {
  const userStore = useUserStore();

  if (userStore.getIsAuthenticated) {
    return navigateTo(useNuxtApp().$i18n.t('url.storeEdit.dashboard'));
  }

  if (userStore.getIsAuthenticated && !userStore.softLogin) {
    return navigateTo('/');
  }
});
