import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartAddressApi extends AbstractHybrisApi {
  create(userId, cartId, addressType, data) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/addresses/${addressType}`,
      params: {
        fields: 'FULL',
      },
      data,
    });
  }

  set(userId, cartId, addressType, addressId) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/addresses/${addressType}`,
      params: {
        addressId,
      },
    });
  }

  delete(userId, cartId, addressType) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/addresses/${addressType}`,
    });
  }
}
