<template>
  <fn-page-container>
    <fn-cart-payback-error />
  </fn-page-container>
</template>

<script setup lang="ts">
definePageMeta({
  name: 'cart-payback-error',
  layout: 'minimal',
});

usePageMeta({});
</script>
