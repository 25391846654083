import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class VotingApi extends AbstractHybrisApi {
  getVotes(votes, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/voting/votes',
      params: {
        query: votes,
        lang,
      },
    });
  }

  vote(voteID, voteUp = true, recaptchaResponse = null, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/voting',
      data: {
        code: voteID,
        customer: '',
        voteUp,
        recaptchaResponse,
      },
      params: {
        lang,
      },
    });
  }

  getUserVote(userId, groupCode, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/voting/vote`,
      params: {
        groupCode,
        lang,
      },
    });
  }

  placeUserVote(userId, groupCode, subjectCode, recaptcha = null, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/voting/vote`,
      params: {
        lang,
        subjectCode,
        groupCode,
        recaptcha,
      },
    });
  }
}
