<template>
  <slot v-bind="{ visible }" />
</template>

<script setup lang="ts">
import type { MaybeRef } from 'vue';

const visible = ref(false);

provide('handleInsertReco', (value: MaybeRef<boolean>) => {
  visible.value = !!unref(value);
});
</script>
