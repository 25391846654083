<template>
  <img
    class="payback-logo"
    src="@/assets/img/payback-logo.svg"
    :width="width"
    :alt="$t('paymentModes.payback.title')"
  />
</template>

<script>
export default defineComponent({
  props: {
    fixedWidth: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    width() {
      return this.fixedWidth ? '70' : undefined;
    },
  },
});
</script>
