import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CountryApi extends AbstractHybrisApi {
  all() {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/countries',
      params: {
        fields: 'FULL',
      },
    });
  }

  shipping(lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/countries',
      params: {
        fields: 'FULL',
        type: 'SHIPPING',
        lang,
      },
    });
  }

  billing(lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/countries',
      params: {
        fields: 'FULL',
        type: 'BILLING',
        lang,
      },
    });
  }
}
