import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class PaybackApi extends AbstractHybrisApi {
  getPaybackAuthorizationUrl(userId, cartId, host, lang = null, paybackNumberOverride = null, redirectPath) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/payback/getPaybackAuthorizationUrl`,
      params: {
        host,
        lang,
        paybackNumberOverride,
        redirectPath,
      },
    });
  }

  getSessionToken(userId, cartId, authCode = null, forceStrong = false, lang = null, host = null, redirectPath = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/payback/sessionToken`,
      params: {
        authCode,
        forceStrong,
        host,
        lang,
        redirectPath,
      },
    });
  }

  connectAccount(userId, cartId, host, lang = null, redirectPath) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/payback/connectAccount`,
      params: {
        host,
        lang,
        redirectPath,
      },
    });
  }

  disconnectAccount(userId, cartId, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/payback/disconnectAccount`,
      params: {
        lang,
      },
    });
  }

  consents(userId, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/payback/consents`,
      params: {
        lang,
      },
    });
  }

  updateMarketingConsent(userId, accept, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/payback/updateMarketingConsent`,
      params: {
        accept,
        lang,
      },
    });
  }
}
