import https from 'https';
import axios, { type AxiosRequestConfig } from 'axios';
import { searchParamsSerializer } from '@/utils/axios/serializer';
import { urlWithoutDoubleSlashesInterceptor } from '@/utils/axios/interceptors';

export const useAxios = (config?: AxiosRequestConfig) => {
  const instance = axios.create(config);

  instance.defaults.paramsSerializer = searchParamsSerializer;
  instance.interceptors.request.use(urlWithoutDoubleSlashesInterceptor);

  if (import.meta.server) {
    // Allow self-signed dev certificates from untrusted CAs
    // TODO: We could pass the CA certificate instead of universally disabling this
    instance.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: true });
  }

  return instance;
};
