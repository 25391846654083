/**
 * @deprecated Use `HideFromCrawl` composable instead
 */
export default {
  props: {
    hideFromCrawl: {
      type: Boolean,
    },
    hideFromCrawlContent: {
      type: Boolean,
    },
    static: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    /**
     * @deprecated Removed from `HideFromCrawl` and now part of `Links` composable, search there when working with it
     */
    hrefAttribute() {
      if (this.hideFromCrawl) {
        return null;
      }

      return this.href ? 'href' : null;
    },
  },
  methods: {
    async handleHideFromCrawlClick() {
      if (this.href) {
        return navigateTo(this.to, {
          open: {
            target: this.target || '_self',
          },
        });
      }

      if (this.to) {
        if (this.target === '_blank') {
          return this.handleMiddleClick();
        } else {
          return navigateTo({ path: this.to });
        }
      }

      return false;
    },

    async handleMiddleClick() {
      if (this.disabled) {
        return false;
      }

      if (this.href) {
        return navigateTo(this.href, {
          open: {
            target: '_blank',
          },
        });
      }

      if (this.to) {
        const routeData = this.$router.resolve({ path: this.to });
        return navigateTo(routeData.href, {
          open: {
            target: '_blank',
          },
        });
      }
    },

    async handleClick(event) {
      if (this.hideFromCrawl) {
        return this.handleHideFromCrawlClick(event);
      }

      if (this.static || this.to || this.href || ('submit' in this && this.submit)) {
        return false;
      }

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      if (this.disabled) {
        return false;
      }

      this.$emit('click', event);
    },
    /**
     * Used for handling click events inside content where
     * multiple <a> tags exist
     * @param {MouseEvent} event
     * @param {string} to - target url
     * @param {string} target - behavior on target url open
     */
    handleClickContent(event, to, target) {
      if (to) {
        return navigateTo(to, {
          open: {
            target: target === '_blank' ? '_blank' : '_self',
          },
        });
      }

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    },
  },
};
