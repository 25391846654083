import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class StoreApi extends AbstractHybrisApi {
  all(query = null, latitude = null, longitude = null, currentPage = 0, lang = null, onlySalons = false, radius) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/stores',
      params: {
        query,
        sort: 'asc',
        currentPage,
        lang,
        latitude,
        longitude,
        radius: radius || 25000,
        fields: 'FULL',
        onlySalons,
      },
    });
  }

  allFiltered(
    query = null,
    latitude = null,
    longitude = null,
    lang = null,
    radius = 20000,
    filterProperties = [],
    sort = 'asc',
  ) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/stores',
      params: {
        fields: 'FULL',
        sort,
        lang,
      },
      data: {
        query,
        latitude,
        longitude,
        radius,
        filterProperties,
      },
    });
  }

  find(id, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/stores/${id}?fields=FULL`,
      params: {
        lang,
      },
    });
  }

  getSeoStores(lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/stores/seo',
      params: {
        lang,
      },
    });
  }

  getRegionsStores(lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/stores/regions',
      params: {
        lang,
      },
    });
  }

  getStoreLocation(location) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/stores?fields=DEFAULT&pageSize=3&query=${location}&radius=100000&&sort=asc&inactive=0`,
    });
  }

  updateStore(token, userid, store) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/stores/${userid}/updatestore`,
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
      },
      data: {
        ...store,
      },
    });
  }

  getStoresSelectables() {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/stores/selectablevalues',
    });
  }

  getProductAvailability(
    query = null,
    latitude = null,
    longitude = null,
    currentPage = 0,
    lang = null,
    onlySalons = false,
    radius,
    sort,
    productId,
  ) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/stores/availability/${productId}`,
      params: {
        query,
        sort,
        currentPage,
        lang,
        latitude,
        longitude,
        radius: radius || 25000,
        fields: 'FULL',
        onlySalons,
      },
    });
  }
}
