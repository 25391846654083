import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class NavigationNodesApi extends AbstractHybrisApi {
  find(category, lang = null, sorted = false) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/cms/wordpress/navigationnodes?url=${category}&sorted=${sorted}`,
      cache: true,
      params: {
        lang,
      },
    });
  }
}
