export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const { path } = to;
  const indexOfUl = path.indexOf('/ul/');

  if (indexOfUl !== -1) {
    const newPath = path.substring(0, indexOfUl) + path.substring(indexOfUl + 3);

    const newRoute = {
      path: newPath,
      query: to.query,
      hash: to.hash,
    };

    return navigateTo(newRoute, {
      redirectCode: 301,
    });
  }

  return null;
});
