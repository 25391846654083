<template>
  <div id="fn-bonial-widget" />
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const { bonialAuthKey } = useRuntimeConfig().public;

    return {
      bonialAuthKey,
    };
  },
  mounted() {
    const bonialLibrary = document.createElement('script');
    bonialLibrary.src = 'https://bonialconnect.com/v2/widget.js';
    document.body.appendChild(bonialLibrary);

    const waitInterval = setInterval(() => {
      if (!(window as any).BonialServices) {
        return;
      }

      clearInterval(waitInterval);

      (window as any).BonialServices.init('#fn-bonial-widget', {
        authKey: this.bonialAuthKey,
      });
    }, 100);
  },
});
</script>
