<template>
  <fn-adobe-target-mbox :id="data.id" />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
