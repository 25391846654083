export const icons = [
  'add-to-cart-new',
  'add-to-cart-new-bold',
  'alarm',
  'alert',
  'animal-love',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-thin-left',
  'arrow-thin-right',
  'arrow-up',
  'avatar',
  'avatar-new',
  'badge',
  'badge-f',
  'basket-heart',
  'bazaarvoice',
  'bidirectional-arrow',
  'book',
  'btn-play',
  'cake',
  'calendar-heart',
  'camera',
  'car',
  'car-package',
  'care',
  'cart',
  'cart-filled-new',
  'cart-new',
  'cart-plus',
  'cat',
  'cat-breed',
  'cat-equipment',
  'cat-kitten',
  'charity',
  'check',
  'check-bold',
  'checklist',
  'checkmark-circle-invert',
  'checkmark-thin',
  'checkmark-filled',
  'circle-dots',
  'circle-euro',
  'circle-i',
  'circle-i-light',
  'circle-meat',
  'circle-minus',
  'circle-no-additives',
  'circle-no-grain',
  'circle-percent',
  'circle-plus',
  'circle-questionmark',
  'circle-tick',
  'circle-time',
  'circle-x',
  'cloud-upload',
  'collar-with-bone',
  'consultation',
  'coupon-euro',
  'credit-card',
  'cross',
  'delivery-new',
  'dog',
  'dog-breed',
  'dog-play',
  'dog-puppy',
  'dog-travel',
  'double-arrow-left',
  'double-arrow-right',
  'download',
  'dropdown',
  'education',
  'email',
  'email-with-at',
  'empty-wishlist',
  'eye',
  'facebook',
  'filter',
  'fish',
  'female',
  'footer-facebook',
  'footer-insta',
  'footer-pinterest',
  'footer-tiktok',
  'footer-whatsapp',
  'footer-youtube',
  'funnel',
  'gender',
  'gift',
  'gift-circled',
  'globe',
  'guidebook',
  'health',
  'heart',
  'heart-filled-new',
  'heart-inverted',
  'heart-new',
  'heart-plus',
  'help',
  'house',
  'instagram',
  'instagram-inverted',
  'interaction',
  'infographic',
  'location-pin',
  'location-pin-heart',
  'location-pin-heart-new',
  'location-pin-heart-empty',
  'location-pin-heart-filled-new',
  'locator',
  'lock-closed',
  'lock-closed-o',
  'lock-open',
  'lp-cat',
  'lp-cat-outline',
  'lp-dog',
  'lp-dog-outline',
  'magnifying-glass',
  'magnifying-glass-bold',
  'magnifying-glass-bold-new',
  'magnifying-glass-new',
  'male',
  'marketplace',
  'menu',
  'minus',
  'none',
  'not-found-error',
  'not-supported-error',
  'nutrition',
  'package',
  'package-return',
  'parrot',
  'paw',
  'paw-search',
  'payback',
  'payback-new',
  'pdf',
  'pen',
  'percent',
  'pet-food',
  'petmoji',
  'phone',
  'piggy-bank',
  'pinterest',
  'plus',
  'pond',
  'preloader',
  'present-o',
  'printer',
  'quiz-finish',
  'rabbit',
  'rebrand-facebook',
  'rebrand-instagram',
  'rebrand-youtube',
  'reptile',
  'returns',
  'returns-calendar',
  'returns-label',
  'route',
  'ruler',
  'scale',
  'share',
  'shield-tick',
  'shipping-new',
  'speech-bubbles',
  'speech-bubbles-new',
  'ssl',
  'star',
  'star-o',
  'step-1',
  'step-2',
  'step-3',
  'success-smiley',
  'supersale',
  'tag',
  'thumbs-up',
  'tiktok',
  'touch',
  'toys',
  'trash',
  'trash-new',
  'truck',
  'uploaded-file',
  'vet',
  'video',
  'warning',
  'whatsapp',
  'youtube',
  'xmas',
];

export const iconsSizes = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

export const colors = [
  'black',
  'green',
  'green-dark',
  'green-darker',
  'green-light',
  'green-lighter',
  'grey',
  'grey-dark',
  'grey-light',
  'orange',
  'happyColor-green',
  'happyColor-orange',
  'happyColor-yellow',
  'happyColor-blue',
  'happyColor-purple',
  'happyColor-red',
  'payback',
  'payback-light',
  'payback-lighter',
  'payback-lightest',
  'red',
  'red-dark',
  'sand',
  'sand-light',
  'terra',
  'white',
  'orientation-green',
  'orientation-orange',
  'orientation-yellow',
  'orientation-blue',
  'orientation-purple',
  'orientation-red',
  'background-green',
  'background-orange',
  'background-yellow',
  'background-blue',
  'background-purple',
];

export const keysForSeoStoreAccordion = [
  'city',
  'phone',
  'welcome',
  'street',
  'relationship',
  'relationship2',
  'sortiment1',
  'sortiment2',
  'sortiment3',
  'sortiment4',
  'sortiment5',
  'sortiment6',
  'price1',
  'service1',
  'service2',
  'service3',
  'visit',
  'dog1',
  'dog2',
  'cat1',
  'cat2',
  'cat3',
  'fish1',
  'fish2',
  'fish3',
  'fish4',
  'bird1',
  'expert1',
  'expert2',
  'expert3',
  'expert4',
  'expert5',
  'expert6',
  'expert7',
  'usp',
  'usp1',
  'usp2',
  'usp3',
  'usp4',
  'usp5',
  'profile1',
  'profile2',
  'voucher1',
  'voucher2',
  'voucher3',
  'voucher4',
  'voucher5',
  'magazin1',
  'magazin2',
  'magazin3',
  'barf',
];

export const keysForSeoTemplateWithBarf = ['fressnapf', 'sortiment', 'services', 'barf', 'contact'];

export const keysForSeoTemplateWithoutBarf = ['fressnapf', 'sortiment', 'services', 'contact'];
