import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartVoucherApi extends AbstractHybrisApi {
  all(userId, cartId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/vouchers`,
    });
  }

  create(userId, cartId, voucherCode, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/vouchers`,
      params: {
        voucherId: voucherCode,
        lang,
      },
    });
  }

  delete(userId, cartId, voucherCode) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/vouchers/${voucherCode}`,
    });
  }
}
