export const LOYALTY_TYPES = {
  FRIENDS_LIGHT: 'FRIENDS_LIGHT',
  FRIENDS: 'FRIENDS',
  LITTLE_FRIENDS: 'LITTLE_FRIENDS',
  PAYBACK: 'PAYBACK',
};

export const LOYALTY_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
};

export const LOYALTY_STEP = {
  GUEST: 'GUEST',
  FRIENDS: 'FRIENDS',
  FRIENDS_AND_LITTLE_FRIENDS: 'FRIENDS_AND_LITTLE_FRIENDS',
  FRIENDS_AND_LITTLE_FRIENDS_PENDING: 'FRIENDS_AND_LITTLE_FRIENDS_PENDING',
  FRIENDS_AND_LITTLE_FRIENDS_INACTIVE: 'FRIENDS_AND_LITTLE_FRIENDS_INACTIVE',
};
