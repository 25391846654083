const componentsToShow = [
  'accordion',
  'advantages',
  'advice_accordion',
  'advice_icon_link',
  'advice_intro',
  'advice_overview_intro',
  'advice_panel_image',
  'advice_topic_teaser',
  'article_headline',
  'brand_slider',
  'card_teaser',
  'card_slider',
  'checklist',
  'contact_form',
  'friends_branded_panel',
  'contact_reason',
  'download_button',
  'expert_profile',
  'friends_info_panel',
  'hashtags',
  'headline',
  'icon_link_list',
  'icon_slider_teaser',
  'icon_text_list',
  'image',
  'image_teaser',
  'image_text_panel',
  'newsletter_preferences_sections',
  'newsletter_register_form',
  'notification_text',
  'pet_profile',
  'picture',
  'product_category_slider',
  'product_slider',
  'progression_box_slider',
  'quote',
  'raffle',
  'related_articles',
  'simple_slider',
  'stage_slider',
  'stage_teaser',
  'step_list',
  'table',
  'table_of_content',
  'tag_list',
  'text',
  'text_icon_teaser',
  'text_image_teaser_group',
  'videos',
];

const headlineTypes = ['article_headline', 'headline'];

export const componentIsWhitelabeled = (component) => componentsToShow.includes(component);

export const isSingleHeadline = (component, listLength) =>
  component && listLength === 1 && headlineTypes.includes(component);
