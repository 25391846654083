/* eslint-disable import/no-duplicates */
import LogoFressNapfPetmoji from '@/assets/img/fressnapf-petmoji.svg';
import LogoFressNapfPetmojiRaw from '@/assets/img/fressnapf-petmoji.svg?raw';
import LogoFressNapfPetmojiSize from '@/assets/img/fressnapf-petmoji.svg?imageSize';
import LogoMaxizooPetmoji from '@/assets/img/maxizoo-petmoji.svg';
import LogoMaxizooPetmojiRaw from '@/assets/img/maxizoo-petmoji.svg?raw';
import LogoMaxizooPetmojiSize from '@/assets/img/maxizoo-petmoji.svg?imageSize';
import LogoMaxizooPetmojiGreen from '@/assets/img/maxizoo-petmoji-green.svg';
import LogoFressNapfPetmojiGreen from '@/assets/img/fressnapf-petmoji-green.svg';
/* eslint-enable import/no-duplicates */

// Smallest reliable placeholder image, see https://stackoverflow.com/a/13139830
export const placeholderImageDataUri = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const imageService = {
  /**
   * @see https://confluence.fressnapf.de/display/OP/ImageService
   */
  createSizes() {
    return {
      prod_xxs: { width: 80, height: 80, extend: true },
      prod_xs: { width: 150, height: 150, extend: true },
      prod_s: { width: 350, height: 350, extend: true },
      prod_m: { width: 800, height: 800, extend: true },
      prod_l: { width: 1300, height: 1300, extend: true },
      prod_max: { width: 5000, height: 5000, extend: true },
      prodtp_xxs: { width: 80, height: 80, extend: true },
      prodtp_xs: { width: 150, height: 150, extend: true },
      prodtp_s: { width: 350, height: 350, extend: true },
      prodtp_m: { width: 800, height: 800, extend: true },
      prodtp_l: { width: 1300, height: 1300, extend: true },
      prodtp_max: { width: 5000, height: 5000, extend: true },
      cmssprite_s: { width: 993, height: 620 },
      cmssprite_m: { width: 1298, height: 620 },
      cmssprite_l: { width: 1920, height: 386 },
      cmsimg_150: { width: 150 },
      cmsimg_220: { width: 220 },
      cmsimg_300: { width: 300 },
      cmsimg_360: { width: 360 },
      cmsimg_450: { width: 450 },
      cmsimg_540: { width: 540 },
      cmsimg_640: { width: 640 },
      cmsimg_920: { width: 920 },
      cmsimg_1100: { width: 1100 },
    };
  },

  /**
   * @param url {string} URL to image on image service
   * @param size {string} A key of ImageServiceSizes
   * @returns {url: string, width: number, height: number} Image object
   */
  getSizedImage(url, size) {
    const sizes = this.createSizes();
    if (!sizes[size]) {
      throw new Error(`Invalid image service size: ${size}`);
    }

    return {
      url: `${url}?t=${size}`,
      ...sizes[size],
    };
  },

  /**
   * @param width {number} Original width of an image
   * @param height {number} Original height of an image
   * @param transformation {string} Transformation to apply onto original image dimensions
   * @returns {{width: number, height: number}} Transformed size
   */
  getTransformedSize(width, height, transformation) {
    const sizes = this.createSizes();
    const sizeTransformed = sizes?.[transformation];

    if (
      // No transformation found, so original size can be used
      !sizeTransformed?.width ||
      // Image is smaller but should not extend, so using original size
      (!sizeTransformed.extend && (width < sizeTransformed.width || height < sizeTransformed.height))
    ) {
      return {
        width,
        height,
      };
    }

    // Height is not always fixed and known. In case we don't, we can use
    // the transformation width to derive the transformation height from it.
    if (!sizeTransformed.height) {
      sizeTransformed.height = (height * sizeTransformed.width) / width;
    }

    return sizeTransformed;
  },
};

export const getImageUrl = (image) => {
  if (typeof image === 'string') {
    return image;
  }

  if (image?.url) {
    return image.url;
  }

  if (image?.src) {
    return image.src;
  }

  return '';
};

export const getLogo = (language, greenLogo) => {
  const logoBaseStoreName = ['de', 'at', 'lu', 'de_CH', 'fr_CH'].includes(language) ? 'fressnapf' : 'maxizoo';

  if (logoBaseStoreName === 'fressnapf') {
    return {
      inline: LogoFressNapfPetmojiRaw,
      src: greenLogo ? LogoFressNapfPetmojiGreen : LogoFressNapfPetmoji,
      ...LogoFressNapfPetmojiSize,
    };
  }

  return {
    inline: LogoMaxizooPetmojiRaw,
    src: greenLogo ? LogoMaxizooPetmojiGreen : LogoMaxizooPetmoji,
    ...LogoMaxizooPetmojiSize,
  };
};
