import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class PetApi extends AbstractHybrisApi {
  all(userId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/pets`,
      params: {
        fields: 'FULL',
      },
    });
  }

  find(userId, petId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/pets/${petId}`,
      params: {
        fields: 'FULL',
      },
    });
  }

  create(userId, data) {
    return this.request({
      method: 'post',
      version: 'v3',
      url: `/users/${userId}/pets`,
      params: {
        fields: 'FULL',
      },
      data,
    });
  }

  update(userId, petId, data) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/pets/${petId}`,
      data,
    });
  }

  patch(userId, petId, data) {
    return this.request({
      method: 'patch',
      version: 'v2',
      url: `/users/${userId}/pets/${petId}`,
      data,
    });
  }

  delete(userId, petId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/pets/${petId}`,
    });
  }

  types(userId, lang = null) {
    return this.request({
      method: 'get',
      version: 'v3',
      url: `/users/${userId}/pets/types`,
      params: {
        lang,
      },
    });
  }
}
