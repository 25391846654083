export const removeLanguagePrefix = (path: string) => {
  const mainStore = useMainStore();

  let pageUrl = path;

  if (!mainStore.isMultilang) {
    return pageUrl;
  }

  const prefix = mainStore.getUrlPrefix as string;

  if (pageUrl.startsWith(prefix)) {
    pageUrl = pageUrl.substring(prefix.length);
  }

  return pageUrl;
};
