<template>
  <fn-default
    v-if="mainStore.errorPage?.content"
    :page="mainStore.errorPage"
  />
  <fn-section
    v-else
    constrained="l"
  >
    <h1>
      {{ error?.message }}
    </h1>
  </fn-section>
</template>

<script setup lang="ts">
const error = useError();
const mainStore = useMainStore();

if (!mainStore.errorPage) {
  await mainStore.loadCmsErrorPage();
}
</script>

<style lang="scss" scoped>
.section--error .p-content {
  padding: 0;
}
</style>
