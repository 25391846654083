import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class WishPetApi extends AbstractHybrisApi {
  getQuizData(quizCode, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/wishpet/${quizCode}/configuration`,
      params: {
        lang,
      },
    });
  }

  calculateQuizResult(quizCode, wishPetAdvisoryAnswers, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/wishpet/${quizCode}`,
      data: {
        wishPetAdvisoryAnswers,
      },
      params: {
        lang,
      },
    });
  }
}
