export default {
  methods: {
    isNuxtLink(link) {
      return link && !link.includes('http') ? link : undefined;
    },
    isHrefLink(link) {
      return link?.includes('http') ? link : undefined;
    },
  },
};
