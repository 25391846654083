<template>
  <component
    :is="tag"
    :href="computedHref ? computedHref : undefined"
    :target="target ? target : undefined"
    :to="to"
    :disabled="disabled"
    :data-id="id"
    :type="tag === 'button' ? (submit ? 'submit' : 'button') : undefined"
    tabindex="0"
    class="button-round"
    :class="{
      'button-round--disabled': disabled,
      'button-round--plain': plain,
      'button-round--secondary': secondary,
      'button-round--tertiary': tertiary,
      'button-round--teaser': teaser,
    }"
    @click="handleClick"
  >
    <slot />
  </component>
</template>

<script>
import { getHref, getTag } from '@/utils/links';

export default {
  props: {
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Boolean,
    },
    plain: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    tertiary: {
      type: Boolean,
    },
    teaser: {
      type: Boolean,
    },
  },
  computed: {
    tag() {
      return getTag(this, 'button');
    },

    computedHref() {
      return getHref(this);
    },
  },
  methods: {
    handleClick(event) {
      if (this.to || this.href || this.submit) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      if (this.disabled) {
        return;
      }

      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-round {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $space;
  height: $space;
  background-color: palette(green, light);
  border-radius: 100%;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: 0.1s ease-in all;
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: palette(green);
    outline: 0;
  }

  &.button-round--disabled,
  &:disabled,
  &:disabled[disabled] {
    background-color: palette(black);
    color: $color-general-text-inverse-default;
    cursor: default;
    pointer-events: none;
    opacity: 0.15;
  }

  &.button-round--plain {
    background: none;
  }

  &.button-round--secondary {
    background-color: palette(background, green);
    border: 2px solid palette(orientation, green);
  }

  &.button-round--tertiary {
    background-color: $color-general-background-neutral-default;
    border: 2px solid palette(grey, light);
  }

  &.button-round--teaser {
    display: block;
    width: 48px;
    height: 48px;
    background-color: $color-general-background-neutral-default;
    border: 2px solid palette(orientation, green);
  }
}
</style>
