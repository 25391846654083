import type { RouteLocation } from 'vue-router';

type HideFromCrawlProps = {
  isStatic?: boolean;
  to?: string | RouteLocation;
  href?: string;
  hideFromCrawl?: boolean;
  target?: string;
  disabled?: boolean;
  submit?: boolean;
};

type HideFromCrawlEmit = (name: string, event: PointerEvent) => void;

export const useHideFromCrawl = (props: HideFromCrawlProps, emit: HideFromCrawlEmit) => {
  const router = useRouter();

  const path = computed(() => {
    if (typeof props.to === 'string' && !props.to.startsWith('?')) {
      return props.to;
    } else if (typeof props.to === 'object' && 'path' in props.to) {
      return props.to.path;
    }
    return '';
  });

  const query = computed(() => {
    if (String(props.to).startsWith('?')) {
      return Object.fromEntries(new URLSearchParams(props.to as string));
    } else {
      return null;
    }
  });

  const handleMiddleClick = () => {
    if (props.disabled) {
      return;
    }

    if (props.href) {
      return navigateTo(props.href, {
        open: {
          target: '_blank',
        },
      });
    }

    if (props.to) {
      const routeData = router.resolve({ path: path.value });
      return navigateTo(routeData.href, {
        open: {
          target: '_blank',
        },
      });
    }
  };

  const handleHideFromCrawlClick = async () => {
    if (props.href) {
      return navigateTo(props.href, {
        open: {
          target: props.target || '_self',
        },
      });
    }

    if (props.to) {
      if (props.target === '_blank') {
        return handleMiddleClick();
      } else if (query.value) {
        return navigateTo({ query: query.value });
      } else {
        return navigateTo(path.value);
      }
    }
  };

  const handleClick = async (event: PointerEvent) => {
    emit('priorityClick', event);

    if (props.hideFromCrawl) {
      return handleHideFromCrawlClick();
    }

    if (props.isStatic || props.to || props.href || ('submit' in props && props.submit)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (props.disabled) {
      return;
    }

    emit('click', event);
  };

  return {
    handleMiddleClick,
    handleClick,
  };
};
