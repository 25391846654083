export const SHIPPING_TYPE_PARCEL = 'PARCEL';
export const SHIPPING_TYPE_SPEDITION = 'SPEDITION';
export const SHIPPING_TYPE_DROPSHIP = 'DROPSHIP';
export const SHIPPING_TYPE_FROZEN = 'FROZEN';

export const SHIPPING_DURATION_PARCEL = 'PARCEL';
export const SHIPPING_DURATION_SPEDITION = 'SPEDITION';
export const SHIPPING_DURATION_DROPSHIP = 'DROPSHIP';
export const SHIPPING_DURATION_OUTOFSTOCK = 'OUTOFSTOCK';
export const SHIPPING_DURATION_REPLENISHMENT_EARLY = 'REPLENISHMENT_EARLY';
export const SHIPPING_DURATION_REPLENISHMENT_MEDIUM = 'REPLENISHMENT_MEDIUM';
export const SHIPPING_DURATION_REPLENISHMENT_LATE = 'REPLENISHMENT_LATE';

export const SHIPPING_CARRIER_ID_DHL = '2504727';

export const SHIPPING_RETURNED = 'RETURNED';
export const SHIPPING_PARTLYRETURNED = 'PARTLYRETURNED';
export const SHIPPING_CANCELED = 'CANCELED';
export const SHIPPING_PARTLYCANCELED = 'PARTLYCANCELED';
export const SHIPPING_OPEN = 'OPEN';
export const SHIPPING_INPROCESS = 'INPROCESS';
export const SHIPPING_SHIPPED = 'SHIPPED';
export const SHIPPING_PARTLYSHIPPED = 'PARTLYSHIPPED';
export const SHIPPING_DELIVERED = 'DELIVERED';
export const SHIPPING_STORED = 'STORED';
export const SHIPPING_IN_DELIVERY = 'IN_DELIVERY';
export const SHIPPING_REGISTERED_FOR_DELIVERY = 'REGISTERED_FOR_DELIVERY';
