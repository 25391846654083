export const TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND';
export const TOKEN_DATE_VALID = 'TOKEN_DATE_VALID';
export const TOKEN_MUST_BE_REFRESHED = 'TOKEN_MUST_BE_REFRESHED';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const getTokenState = (token) => {
  if (!token) {
    return TOKEN_NOT_FOUND;
  }

  if (new Date(token.expiry) >= new Date()) {
    return TOKEN_DATE_VALID;
  }

  if (token.refresh_token) {
    return TOKEN_MUST_BE_REFRESHED;
  }

  return TOKEN_EXPIRED;
};
