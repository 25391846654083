export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const { $i18n, $log, $api } = useNuxtApp();
  const { getFeature } = useMainStore();

  if (getFeature('ccaSubscriptionApiEnabled')) {
    return navigateTo(getUnsubscribeRedirectCca(to));
  }

  let campaigns;

  try {
    const campaignsResponse = await $api.hybris.campaign.status(to.params.id);
    campaigns = campaignsResponse.data;
  } catch (error) {
    $log.error(error);
    return navigateTo({ path: $i18n.t('url.campaign.unsubscribe.error') });
  }

  if (!campaigns.length) {
    return navigateTo({ path: $i18n.t('url.campaign.unsubscribe.error') });
  }

  return navigateTo({
    path: $i18n.t('url.campaign.unsubscribe.index'),
    query: { cryptId: to.params.id },
  });
});
