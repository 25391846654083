import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class ConfigurationApi extends AbstractHybrisApi {
  global() {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/configuration/global',
    });
  }

  features() {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/configuration/featuretoggles',
    });
  }

  /**
   * @param lang {string|null|undefined}
   * @returns {Promise<axios.AxiosResponse>}
   */
  translations(lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/configuration/i18n',
      params: {
        lang,
      },
    });
  }
}
