import { onMounted } from 'vue';
import type { RouteLocation } from 'vue-router';

export function useScrollToHash(route: RouteLocation): void {
  onMounted(() => {
    if (route.hash) {
      try {
        const target = document.querySelector(route.hash) as HTMLElement;
        if (target) {
          window.scrollTo({ top: target.getBoundingClientRect().top + window.pageYOffset, behavior: 'smooth' });
        }
      } catch (error) {
        console.warn(`Tried to scroll to ${route.hash} but failed:`, error);
      }
    }
  });
}
