import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class WishlistApi extends AbstractHybrisApi {
  all(userId, fields = 'FULL', lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/wishlists/entries`,
      params: {
        fields,
        lang,
      },
    });
  }

  info(userId, fields = 'FULL') {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/wishlists`,
      params: {
        fields,
      },
    });
  }

  create(userId, productCode) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/wishlists/entries`,
      params: {
        code: productCode,
      },
    });
  }

  find(userId, fields = 'FULL') {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/wishlists/entries/codes`,
      params: {
        fields,
      },
    });
  }

  delete(userId, productCode) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/wishlists/entries/${productCode}`,
    });
  }
}
