import { stringify } from 'qs';

const isObject = (value: any) => typeof value === 'object' && !Array.isArray(value);

const isValueValid = (value: any) =>
  value !== null && value !== undefined && value !== '' && value !== 'undefined' && value !== 'null';

const stringifyObjectProps = (params: Record<string, any>): Record<string, any> =>
  Object.keys(params).reduce((acc, key) => {
    // Always been like this, but would be nice to know why we encode to JSON here
    const value = isObject(params[key]) ? JSON.stringify(params[key]) : params[key];

    if (isValueValid(value)) {
      acc[key] = value;
    }

    return acc;
  }, {});

export const searchParamsSerializer = (params: Record<string, any>) =>
  stringify(stringifyObjectProps(params), { arrayFormat: 'brackets' });
