<template>
  <fn-panel
    :headline="data.headline"
    :background-color="data.backgroundColor"
    :foreground-color="data.foregroundColor"
    :toggle="data.toggle"
    :toggle-visible="data.toggleVisible"
    :loading="data.loading"
  >
    <template
      v-if="data.header"
      #header
    >
      <div v-html="data.header" />
    </template>
    <template #default>
      <div v-html="data.content" />
    </template>
  </fn-panel>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
