<template>
  <fn-grid>
    <fn-grid-item
      v-for="(item, key) in cleanedList"
      :key="key"
      :static="item.static"
      :columns="item.columns && item.columns.xs ? item.columns.xs : 12"
      :columns-s="item.columns && item.columns.s ? item.columns.s : null"
      :columns-m="item.columns && item.columns.m ? item.columns.m : null"
      :columns-l="item.columns && item.columns.l ? item.columns.l : null"
      :columns-xl="item.columns && item.columns.xl ? item.columns.xl : null"
      :push="item.push && item.push.xs ? item.push.xs : null"
      :push-s="item.push && item.push.s ? item.push.s : null"
      :push-m="item.push && item.push.m ? item.push.m : null"
      :push-l="item.push && item.push.l ? item.push.l : null"
      :push-xl="item.push && item.push.xl ? item.push.xl : null"
    >
      <fn-cms-component-loader
        v-for="component in item.components"
        :key="component.type"
        :type="component.type"
        :data="component.data"
        :id-prefix="idPrefix"
        :page-metrics="pageMetrics"
        :transformation="item.transformation"
        :image-options="imageOptions"
      />
    </fn-grid-item>
  </fn-grid>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
    idPrefix: {
      type: String,
      default: '',
    },
    pageMetrics: {
      type: Object,
      default: () => ({}),
    },
    imageOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(useMainStore, {
      isAppview: 'isAppview',
      allComponentsInAppEnabled: 'getAllComponentsInAppEnabled',
    }),

    cleanedList() {
      return this.items
        .map((item: any) => ({ ...item, components: this.cleanedComponents(item.components) }))
        .filter((item: any) => item.components?.length);
    },
  },
  methods: {
    cleanedComponents(componentList: Array<any>) {
      if (!this.isAppview || this.allComponentsInAppEnabled) {
        return componentList;
      }

      const whitelabelList = componentList.filter((component) => componentIsWhitelabeled(component.type));
      return whitelabelList.filter((component) => !isSingleHeadline(component.type, whitelabelList.length));
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.grid-item--12-fixed-cart-width) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
