export const createCustomEvent = (name, bubbles = false, cancelable = false) =>
  new CustomEvent(name, {
    bubbles,
    cancelable,
  });

export const timeout = (delay = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
