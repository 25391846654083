import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartPaybackApi extends AbstractHybrisApi {
  setPaybackNumber(userId, cartId, paybackNumber, abTests = null) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/savePaybackNumber`,
      params: {
        fields: 'FULL',
        paybackNumber,
      },
      abTests,
    });
  }

  removePaybackNumber(userId, cartId, abTests = null) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/removePaybackNumber`,
      params: {
        fields: 'FULL',
      },
      abTests,
    });
  }

  addPaybackCoupon(userId, cartId, triggerCode, abTests = null) {
    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/addPaybackCoupon`,
      params: {
        fields: 'FULL',
        triggerCode,
      },
      abTests,
    });
  }

  removePaybackCoupon(userId, cartId, triggerCode, abTests = null) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/removePaybackCoupon/${triggerCode}`,
      params: {
        fields: 'FULL',
      },
      abTests,
    });
  }

  getPaybackAuthUrl(userId, cartId, host) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/getPaybackAuthorizationUrl`,
      params: {
        fields: 'FULL',
        host,
      },
    });
  }

  setPaybackAuthCode(userId, cartId, authCode, host, abTests = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/paybackAuthCode`,
      params: {
        fields: 'FULL',
        authCode,
        host,
      },
      abTests,
    });
  }

  removePaybackAuth(userId, cartId, abTests = null) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/removePaybackRedemption`,
      params: {
        fields: 'FULL',
      },
      abTests,
    });
  }

  setPaybackRedemptionPoints(userId, cartId, pointsToRedeem, abTests = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/pointsToRedeem`,
      params: {
        fields: 'FULL',
        pointsToRedeem,
      },
      abTests,
    });
  }
}
