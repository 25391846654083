import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class AdoptionApi extends AbstractHybrisApi {
  getFilter(token, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/adoption/values',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        lang,
      },
    });
  }

  /**
   * Returns particular pet details
   * */
  getPet(petID, token, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/adoption/${petID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        lang,
      },
    });
  }

  getPetList(properties = [], token, pageIndex = 0, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: '/adoption',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        lang,
        pageIndex,
      },
      data: {
        properties: [...properties],
      },
    });
  }
}
