export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to, from) => {
  const { path, query, hash } = to;

  if (path !== '/' && !path.endsWith('/')) {
    const nextRoute = { path: `${path}/`, query, hash };

    useNuxtApp().$log.debug(`Redirecting (current URL is missing trailing slash)`, { from, to: nextRoute });

    return navigateTo(nextRoute, {
      redirectCode: 301,
    });
  }
});
