import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class SheltersApi extends AbstractHybrisApi {
  all(currentPage = 0, pageSize = -1, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/shelters',
      params: {
        currentPage,
        pageSize,
        lang,
      },
    });
  }

  find(customerId, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/shelters/${customerId}`,
      params: {
        fields: 'FULL',
        lang,
      },
    });
  }

  info(shelterId, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/shelters/${shelterId}/shelter`,
      params: {
        fields: 'FULL',
        lang,
      },
    });
  }

  update(shelterId, shelterData, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/shelters/${shelterId}/shelter`,
      params: {
        fields: 'FULL',
        lang,
      },
      data: shelterData,
    });
  }
}
