import type { NuxtApp } from '#app';

declare const window: Window & {
  $nuxt: NuxtApp;
};

const setBruteforcePreventCookie = () => {
  const config = useRuntimeConfig();
  const url = unref(useRouter().currentRoute).path;
  const listedPages = config.public.blacklistPages.split(',');

  if (listedPages?.length && !listedPages.includes(url)) {
    const checkCookie = useCookie<boolean>(config.public.checkCookie, {
      sameSite: 'lax',
    });

    checkCookie.value = true;
  }
};

const restoreToken = ($log: any) => {
  const token = useCookie<any>('token').value;

  if (token) {
    $log.debug('Adding token to store.');
    useUserStore().token = token as any;
  }
};

const restoreCart = ($log: any) => {
  const cartStore = useCartStore();

  if ((cartStore.cart as any)?.type !== 'cartWsDTO') {
    cartStore.cart = useCookie<any>('cart').value;
    $log.debug('Restored cart from cookie.');
  }
};

const handleUpdateDevicePlatform = () => {
  if (import.meta.client) {
    const mainStore = useMainStore();
    window.addEventListener('resize', () => {
      // `DevicePlatform` is initially updated in the `UserAgent` router middleware. Changing the device in the browser
      // devtools does not cause an update however, so we listen to the window resize event which gets triggered by it.
      mainStore.updateDevicePlatform({ userAgent: navigator.userAgent });
    });
  }
};

const handleUpdateTrackerUtm = () => {
  if (import.meta.client) {
    const userStore = useUserStore();
    document.body.addEventListener('trackerUtm', (e: any) => {
      userStore.trackerUtm = e.detail;
    });
  }
};

const isCacheableRequest = (nuxtApp: NuxtApp) =>
  import.meta.server && nuxtApp?.ssrContext?.event?.__MULTI_CACHE_ROUTE?.cacheable;

export default defineNuxtPluginWithTiming(import.meta.url, async function init(nuxtApp) {
  const mainStore = useMainStore();
  const userStore = useUserStore();

  // Establish side effect handlers early on
  watch(
    () => userStore.token,
    (tokenValue?: Record<string, any>) => {
      if (tokenValue) {
        nuxtApp.$api.setAccessToken(tokenValue.access_token);
        userStore.setTokenCookie();
        notifyApp('onNewToken', tokenValue);
      } else {
        nuxtApp.$api.setAccessToken(null);
      }
    },
  );

  handleUpdateDevicePlatform();
  handleUpdateTrackerUtm();
  setBruteforcePreventCookie();

  // Prevent adding user data to cache by not restoring user information on uncached requests
  if (!isCacheableRequest(nuxtApp)) {
    restoreToken(nuxtApp.$log);
    restoreCart(nuxtApp.$log);
  }

  if (import.meta.server) {
    // Depends on: 35.api
    await mainStore.nuxtServerInit(nuxtApp);
  } else {
    window.$nuxt = nuxtApp;
  }
});
