import { resourceNames } from '@/utils/analytics';

export const useWishlistStore = defineStore('wishlist', {
  state: () => ({
    loading: null,
    wishlist: null,
    fullWishlist: null,
    isPublished: false,
    shelterWishlist: null,
  }),
  actions: {
    startLoading() {
      this.loading = true;
    },

    finishLoading() {
      this.loading = false;
    },

    async initializeWishlist() {
      if (useUserStore().getIsAuthenticated) {
        await this.loadWishlistItems({ fields: 'FULL' });
      }
    },

    loadWishlist(payload) {
      const { $api, $log } = useNuxtApp();
      $log.debug('Loading wishlist...');

      this.startLoading();

      const { fields = 'FULL' } = payload || {};

      const userStore = useUserStore();
      const mainStore = useMainStore();
      const userType = userStore.getType;
      const lang = mainStore.language.value;

      return new Promise((resolve, reject) => {
        if (userType === 'anonymous') {
          this.finishLoading();
          return resolve();
        }

        return $api.hybris.wishlist
          .all(userType, fields, lang)
          .then((response) => {
            $log.debug('wishlist loaded.');

            this.fullWishlist = response.data;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading wishlist.', error);

            this.$reset();

            if (error.response.status === 400 && error.response.data.errors[0].reason === 'notFound') {
              return resolve();
            }

            return reject(error);
          })
          .finally(() => this.finishLoading());
      });
    },

    loadWishlistItems(payload) {
      const { $api, $log } = useNuxtApp();
      $log.debug('Loading wishlistitems...');

      this.startLoading();

      const { fields = 'FULL' } = payload || {};

      const userStore = useUserStore();
      const userType = userStore.getType;

      return new Promise((resolve, reject) => {
        if (userType === 'anonymous') {
          this.finishLoading();
          return resolve();
        }

        return $api.hybris.wishlist
          .find(userType, fields)
          .then((response) => {
            $log.debug('wishlist loaded.');

            this.wishlist = response.data;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading wishlist.', error);

            this.$reset();

            if (error.response.status === 400 && error.response.data.errors[0].reason === 'notFound') {
              return resolve();
            }

            return reject(error);
          })
          .finally(() => this.finishLoading());
      });
    },

    loadWishlistInfo(payload) {
      const { $api, $log } = useNuxtApp();
      $log.debug('Loading wishlist info...');

      this.startLoading();

      const { fields = 'FULL' } = payload || {};

      const userStore = useUserStore();
      const userType = userStore.getType;

      return new Promise((resolve, reject) => {
        if (userType === 'anonymous') {
          this.finishLoading();
          return resolve();
        }

        return $api.hybris.wishlist
          .info(userType, fields)
          .then((response) => {
            $log.debug('wishlist info loaded.');

            this.isPublished = response.data?.isPublished;

            return resolve();
          })
          .catch((error) => {
            $log.error('Error while loading wishlist info.', error);

            this.$reset();

            if (error.response.status === 400 && error.response.data.errors[0].reason === 'notFound') {
              return resolve();
            }

            return reject(error);
          })
          .finally(() => this.finishLoading());
      });
    },

    async addWishlistItem(payload) {
      const { $api, $errorHandler, $log, $i18n } = useNuxtApp();
      $log.debug('Adding entry to wishlist...');

      this.startLoading();

      const { productCode } = payload;

      const userStore = useUserStore();
      const userType = userStore.getType;

      return new Promise((resolve, reject) => {
        if (userType === 'anonymous') {
          $log.debug('Skipping adding wishlist entry - reason: anonymous user.');
          const error = {
            response: {
              status: 401,
              ...$i18n.t('errors.401'),
            },
          };

          $errorHandler.handleErrorModal(error);

          this.finishLoading();
          return resolve();
        }

        return $api.hybris.wishlist
          .create(userType, productCode)
          .then(async (response) => {
            $log.debug('Added entry to wishlist.');

            await this.loadWishlistItems({ fields: 'FULL' });

            const datalayerStore = useDatalayerStore();
            await datalayerStore.loadAnalytics([resourceNames.WISHLIST]);

            return resolve(response);
          })
          .catch(async (error) => {
            $log.error('Error while adding entry to wishlist.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          })
          .finally(() => this.finishLoading());
      });
    },

    async removeWishlistItem(payload) {
      const { $api, $errorHandler, $log } = useNuxtApp();

      $log.debug('Removing entry from wishlist...');

      this.startLoading();
      const { productCode, loadFullWishlist } = payload;

      const userStore = useUserStore();
      const userType = userStore.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.wishlist
          .delete(userType, productCode)
          .then(async (response) => {
            $log.debug('Removed entry from wishlist.');

            if (loadFullWishlist) {
              await this.loadWishlist({ fields: 'FULL' });
            }

            await this.loadWishlistItems({ fields: 'FULL' });

            const datalayerStore = useDatalayerStore();
            await datalayerStore.loadAnalytics([resourceNames.WISHLIST]);

            return resolve(response);
          })
          .catch(async (error) => {
            $log.error('Error while removing entry from wishlist.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          })
          .finally(() => this.finishLoading()),
      );
    },

    async togglePublishWishlist(publish) {
      const { $api, $errorHandler, $log } = useNuxtApp();

      if (publish) {
        $log.debug('Publishing wishlist...');
      } else {
        $log.debug('Unpublishing wishlist...');
      }

      this.startLoading();

      const userStore = useUserStore();
      const userType = userStore.getType;

      return new Promise((resolve, reject) =>
        $api.hybris.user
          .togglePublishWishlist(userType, publish)
          .then(async (response) => {
            if (publish) {
              $log.debug('Published wishlist.');
            } else {
              $log.debug('Unpublished wishlist...');
            }

            await this.loadWishlistInfo({ fields: 'FULL' });

            return resolve(response);
          })
          .catch(async (error) => {
            $log.error('Error while publishing/unpublishing wishlist.', error);

            $errorHandler.handleErrorModal(error);

            return reject(error);
          })
          .finally(() => this.finishLoading()),
      );
    },

    async loadShelterWishlist(customerId) {
      const { $api, $log } = useNuxtApp();

      $log.debug('Loading shelter wishlist...');

      const mainStore = useMainStore();
      const lang = mainStore.language;

      this.startLoading();

      return new Promise((resolve, reject) =>
        $api.hybris.shelters
          .find(customerId, lang)
          .then(async (response) => {
            $log.debug('Loaded shelter wishlist.');

            const userStore = useUserStore();
            userStore.shelter = { name: response.data.name };

            this.shelterWishlist = response.data.wishList;

            return resolve();
          })
          .catch(async (error) => {
            $log.error('Error while getting shelter wishlist.', error);

            return reject(error);
          })
          .finally(() => this.finishLoading()),
      );
    },
  },
  getters: {
    getOnWishlist: (state) => (productCode) => {
      return state.wishlist?.entries ? state.wishlist.entries.filter((entry) => entry === productCode).length : null;
    },

    getWishlistItemsCount(state) {
      return state.wishlist.entries.length;
    },

    getDatalayer(state) {
      const items = [];
      const today = new Date();

      for (let i = 0; i < state.fullWishlist.entries.length; i += 1) {
        const entry = state.fullWishlist.entries[i];
        const addedDate = new Date(entry.addedDate);

        items.push({
          ...entry.product.analytics,
          daysOnList: Math.floor((today.getTime() - addedDate.getTime()) / (1000 * 60 * 60 * 24)),
        });
      }

      return { items };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWishlistStore, import.meta.hot));
}
