export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  const { $router } = nuxtApp;

  $router.afterEach(async () => {
    await useCartStore().handleVoucherFromQuery();

    if ($router.currentRoute.value.query.voucher) {
      await $router.replace({
        path: $router.currentRoute.value.path,
        hash: $router.currentRoute.value.hash,
      });
    }
  });
});
