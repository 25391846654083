<template>
  <div class="loading-overlay">
    <fn-loading-icon />
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.loading-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(palette(white, base), 0.75);
  z-index: 10;

  .loading-icon {
    position: sticky;
    top: calc(50% - 35px);
  }
}
</style>
