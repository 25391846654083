import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class BookingApi extends AbstractHybrisApi {
  getAvailableTimeslots(userId, params, serviceProviderType, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/bookings/${userId}/${serviceProviderType}/available/timeslots`,
      params: {
        lang,
        ...params,
      },
    });
  }

  bookReservation(userId, data, serviceProviderType, lang = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/bookings/${userId}/${serviceProviderType}/reservations`,
      params: {
        lang,
      },
      data,
    });
  }

  cancelReservation(userId, reservationId, serviceProviderType, lang = null) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/bookings/${userId}/${serviceProviderType}/reservations/${reservationId}`,
      params: {
        lang,
      },
    });
  }

  getTreatments(userId, serviceProviderType, params, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/bookings/${userId}/${serviceProviderType}/treatments`,
      params: {
        lang,
        params,
      },
    });
  }
}
