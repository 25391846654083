export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to) => {
  const { path, params } = to;

  const { $i18n } = useNuxtApp();
  const userStore = useUserStore();

  if (!userStore.user?.isStoreEditor) {
    return navigateTo({
      path: $i18n.t('url.login'),
      query: {
        redirectTo: path,
      },
    });
  }

  if (userStore.user?.isStoreEditor && path.includes('detail')) {
    const hasAccess = userStore.user?.editableStores.find((storeToFind) => storeToFind.storeNumber === params.id);

    if (!hasAccess) {
      return navigateTo($i18n.t('url.storeEdit.dashboard'));
    }
  }

  return null;
});
