import { logger } from '@/utils/logger';
import type { RouteMiddleware, NuxtApp } from '#app';

// Safely import (and re-export) node modules for SSR without causing errors during CSR
export const performance = import.meta.server ? (await import('node:perf_hooks')).performance : window.performance;

export const useWithTiming = async <T extends Function>(
  name: string | { value: () => string } | (() => string) | undefined,
  handler: T,
) => {
  if (!name || !logger.isEnabled('debug')) {
    // Just run the handler
    return handler();
  }

  // Run the handler and collect timings
  const start = performance.now();
  const returnValue = await handler();
  const end = performance.now();

  let nameValue: string;

  if (typeof name === 'function') {
    nameValue = name();
  } else if (typeof name !== 'string' && typeof name.value === 'function') {
    nameValue = name.value();
  } else {
    nameValue = name as string;
  }

  // Log timings
  logger.debug(`[${(end - start).toFixed(3)}ms]`, nameValue);

  return returnValue;
};

export const useWithTimingLogBuilder = (value: string) => {
  let valueInternal = value;

  const append = <T>(val: T) => {
    const isObjectNonEmpty = typeof val === 'object' && Object.keys(val as object).length;
    const isEmpty = val === undefined || val === null || val === '';

    if (isObjectNonEmpty || !isEmpty) {
      valueInternal += ` ${logger.inspect(val)}`;
    }
  };

  return {
    append,
    value: () => valueInternal,
  };
};

export const defineNuxtPluginWithTiming = (name: string, handler: (nuxtApp: NuxtApp) => any) =>
  defineNuxtPlugin(async (nuxtApp) => {
    return logger.isEnabled('debug')
      ? useWithTiming(name, () => nuxtApp.runWithContext(() => handler(nuxtApp as NuxtApp)))
      : nuxtApp.runWithContext(() => handler(nuxtApp as NuxtApp));
  });

export const defineNuxtRouteMiddlewareWithTiming = (name: string, handler: RouteMiddleware) =>
  defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();

    return logger.isEnabled('debug')
      ? useWithTiming(name, () => nuxtApp.runWithContext(() => handler(to, from)))
      : nuxtApp.runWithContext(() => handler(to, from));
  });
