import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class RaffleApi extends AbstractHybrisApi {
  register(userId, data) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/raffle`,
      data: data.fields,
    });
  }
}
