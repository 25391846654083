import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartDeliveryModeApi extends AbstractHybrisApi {
  all(userId, cartId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/deliverymodes`,
      params: {
        fields: 'FULL',
      },
    });
  }

  add(userId, cartId, deliveryModeId) {
    if (!cartId) {
      return Promise.resolve();
    }

    return this.request({
      method: 'put',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/deliverymode`,
      params: {
        deliveryModeId,
      },
    });
  }

  get(userId, cartId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/deliverymode`,
      params: {
        fields: 'FULL',
      },
    });
  }

  delete(userId, cartId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/deliverymode`,
    });
  }
}
