export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to, from) => {
  if (!useUserStore().getIsAuthenticated) {
    const { t } = useNuxtApp().$i18n;

    if (from.path === t('url.login')) {
      useMainStore().isLoading = false;
    }

    return navigateTo({
      path: t('url.login'),
      query: {
        redirectTo: getRouteRedirectTo(to),
      },
    });
  }

  return null;
});
