const getNormalizedFields = (slide) => {
  const newSlide = { ...slide };

  // Flatten structure for potentially cloned ACF field
  if (typeof newSlide.add_personalization === 'object' && 'add_personalization' in newSlide.add_personalization) {
    newSlide.add_personalization = newSlide.add_personalization.add_personalization;
  }

  // Flatten structure for potentially cloned ACF field
  if (typeof newSlide.segment_id === 'object' && 'segment_id' in newSlide.segment_id) {
    newSlide.segment_id = newSlide.segment_id.segment_id;
  }

  return newSlide;
};

export const getCmsPersonalizedContent = (segments, slides) => {
  if (!Array.isArray(segments) || !slides?.length) {
    return [];
  }

  return slides
    .map((slide) => getNormalizedFields(slide))
    .filter((slide) => {
      // Order of precedence when multiple personalization options are selected:
      // onlyIfNoSegmentId > onlyForSegmentId > hideForSegmentId
      const hideForSegmentId = slide.add_personalization?.includes('hide_for_segment_id');
      const onlyForSegmentId = slide.add_personalization?.includes('only_for_segment_id');
      const onlyIfNoSegmentId = slide.add_personalization?.includes('only_if_no_segment_id');

      // Show only if segment ID matches
      if (onlyForSegmentId && !onlyIfNoSegmentId) {
        return segments?.includes(slide.segment_id);
      }

      // Show only if no segments
      if (!onlyForSegmentId && onlyIfNoSegmentId) {
        return !segments.length;
      }

      // Hide if segment ID matches
      if (hideForSegmentId && segments?.includes(slide.segment_id)) {
        return false;
      }

      // Bail early when possible
      if (!onlyForSegmentId && !onlyIfNoSegmentId) {
        return true;
      }

      // Both are checked: Show only if no segments OR if ID matches
      return !segments.length || segments.includes(slide.segment_id);
    });
};
