import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class PickupStationApi extends AbstractHybrisApi {
  find(userId, city, postalCode, streetName, houseNumber) {
    return this.request({
      method: 'get',
      url: `/users/${userId}/pickUpStation/`,
      params: {
        city,
        postalCode,
        streetName,
        houseNumber,
      },
    });
  }
}
