import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CampaignApi extends AbstractHybrisApi {
  status(cryptId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/campaigns/${cryptId}`,
    });
  }

  unsubscribe(cryptId) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/campaigns/${cryptId}`,
    });
  }
}
