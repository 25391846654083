import type { Options } from '@sentry/types';

export const resolveSentryConfigShared = (): Options => {
  const sentryConfig = useRuntimeConfig().public.sentry;

  return {
    dsn: sentryConfig.dsn,
    environment: sentryConfig.environment,

    /* @TODO: Find a way to tag the release here */
    // release: `storefront@4.17.0`,

    // Error sample rate
    sampleRate: sentryConfig.sampleRate ?? 1.0,

    // Trace sample rate
    tracesSampleRate: sentryConfig.tracesSampleRate ?? 0.05,

    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-dev.*\.os\.fressnapf\.com/,
      /^https:\/\/api-stage.*\.os\.fressnapf\.com/,
      /^https:\/\/api\.os\.fressnapf\.com/,
      /^\//,
      /rest\/v2/,
      /rest\/latest/,
    ],
  };
};
