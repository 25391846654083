import type { LocationQuery } from 'vue-router';
import type { Ref } from 'vue';

function getQueryValueCaseInsensitive(query: LocationQuery, key: string) {
  // Normalize the key to lower case for comparison
  const lowerCaseKey = key.toLowerCase();

  // Find the first matching key in the query object, regardless of its case
  const foundKey = Object.keys(query).find((queryKey) => queryKey.toLowerCase() === lowerCaseKey);

  return query[foundKey ?? '']?.toString() ?? '';
}

export const useIsAppView = (flagName: 'appview' | 'noAdobe' = 'appview'): Ref<boolean> => {
  const route = useRoute();
  const cookieAppview = useCookie<boolean>(flagName);
  const queryAppview = getQueryValueCaseInsensitive(route.query, `is${flagName}`);

  // Allow to explicitly disable via query param
  if (queryAppview === '0') {
    cookieAppview.value = false;
  } else {
    cookieAppview.value = !!(queryAppview === '1' || !!cookieAppview.value);
  }

  return cookieAppview;
};
