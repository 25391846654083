/**
 * Disassembles a solr string and creates a solr object from it
 *
 * @param string {String}
 * @returns {{freeTextSearch: string, sorting: string, filters: []}}
 */
export const createSolrObjectFromSolrString = (string) => {
  const queryObject = {
    freeTextSearch: '',
    sorting: '',
    filters: [],
  };

  if (!string) return queryObject;

  // Ensure this is decoded, so we can properly detect the delimiter
  const array = decodeURIComponent(string).split(':');

  queryObject.freeTextSearch = array[0];
  queryObject.sorting = array[1] || '';

  if (queryObject.freeTextSearch) {
    array.splice(0, 2);
  }

  while (array.length > 0) {
    const [key, value] = array.splice(0, 2);
    if (key !== '' && value === '') {
      queryObject.freeTextSearch = key;
    }

    if (key !== '' && value !== '') {
      queryObject.filters.push({ key, value });
    }
  }

  return queryObject;
};

/**
 * Creates a solr query string from a solr object
 *
 * @param filters {Array}
 * @param freeTextSearch {String}
 * @param excludedFilters {Array}
 * @returns {string}
 */
export const createSolrStringFromSolrObject = (filters, freeTextSearch = '', excludedFilters = []) => {
  let query = '';

  query += `${freeTextSearch || ''}:`;
  query += ':';

  if (filters && filters.length > 0) {
    for (let i = 0; i < filters.length; i += 1) {
      const filter = filters[i];
      query += `${filter.key}:${filter.value}:`;
    }
  }

  if (excludedFilters && excludedFilters.length > 0) {
    for (let i = 0; i < excludedFilters.length; i += 1) {
      const excludedFilter = excludedFilters[i];
      query += `-${excludedFilter.key}:${excludedFilter.value}:`;
    }
  }

  if ((filters && filters.length > 0) || (excludedFilters && excludedFilters.length > 0)) {
    query = query.slice(0, -1);
  }

  return query;
};

/**
 * Merges a solr with a query object
 *
 * @param solrObject {Object}
 * @param query {Object}
 * @param predefinedTextSearch {String} - Optional text search when 'query' is not available. Ignored if query exists
 * @returns {{freeTextSearch: string, sorting: string, filters: [], currentPage: number}}
 */
export const mergeSolrAndQueryObject = (solrObject, query, predefinedTextSearch = null) => {
  // Note: This is about pagination, not to confuse with `router.currentPath`
  let currentPage = null;

  if (query?.sort) {
    solrObject.sorting = query.sort;
  }

  if (query?.text) {
    solrObject.freeTextSearch = query.text;
  } else if (predefinedTextSearch) {
    solrObject.freeTextSearch = predefinedTextSearch;
  }

  if (query?.currentPage) {
    currentPage = query.currentPage - 1;
  }

  return {
    ...solrObject,
    currentPage,
  };
};

/**
 * Adds filters to a filter array
 *
 * @param filters {Array}
 * @param filtersToAdd {Array}
 * @returns {Array}
 */
export const addFilters = (filters = [], filtersToAdd = []) => {
  for (let i = 0; i < filtersToAdd.length; i += 1) {
    const filterToAdd = filtersToAdd[i];
    const index = filters.findIndex((filter) => filter.key === filterToAdd.key && filter.value === filterToAdd.value);

    if (index === -1) {
      filters.push(filterToAdd);
    }
  }

  return filters;
};

/**
 * Removes filters from a filter array
 *
 * @param filters {Array}
 * @param filtersToRemove {Array}
 * @returns {Array}
 */
export const removeFilters = (filters = [], filtersToRemove = []) => {
  for (let i = 0; i < filtersToRemove.length; i += 1) {
    const filterToRemove = filtersToRemove[i];
    const index = filters.findIndex(
      (filter) => filter.key === filterToRemove.key && filter.value === filterToRemove.value,
    );

    if (index !== -1) {
      filters.splice(index, 1);
    }
  }
  return filters;
};

/**
 * Searches and returns freeTextSearch value from 'free-text' facet.
 * 'free-text' is set in Wordpress. Enables the author to define a landing page
 * with predefined facets and freeTextSearch w/o the visitor to see it
 *
 * @param defaultFilters {Array}
 * @returns {{predefinedTextSearch: string, isCustomFreeSearch: boolean}}
 */
export const prepareTextSearchQuery = (defaultFilters) => {
  const customFreeTextItem = defaultFilters ? defaultFilters.find((filter) => filter.key === 'free-text') : false;
  const isPredefinedTextSearch = !!customFreeTextItem;
  const predefinedTextSearch = isPredefinedTextSearch ? customFreeTextItem.value : '';

  return {
    isPredefinedTextSearch,
    predefinedTextSearch,
  };
};

/**
 * Returns the arguments for sending a request to the product search
 *
 * @param query {Object}
 * @param defaultFilters {Array}
 * @param defaultSorting {String}
 * @param defaultElevatedIds {Array}
 * @param defaultSearchIds
 * @param facetToExclude
 * @param orderOfSortFacet
 * @param excludedFilters
 * @returns {{solrQuery: string, sorting: string, currentPage: null|Number, elevateIds: String}}
 */

export const getProductSearchArguments = (
  query,
  defaultFilters,
  defaultSorting,
  defaultElevatedIds,
  defaultSearchIds,
  facetToExclude,
  orderOfSortFacet,
  excludedFilters,
) => {
  const { isPredefinedTextSearch, predefinedTextSearch } = prepareTextSearchQuery(defaultFilters);

  const solrObject = createSolrObjectFromSolrString(query.q);

  // eslint-disable-next-line prefer-const
  let { freeTextSearch, sorting, filters, currentPage } = mergeSolrAndQueryObject(
    solrObject,
    query,
    predefinedTextSearch,
  );

  // Always add default filters
  if (defaultFilters) {
    filters = addFilters(filters, defaultFilters);
  }

  // Remove 'free-text' facet from filters
  if (isPredefinedTextSearch) {
    filters = filters.filter((filter) => filter.key !== 'free-text');
  }

  // Use default sorting if no sorting is set
  if (defaultSorting && !sorting) {
    sorting = defaultSorting;
  }

  const productIds = [];
  if (defaultElevatedIds && defaultElevatedIds.length > 0) {
    for (let i = 0; i < defaultElevatedIds.length; i += 1) {
      productIds.push(defaultElevatedIds[i].product_id);
    }
  }
  const elevatedIds = productIds.toString();

  const filteredIds = [];
  if (defaultSearchIds && defaultSearchIds.length > 0) {
    for (let i = 0; i < defaultSearchIds.length; i += 1) {
      filteredIds.push(defaultSearchIds[i].product_id);
    }
  }
  const searchIds = filteredIds.toString();

  /** Iterate through excluded Facets and join with semicolon */
  const excludedFacets = facetToExclude ? facetToExclude.map((facet) => facet.facet_name).join(',') : null;

  /** Iterate through facetSortOrder Array and join with semicolon */
  const facetSortOrder = orderOfSortFacet ? orderOfSortFacet.map((facet) => facet.facet_name).join(',') : null;

  const solrQuery = createSolrStringFromSolrObject(filters, freeTextSearch, excludedFilters);

  return {
    solrQuery,
    sorting,
    currentPage,
    elevatedIds,
    searchIds,
    excludedFacets,
    facetSortOrder,
    isPredefinedTextSearch,
  };
};

/**
 * Returns filter arrays for selected and deleted filters from a facet
 *
 * @param facetOrFacets {[]}
 * @returns {{deletedFilters: [], selectedFilters: []}}
 */
export const getFiltersFromFacet = (facetOrFacets) => {
  const selectedFilters = [];
  const deletedFilters = [];

  // Support single or multiple facets at once
  const facets = Array.isArray(facetOrFacets) ? facetOrFacets : [facetOrFacets];

  for (let i = 0; i < facets.length; i += 1) {
    for (let j = 0; j < facets[i].values.length; j += 1) {
      const facetItem = facets[i].values[j];
      const value = {
        key: facets[i].code,
        value: facetItem.code,
      };

      if (facetItem.selected) {
        selectedFilters.push(value);
      } else {
        deletedFilters.push(value);
      }

      const subFacets = facetItem.values;

      if (subFacets?.length) {
        for (let k = 0; k < subFacets.length; k += 1) {
          const subValue = {
            key: facets[i].code,
            value: subFacets[k].code,
            parentFacet: facetItem.code,
          };

          if (subFacets[k].selected) {
            selectedFilters.push(subValue);
          } else {
            deletedFilters.push(subValue);
          }
        }
      }
    }
  }

  return {
    selectedFilters,
    deletedFilters,
  };
};

/**
 * Returns the solr query string for the q parameter after saving one or multiple facets
 *
 * @param query {Object}
 * @param defaultFilters {Array}
 * @param facetOrFacets {Array}
 * @returns {String}
 */
export const getFilteredSolrString = (query, defaultFilters, facetOrFacets) => {
  let solrObject = {};

  if (query) {
    solrObject = createSolrObjectFromSolrString(query.q);
  }

  // eslint-disable-next-line prefer-const
  let { filters, freeTextSearch } = mergeSolrAndQueryObject(solrObject, query);

  // Merge selected facet filters with current filters
  const { selectedFilters, deletedFilters } = getFiltersFromFacet(facetOrFacets);

  filters = addFilters(filters, selectedFilters);
  filters = removeFilters(filters, deletedFilters);
  filters = removeFilters(filters, defaultFilters);

  return createSolrStringFromSolrObject(filters, freeTextSearch);
};
