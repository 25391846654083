import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class ForgottenPasswordApi extends AbstractHybrisApi {
  token(userId, lang = null, redirect = '/my-account/', captcha = null) {
    return this.request({
      method: 'post',
      version: 'v3',
      url: '/forgottenpasswordtokens',
      params: {
        userId,
        lang,
        redirect,
        captcha,
      },
    });
  }

  reset(newPassword, token) {
    return this.request(
      {
        method: 'post',
        version: 'v2',
        url: '/resetpassword',
        data: {
          newPassword,
          token,
        },
      },
      false,
    );
  }
}
