<template>
  <svg
    class="curve"
    :viewBox="viewBox"
  >
    <path
      d="M 0 0 L 0 -32 Q 85 -1 206 -1 C 282 0 399 -45 500 -45 L 500 0 Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
    viewBox: {
      type: String,
      default: '0 -45 500 45',
    },
  },
};
</script>
