const phonePrefix = {
  DE: '+49',
  CH: '+41',
  AT: '+43',
  PL: '+48',
  FR: '+33',
  BE: '+32',
  IE: '+353',
  LU: '+352',
  DK: '+45',
};

const shelterAddressForm = (isocode: keyof typeof phonePrefix = 'DE') => ({
  groups: [
    {
      fields: [
        {
          code: 'shelterName',
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'requiredNotice',
          size: 'l',
        },
      ],
    },
    {
      fields: [
        {
          code: 'firstName',
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'lastName',
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'email',
          rules: {
            required: true,
            email: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          size: 's',
          code: 'postcode',
          rules: {
            required: true,
          },
        },
        {
          size: 'l',
          code: 'city',
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          size: 'l',
          code: 'street',
          rules: {
            required: true,
          },
        },
        {
          size: 's',
          code: 'houseNumber',
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'country',
          rules: {
            required: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'birthday',
          rules: {
            required: false,
            birthday_plausibility: true,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'addressAddition',
          rules: {
            required: false,
            max: 30,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'phone',
          prefix: phonePrefix[isocode],
          rules: {
            required: false,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'associationRegisterNumber',
          rules: {
            required: false,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'legalForm',
          rules: {
            required: false,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'description',
          rules: {
            required: false,
            max: 300,
          },
        },
      ],
    },
    {
      fields: [
        {
          code: 'media',
          rules: {
            required: false,
          },
        },
      ],
    },
  ],
});

const fressnapfAT = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.AT,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
  shelterAddressForm: shelterAddressForm('AT'),
};

const fressnapfCH = {
  defaultCountry: 'CH',
  multilingual: true,
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.CH,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
  shelterAddressForm: shelterAddressForm('CH'),
};

const fressnapfDE = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
              max: 30,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.DE,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
  shelterAddressForm: shelterAddressForm('DE'),
};

const fressnapfLU = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
              max: 30,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.LU,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
};

const maxizooBE = {
  defaultCountry: 'BE',
  multilingual: true,
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.BE,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
  shelterAddressForm: shelterAddressForm('BE'),
};

const maxizooDK = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.DK,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
};

const maxizooFR = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.FR,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
};

const maxizooIE = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: phonePrefix.IE,
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
  shelterAddressForm: shelterAddressForm('IE'),
};

const maxizooIT = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            rules: {
              required: false,
            },
          },
        ],
      },
    ],
  },
};

const maxizooPL = {
  addressForm: {
    groups: [
      {
        fields: [
          {
            code: 'salutation',
            size: 's',
            rules: {
              required: false,
            },
          },
          {
            code: 'requiredNotice',
            size: 'l',
          },
        ],
      },
      {
        fields: [
          {
            code: 'firstName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'lastName',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'email',
            rules: {
              required: true,
              email: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'birthday',
            rules: {
              required: false,
              birthday_plausibility: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 's',
            code: 'postcode',
            rules: {
              required: true,
            },
          },
          {
            size: 'l',
            code: 'city',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            size: 'l',
            code: 'street',
            rules: {
              required: true,
            },
          },
          {
            size: 's',
            code: 'houseNumber',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'country',
            rules: {
              required: true,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'addressAddition',
            rules: {
              required: false,
            },
          },
        ],
      },
      {
        fields: [
          {
            code: 'phone',
            prefix: '+48',
            rules: {
              required: false,
              min: 9,
              max: 9,
              leading_zero: true,
            },
          },
        ],
      },
    ],
  },
};

export type BaseStoreConfigAddressForm = {
  groups: Array<{
    fields: Array<{
      code: string;
      size?: 's' | 'm' | 'l'; // size is optional, only some fields have it
      prefix?: string; // prefix is only used for the phone field
      rules?: {
        required: boolean;
        email?: boolean; // email validation exists only on specific fields
        birthday_plausibility?: boolean; // birthday plausibility exists only on specific fields
      };
    }>;
  }>;
};

export type BaseStoreConfig = {
  defaultCountry?: string;
  multilingual?: boolean;
  addressForm: BaseStoreConfigAddressForm;
  shelterAddressForm: ReturnType<typeof shelterAddressForm>;
};

export const baseStoreConfig = {
  fressnapfAT,
  fressnapfCH,
  fressnapfDE,
  fressnapfLU,
  maxizooBE,
  maxizooDK,
  maxizooFR,
  maxizooIE,
  // maxizooIT,
  maxizooPL,
};

export type BaseStoreKeys = keyof typeof baseStoreConfig;

export const getBaseStoreConfig = (baseStore: BaseStoreKeys): BaseStoreConfig =>
  baseStoreConfig[baseStore] as BaseStoreConfig;
