<template>
  <fn-picture
    :src="imageUrl"
    :alt="data.image.alt"
    :title="data.image.title"
    :copyright="data.image.copyright"
    :lazy="!hasOption('eager')"
    :fetchpriority="hasOption('priority') ? 'high' : 'auto'"
    :width="data.image.width"
    :height="data.image.height"
    :image-sprite="hasOption('use_image_sprite')"
    :round-display="hasOption('round_display')"
    :rounded-corners="hasOption('rounded_corners')"
    :transformation="transformation"
  />
</template>

<script setup lang="ts">
import { getImageUrl } from '@/utils/images';

const props = withDefaults(
  defineProps<{
    data: Record<string, any>;
    transformation?: string;
  }>(),
  {
    transformation: '',
  },
);

const imageUrl = computed(() => getImageUrl(props.data.image));

function hasOption(optionName: string) {
  return props.data.image_options?.includes(optionName);
}
</script>
