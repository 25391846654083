export const LOCAL_STORAGE_ADYEN_ACTION = 'adyenAction';
export const LOCAL_STORAGE_ADYEN_STATE = 'adyenState';
export const LOCAL_STORAGE_ADYEN_PAYLOAD = 'adyenPayload';
export const LOCAL_STORAGE_DELIVERY_OPTION = 'deliveryOption';
export const LOCAL_STORAGE_PICKUP_DELIVERY_ADDRESS = 'pickupDeliveryAddress';

export function getFromLocalStorage(storageKey) {
  if (import.meta.server) {
    return null;
  }

  const storedData = localStorage.getItem(storageKey);

  if (!storedData) {
    return null;
  }

  try {
    return JSON.parse(storedData);
  } catch (error) {
    return null;
  }
}

export function saveToLocalStorage(storageKey, data) {
  if (import.meta.server) {
    return;
  }

  const storedData = getFromLocalStorage(storageKey);

  localStorage.setItem(storageKey, JSON.stringify({ ...storedData, ...data }));
}

export function deleteFromLocalStorage(storageKey) {
  if (import.meta.server) {
    return;
  }

  localStorage.removeItem(storageKey);
}
