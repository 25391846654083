export const useCmsErrorPage = async (code = 404) => {
  const { $i18n } = useNuxtApp();
  const mainStore = useMainStore();

  if (!mainStore.errorPage) {
    await mainStore.loadCmsErrorPage();
  }

  // NOTE: Stack traces are not forwarded to the client for 404 errors or production builds
  // @see https://github.com/nuxt/nuxt/discussions/10578#discussioncomment-4887261
  throw createError({
    statusCode: code,
    message: `${$i18n.t('pages.error.message')} ${code}`,
    fatal: true,
  });
};
