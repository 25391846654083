import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class SeoContentApi extends AbstractHybrisApi {
  find(fullUrl, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/seocontent/filterpage',
      params: {
        fullUrl,
        lang,
      },
    });
  }

  getRelatedLinks(requestingLevel1Url, urlPart, lang = null) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/seocontent/relatedfilterpages',
      params: {
        urlPart,
        lang,
        requestingLevel1Url,
      },
    });
  }
}
