import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class CartEntryApi extends AbstractHybrisApi {
  all(userId, cartId) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/entries`,
    });
  }

  create(userId, cartId, productCode, quantity, lang = null, shelterCode = null) {
    return this.request({
      method: 'post',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/entries`,
      data: {
        product: {
          code: productCode,
        },
        quantity,
      },
      params: {
        lang,
        shelterCode,
      },
    });
  }

  find(userId, cartId, entryNumber) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
    });
  }

  update(userId, cartId, entryNumber, quantity, lang) {
    return this.request({
      method: 'patch',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
      data: {
        quantity,
      },
      params: {
        lang,
      },
    });
  }

  delete(userId, cartId, entryNumber) {
    return this.request({
      method: 'delete',
      version: 'v2',
      url: `/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
    });
  }
}
