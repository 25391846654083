/**
 * Middleware to prevent infinite redirection loops for some newsletter pages.
 *
 * Case 1: Unsubscribed User
 * - When an unsubscribed user clicks the newsletter link in /my-account/ or the menu flyout,
 *   he is redirected from /my-account/newsletter/ to /newsletter/. This occurs because we first load
 *   /my-account/newsletter/, but since the user hasn't subscribed, a redirection is necessary.
 *   This creates a loop if he try to navigate back to /my-account/ because the same redirection occurs
 *
 * Case 2: Subscribed User Preferences
 * - A similar issue arises when a subscribed user navigates from
 *   /subscription/newsletter/preferences/ to /newsletter/edit-preferences/
 *   when user has already subscribed to newsletter.
 *
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const { $i18n } = useNuxtApp();

  if (
    (from?.path === $i18n.t('url.newsletter') && to?.path === $i18n.t('url.account.newsletter')) ||
    (from?.path === $i18n.t('url.newsletterPublicPreferences') && to?.path === $i18n.t('url.newsletterPreferences'))
  ) {
    return navigateTo($i18n.t('url.account.index'), { replace: true });
  }
});
