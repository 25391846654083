<template>
  <div class="page-cart">
    <fn-section
      constrained="l"
      padding-top="s"
      padding-bottom="s"
      background-color="sand-light"
    >
      <fn-grid>
        <fn-grid-item columns="12">
          <p>{{ $t('cartPaybackMessages.errorMsg') }}</p>
          <fn-button
            secondary
            @click="onClick"
          >
            {{ $t('cartPaybackMessages.buttons.continue') }}
          </fn-button>
        </fn-grid-item>
      </fn-grid>
    </fn-section>
  </div>
</template>

<script>
export default defineComponent({
  methods: {
    onClick() {
      if (window.opener && !window.opener.closed) {
        window.opener.location.replace(this.$t('url.cart'));
        window.close();
      } else {
        window.document.location.replace(this.$t('url.cart'));
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
