import type { LocaleObject } from 'vue-i18n-routing';

export const useReviewScript = () => {
  const config = useRuntimeConfig();
  const { $i18n } = useNuxtApp();
  const { language } = useMainStore();

  const isoLang = ([...$i18n.locales.value] as LocaleObject[]).find(
    (locale: any) => locale.code === language,
  );

  if (!isoLang?.language) {
    return undefined;
  }

  return config.public.bazaarvoiceScript?.replace('{locale}', isoLang.language.replace('-', '_'));
};
