import { configure, defineRule, normalizeRules } from 'vee-validate';
import { type FieldValidationMetaInfo } from '@vee-validate/i18n';
import * as AllRules from '@vee-validate/rules';
import { splitDate, ageCheck } from '@/utils/date';
import type { NuxtApp } from '#app';

type MaybeArray<T> = T | T[];

type ValidationRuleValueGeneric = boolean | string | number | RegExp;

// Validation rule simple syntax
type ValidationRuleValue<T = ValidationRuleValueGeneric> = MaybeArray<T>;

// Validation rule extended object syntax
type ValidationRuleObject<T = ValidationRuleValueGeneric> = {
  value?: ValidationRuleValue<T>;
  message?: string;
  skipTranslate?: boolean | string;
  detailedErrors?: boolean;
};

// Validation rule combined universal syntax
type ValidationRule<T = ValidationRuleValueGeneric> = ValidationRuleValue<T> | ValidationRuleObject<T>;

// Includes references to the form, field, rule and more
type ValidationMessageMetaInfo = FieldValidationMetaInfo & {
  rule: {
    name: string;
    params?: ValidationRule;
  };
};

/**
 * Normalize rule parameters to always return an array
 */
const normalizeParams = <T>(params: ValidationRule<T>): Array<T> => {
  if (params === true) {
    return [];
  }

  if (Array.isArray(params)) {
    return params;
  }

  if (typeof params === 'object') {
    return params as Array<T>;
  }

  return [params];
};

/**
 * Returns strongly typed and normalized rule parameter array for retrieval in custom rules
 */
const getRuleParams = <T = ValidationRuleValueGeneric>(params: ValidationRule<T>, key = 'value'): Array<T> => {
  if (typeof params === 'object' && key in (params as any)) {
    return normalizeParams((params as ValidationRuleObject<T>)?.[key as keyof ValidationRuleObject<T>]) as Array<T>;
  }

  return normalizeParams(params);
};

/**
 * Returns strongly typed and normalized rule parameter value for retrieval in custom rules
 */
const getRuleParam = <T = ValidationRuleValueGeneric>(params: ValidationRule<T>, key?: string): T => {
  return getRuleParams(params, key)[0];
};

/**
 * Substitute placeholders in a string, for use in fallback cases
 */
const substitutePlaceholders = (message: string, messageParams: Record<string, string>) => {
  return message.replace(/{([^}]+)}/g, (match, key) => {
    return key in messageParams ? messageParams[key] : match;
  });
};

const getMessageParams = (context: ValidationMessageMetaInfo): Record<string, string> => {
  if (!context.rule?.name) {
    return {};
  }

  const ruleNormalized = normalizeRules(context.rule);

  if (!Array.isArray(ruleNormalized.params)) {
    return ruleNormalized.params as Record<string, string>;
  }

  // Backwards compatibility: Correct validation message placeholder values with updated validation rules package
  // Rules using single value or array syntax no longer provide the parameter name, but since we only support a single
  // placeholder per validation message we can set all 5 placeholders that are in use and have it working all the time.
  // Alternative solutions require brittle custom logic or rewriting rules with ZOD/YUP, so imo the lesser evil atm.
  return {
    min: ruleNormalized.params[0] as string,
    max: ruleNormalized.params[0] as string,
    length: ruleNormalized.params[0] as string,
    number: ruleNormalized.params[0] as string,
    value: ruleNormalized.params[0] as string,
  };
};

const getValidationMessage = (context: ValidationMessageMetaInfo, $i18n: NuxtApp['$i18n']) => {
  const messageParams = getMessageParams(context);
  if ((context?.rule?.params as ValidationRuleObject)?.detailedErrors) {
    return {
      name: context.rule.name,
      message: messageParams.message,
    };
  }

  // Skip translating and return rule name for further handling
  if ((context?.rule?.params as ValidationRuleObject)?.skipTranslate) {
    return (
      messageParams?.message || {
        name: context.rule.name,
        messageParams,
      }
    );
  }

  const customMessage = (context?.rule?.params as ValidationRuleObject)?.message;

  // Bail early and use default translation
  if (!customMessage) {
    return $i18n.t(`validation.${context?.rule?.name}`, messageParams);
  }

  // Try translating custom message
  if ($i18n.te(customMessage)) {
    return $i18n.t(customMessage, messageParams);
  }

  // Try substituting placeholders
  return substitutePlaceholders(customMessage, messageParams);
};

// const getSingleParam = <T = any>(params: Array<T> | Record<string, T>, paramName: string) => {
//   return Array.isArray(params) ? params[0] : params[paramName];
// };

const isEmptyDate = (value: string) => {
  const date = splitDate(value);
  return !date || (date.year === 0 && date.month === 0 && date.day === 0);
};

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  const { $i18n } = <NuxtApp>nuxtApp;

  configure({
    // Note: Expects `string`, but we can handle a custom object the untranslated message and params when needed
    generateMessage: (context) => getValidationMessage(context as ValidationMessageMetaInfo, $i18n) as string,
  });

  /*
   * Custom messages are now added directly on the rule definition using the extended object syntax
   * @see https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules
   */

  // Custom messages are now added directly on the rule definition using the extended object syntax
  // @see https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules
  defineRule('confirmed', AllRules.confirmed);
  defineRule('digits', AllRules.digits);
  defineRule('image', AllRules.image);
  defineRule('length', AllRules.length);
  defineRule('max', AllRules.max);
  defineRule('max_value', AllRules.max_value);
  defineRule('min', AllRules.min);
  defineRule('min_value', AllRules.min_value);
  defineRule('numeric', AllRules.numeric);
  defineRule('regex', AllRules.regex);
  defineRule('size', AllRules.size);

  // Custom rules
  defineRule('min_1_number', (value: string) => /\d+/.test(value));
  defineRule('min_1_large_alpha', (value: string) => /[A-Z]+/.test(value));
  defineRule('min_1_small_alpha', (value: string) => /[a-z]+/.test(value));
  defineRule('min_1_special_char', (value: string) => /[^A-Za-z0-9]+/.test(value));

  defineRule('required', (value: any, params: any) => {
    const allowFalse = getRuleParam(params, 'allowFalse');

    if (!allowFalse && value === false) {
      return false;
    }

    return AllRules.required(value);
  });

  defineRule('ext', (files: MaybeArray<File>, params: Record<string, any>) => {
    const exts = getRuleParams(params, 'value');
    if (!Array.isArray(files) || !files.length) {
      return true;
    }

    if (!exts?.length) {
      return true;
    }

    const regex = new RegExp(`\\.(${exts.join('|')})$`, 'i');
    if (Array.isArray(files)) {
      return files.every((file) => regex.test(file.name));
    }

    return regex.test((files as File).name);
  });

  defineRule('date_plausibility', (value: string) => {
    if (isEmptyDate(value)) return true;
    const hundredYearsAgo = Date.now() - 60 * 60 * 24 * 365 * 100 * 1000;
    const birthday = new Date(value).getTime();

    // Check if date is in last hundred years
    return birthday >= hundredYearsAgo;
  });

  defineRule('date_in_future', (value: string) => {
    if (isEmptyDate(value)) return true;

    const birthday = new Date(value).getTime();
    return birthday <= Date.now();
  });

  defineRule('birthday_complete', (value: string) => {
    if (isEmptyDate(value)) return true;
    const date = splitDate(value);
    return !!date && !!date.year && !!date.month && !!date.day;
  });

  defineRule('birthday_day', (value: string) => {
    if (isEmptyDate(value)) return true;
    const date = splitDate(value);

    // We don't always have a valid month or year,
    // so we need to fallback to a default of 31
    const daysInMonth = date?.year && date?.month ? new Date(date?.year, date?.month, 0).getDate() : 31;

    const day = Number(date?.day);
    return day <= daysInMonth && day > 0;
  });

  defineRule('birthday_month', (value: string) => {
    if (isEmptyDate(value)) return true;
    const date = splitDate(value);

    const month = Number(date?.month);

    return month <= 12 && month > 0;
  });

  defineRule('birthday_min_age', (value: string, [age]: [number]) => {
    return ageCheck(value, age);
  });

  defineRule('birthday_plausibility', (value: string) => {
    if (isEmptyDate(value)) return true;
    const date = splitDate(value);

    if (date === null) return true;

    // Build the date manually to prevent Date()
    // misinterpreting non-4-digit years as days
    const birthdayDate = new Date();
    birthdayDate.setUTCFullYear(date.year);
    birthdayDate.setUTCMonth(date.month - 1);
    birthdayDate.setUTCDate(date.day);

    const birthdayTimestamp = birthdayDate.getTime();
    const hundredYearsAgo = Date.now() - 60 * 60 * 24 * 365 * 100 * 1000;

    // Check if date is in last hundred years
    return birthdayTimestamp >= hundredYearsAgo;
  });

  defineRule('date_complete', (value: string) => {
    if (isEmptyDate(value)) return true;
    const date = splitDate(value);

    return !!date && !!date.year && !!date.month && !!date.day;
  });

  defineRule('email', (value: string) => {
    if (!value || !value.length) {
      return false;
    }

    // eslint-disable-next-line no-control-regex, no-useless-escape, max-len
    return /^(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21-\x5A\x53-\x7F]|\\[\x01-\x09\x0B\x0C\x0E-\x7F])+)])$/.test(
      value,
    );
  });

  defineRule('postcode', (value: string, [regex]: [string]) => {
    if (!regex) {
      return true;
    }

    return new RegExp(regex).test(value);
  });

  defineRule('max_num_files', (files: Array<File>, params: Record<string, any>) => {
    const max = getRuleParam(params, 'max');
    if (!max) {
      return true;
    }

    return files.length <= max;
  });

  defineRule('no_double_filenames', (files: Array<File>) => {
    if (!files?.length) return true;
    const filenames = files.map((file) => file.name);
    const uniqueFilenames = new Set(filenames);
    return uniqueFilenames.size === filenames.length;
  });

  defineRule('leading_zero', (value: string) => !value?.startsWith('0'));

  /**
   * We need to explicitly override the 'mimes' validation rule to ensure that the file validation
   * is based only on the file extension using the custom 'ext' rule.
   * Therefore, we will always return 'true' as a result of the 'mimes' validation to let the 'ext' rule fire.
   *
   * We can't remove the 'mimes' rule - it defines file types for the file selection dialog in the (OS).
   */
  defineRule('mimes', () => true);

  defineRule('payback_coupon', (value: string) => {
    const regex = /^\d\d-\d\d-\d\d$/;
    return regex.test(value);
  });
});
