<template>
  <div
    class="grid-container"
    :class="{
      'grid-container--overflown': overflow,
      'grid-container--subgrid': subgrid,
    }"
  >
    <div class="grid">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    overflow: {
      type: Boolean,
    },
    subgrid: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  @include grid-row();

  // Select only nested .grid inside .account-layout
  .account-layout & .grid {
    margin-bottom: 0;
  }
}
.section {
  .grid-container.grid-container--overflown {
    overflow: visible;
    padding: 0 20px;
    @include bp(m) {
      margin: 0 -20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .button-group.reversible .grid {
    flex-flow: column-reverse;
  }
}

.grid-container--subgrid {
  // Seems like we just need "enough" rows for all content, so just be generous
  $row-count: 20;

  --column-count: 2;
  @include bp(m) {
    --column-count: 4;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(var(--column-count), 1fr);
    grid-auto-rows: auto;
    width: 100%;

    :deep(.grid-item) {
      display: grid;
      grid-template-rows: subgrid;
      width: 100% !important;

      // Make the item span over a given amount of rows from "grid-auto-rows". Since we set it
      // to "auto", it creates as many auto-sized rows as we need, taking all grid items into account
      grid-row: span $row-count;
    }
  }
}
</style>
