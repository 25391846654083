export default class AbstractApi {
  /**
   *
   * @param client
   * @param logger
   * @param config
   * @param isAppview
   */
  constructor(client, logger, config, isAppview = false) {
    if (import.meta.dev && !client) {
      throw createError({
        statusCode: 500,
        statusMessage: 'Missing client',
        fatal: true,
      });
    }
    if (import.meta.dev && !logger) {
      throw createError({
        statusCode: 500,
        statusMessage: 'Missing logger',
        fatal: true,
      });
    }

    this.client = client;
    this.logger = logger;
    this.config = config;
    this.baseUrl = '';
    this.basePath = '';
    this.headers = {};
    this.isAppview = isAppview;
  }

  /**
   * @param config {AxiosRequestConfig | Record<string, any>}
   * @returns {Promise<unknown>}
   */
  async sendRequest(config) {
    // Cache is only enabled on the server and when 'cache' is not explicitly set to false
    const shouldCache =
      import.meta.server && this.config.multiCache.data && config.cache && config.method.toLowerCase() === 'get';

    let cacheKey, addToCache, ttl;

    if (shouldCache) {
      cacheKey = generateCacheKeyRequest(config);
      ttl = parseInt(this.config.cache.data.ttl, 10);

      const cache = await this.config.useDataCache(cacheKey, useNuxtApp().ssrContext.event);

      // Return on cache hit
      if (cache.value) {
        const isStale = Date.now() - cache.value.createdAt > ttl;

        if (!isStale) {
          return cache.value;
        }
      }

      // Assign addToCache to use it after the request
      addToCache = cache.addToCache;
    }

    // Send request
    const response = await this.client.request(config).catch((error) => {
      if (import.meta.client && isResponseError(error, 401, REQUIRES_HARD_LOGIN_ERROR)) {
        useUserStore().softLogin = true;
      }

      // Access token gets invalidated after a long time (app in background)
      // In this case, do a browser refresh to get a refreshed token for WebViews (in app)
      if (import.meta.client && unref(this.isAppview) && !config.isTokenApi && error.response?.status === 401) {
        this.logger.debug('Reloading Page due to unauthorized ajax request in appview');
        window.location.reload();
        return;
      }

      throw error;
    });

    if (shouldCache) {
      // Create serializable response object
      const responseCached = {
        config,
        data: response.data,
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
        id: cacheKey,
        ttl,
        createdAt: Date.now(),
      };

      await addToCache(responseCached, undefined, ttl);
      return deepClone(responseCached);
    }

    return {
      config,
      data: response.data,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
    };
  }

  /**
   * @param config {AxiosRequestConfig | Record<string, any>}
   * @param shouldLogData Set false to omit request payload in logs.
   * @returns {Promise<AxiosResponse>}
   */
  async request(config, shouldLogData = true) {
    config.url = this.getURL(config.url, config.version);
    config.headers = this.getHeaders(config.headers, config.abTests);

    if (!this.logger.isEnabled('debug')) {
      return this.sendRequest(config);
    }

    // Prepare log string value, additional info can be appended inside useWithTiming callback
    const logBuilder = this.config.useWithTimingLogBuilder(`${config.method.toUpperCase()} ${config.url}`);

    return this.config.useWithTiming(logBuilder, async () => {
      const response = await this.sendRequest(config);
      logBuilder.append(this.getLogData(shouldLogData, config, response));
      return response;
    });
  }

  getURL(endpoint, version) {
    const basePath = version ? `/${version}` : this.basePath;
    return `${this.baseUrl}${basePath}${endpoint}`;
  }

  getHeaders(headers, abTests) {
    if (!headers) {
      return this.headers;
    }

    if (abTests) {
      return {
        ...this.headers,
        ...headers,
        'FN-UX': JSON.stringify(abTests || {}),
      };
    }

    return {
      ...this.headers,
      ...headers,
    };
  }

  setAccessToken(accessToken) {
    if (!accessToken) {
      delete this.headers.Authorization;

      return this;
    }

    this.headers.Authorization = `Bearer ${accessToken}`;

    return this;
  }

  getLogData(shouldLogData, config, response) {
    if (!this.logger.isEnabled('debug')) {
      return {};
    }

    let configCopy;

    try {
      configCopy = JSON.parse(JSON.stringify(config));

      if (!shouldLogData) {
        delete configCopy.data;
      } else {
        // Always delete sensitive user data
        delete configCopy.data?.username;
        delete configCopy.data?.password;
      }

      // Delete redundant stuff
      delete configCopy.version;
      delete configCopy.lang;

      // Delete when headers might be empty, keeps the logs cleaner
      if (!Object.keys(configCopy.headers).length) {
        delete configCopy.headers;
      }

      // Add cache info if available
      if (response.ttl) {
        const remaining = response.ttl - (Date.now() - response.createdAt);

        configCopy.cache = {
          type: 'n/a',
          remaining: `${(remaining / 1000).toFixed(0)}s`,
        };
      }
    } catch (error) {
      this.logger.error('Error occurred while parsing request config:', error);
    }

    delete configCopy.method;
    delete configCopy.url;

    if (!Object.keys(configCopy).length) {
      return {};
    }

    return configCopy;
  }
}
