<template>
  <div
    class="grid-item"
    :class="gridItemClasses"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    static: {
      type: Boolean,
    },
    columns: {
      type: [Number, String],
      default: null,
    },
    columnsS: {
      type: [Number, String],
      default: null,
    },
    columnsM: {
      type: [Number, String],
      default: null,
    },
    columnsL: {
      type: [Number, String],
      default: null,
    },
    columnsXl: {
      type: [Number, String],
      default: null,
    },
    push: {
      type: [Number, String],
      default: null,
    },
    pushS: {
      type: [Number, String],
      default: null,
    },
    pushM: {
      type: [Number, String],
      default: null,
    },
    pushL: {
      type: [Number, String],
      default: null,
    },
    pushXl: {
      type: [Number, String],
      default: null,
    },
    pull: {
      type: [Number, String],
      default: null,
    },
    pullS: {
      type: [Number, String],
      default: null,
    },
    pullM: {
      type: [Number, String],
      default: null,
    },
    pullL: {
      type: [Number, String],
      default: null,
    },
    pullXl: {
      type: [Number, String],
      default: null,
    },
    order: {
      type: [Number, String],
      default: null,
    },
    orderS: {
      type: [Number, String],
      default: null,
    },
    orderM: {
      type: [Number, String],
      default: null,
    },
    orderL: {
      type: [Number, String],
      default: null,
    },
    orderXl: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    gridItemClasses() {
      const gridItemClass = [];
      if (this.static) {
        gridItemClass.push('grid-item--static');
      }
      if (this.columns) {
        gridItemClass.push(`grid-item--${this.columns}`);
      }
      if (this.columnsS) {
        gridItemClass.push(`grid-item--s--${this.columnsS}`);
      }
      if (this.columnsM) {
        gridItemClass.push(`grid-item--m--${this.columnsM}`);
      }
      if (this.columnsL) {
        gridItemClass.push(`grid-item--l--${this.columnsL}`);
      }
      if (this.columnsXl) {
        gridItemClass.push(`grid-item--xl--${this.columnsXl}`);
      }
      if (this.push) {
        gridItemClass.push(`grid-item--push--${this.push}`);
      }
      if (this.pushS) {
        gridItemClass.push(`grid-item--push--s--${this.pushS}`);
      }
      if (this.pushM) {
        gridItemClass.push(`grid-item--push--m--${this.pushM}`);
      }
      if (this.pushL) {
        gridItemClass.push(`grid-item--push--l--${this.pushL}`);
      }
      if (this.pushXl) {
        gridItemClass.push(`grid-item--push--xl--${this.pushXl}`);
      }
      if (this.pull) {
        gridItemClass.push(`grid-item--pull--${this.pull}`);
      }
      if (this.pullS) {
        gridItemClass.push(`grid-item--pull--s--${this.pullS}`);
      }
      if (this.pullM) {
        gridItemClass.push(`grid-item--pull--m--${this.pullM}`);
      }
      if (this.pullL) {
        gridItemClass.push(`grid-item--pull--l--${this.pullL}`);
      }
      if (this.pullXl) {
        gridItemClass.push(`grid-item--pull--xl--${this.pullXl}`);
      }
      if (this.order) {
        gridItemClass.push(`grid-item--order--${this.order}`);
      }
      if (this.orderS) {
        gridItemClass.push(`grid-item--order--s--${this.orderS}`);
      }
      if (this.orderM) {
        gridItemClass.push(`grid-item--order--m--${this.orderM}`);
      }
      if (this.orderL) {
        gridItemClass.push(`grid-item--order--l--${this.orderL}`);
      }
      if (this.orderXl) {
        gridItemClass.push(`grid-item--order--xl--${this.orderXl}`);
      }
      return gridItemClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-item {
  @include grid-item('grid-item');

  &.grid-item--product {
    margin-bottom: $grid-margin-width;
  }

  // Grid item that stays the same width
  // and doesn't grow or shrink
  &.grid-item--static {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &.grid-item--content-tile {
    padding-bottom: $grid-content-tile-padding;
  }
}
</style>
