// Import manually, as auto-import will not pick it up if we return 'NuxtLink' string
import { NuxtLink } from '#components';

/**
 * @deprecated Use `Links` composable instead
 */
export const getTag = (ctx, defaultTag) => {
  const hasProp = createHasProp(ctx);

  // Legacy stuff: hasProp since ButtonRound and NavigationLink components don't have `static`
  if (hasProp('static')) {
    return 'div';
  }

  // Legacy stuff: hasProp since Card + NavigationLink components don't have `submit`
  if (ctx.disabled || hasProp('submit')) {
    return 'button';
  }

  // Legacy stuff: hasProp since ButtonRound component does not have `hideFromCrawl`
  if (hasProp('hideFromCrawl')) {
    return 'span';
  }

  if (ctx.to) {
    return NuxtLink;
  }

  // Legacy stuff: hasProp since Card + NavigationLink components don't have `computedHref`
  if (ctx.href || hasProp('computedHref')) {
    return 'a';
  }

  return defaultTag;
};

/**
 * @deprecated Use `Links` composable instead
 */
export const getHref = (componentInstance) => {
  if (componentInstance.$router && componentInstance.to) {
    return undefined;
  }

  if (componentInstance.href) {
    return componentInstance.href;
  }

  if (componentInstance.to) {
    if (typeof componentInstance.to === 'object') {
      return componentInstance.to.path;
    }

    return componentInstance.to;
  }

  if (componentInstance.submit) {
    return undefined;
  }

  return '#';
};

/**
 * @deprecated Use `Links` composable instead
 */
export const getMultilangLink = (prefix, link) => {
  if (!prefix || !link) {
    return null;
  }

  const multilangLink = link?.path || link;
  const multilangLinkValid = multilangLink && typeof multilangLink === 'string';
  const linkArr = multilangLinkValid ? multilangLink.split('/').filter((part) => part !== '') : [];

  if ((linkArr.length && `/${linkArr[0]}` === prefix) || !multilangLink.startsWith('/')) {
    return null;
  }

  return prefix + multilangLink;
};
