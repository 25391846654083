let pbClient;

export const loadPaybackRegisterOverlay = async (paybackApiKey, accountBinding, partnerCard) => {
  PB.mc.core.Bootstrap.createClient({
    apiKey: paybackApiKey,
    canCreateStrongTokens: true,
    onSuccess(client) {
      pbClient = client;
      pbClient.createModuleInOverlay(
        {
          moduleType: 'enrollment',
          contextKey: 'overlayContext',
          options: {
            customerHasCard: false,
            partnerProvidesOwnCard: !partnerCard,
            showAccountBinding: !!accountBinding,
          },
        },
        {
          backdropDisabled: true,
          height: PB.mc.core.client.OverlayHeight.MD,
          maximumWidth: PB.mc.core.client.OverlayMaximumWidth.MD,
        },
      );
      pbClient.subscribe(PB.mcapi.AuthorizationCodeEvent.eventName, (payload) => {
        const { authorizationCode } = payload.data;
        $nuxt.$router.push({ path: '/payback/payback-auth/', query: { code: authorizationCode, enrollment: true } });
      });
      pbClient.subscribe(PB.mcapi.OverlayCloseEvent.eventName, () => {
        window.location.reload();
      });
    },
    onError(errorData) {},
  });
};

export const loadPaybackCouponIframe = async (paybackApiKey, token) => {
  PB.mc.core.Bootstrap.createClient({
    apiKey: paybackApiKey,
    canCreateStrongTokens: true,
    onSuccess(client) {
      client.createModule({
        moduleType: 'coupon',
        selector: '#payback_couponnutshell',
        width: 815,
        height: 300,
        contextKey: 'partnerWithoutPaybackContext',
        options: {},
        onSuccess(couponNutshellModule) {},
        onError(errorData) {},
      });
    },
    onError(errorData) {},
    onTokenRequired(handOverTokenCallbackFunction) {
      handOverTokenCallbackFunction(token);
    },
  });
};
