import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class AutoCompleteApi extends AbstractHybrisApi {
  startSession() {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/address/completion/startSession',
      params: {
        fields: 'FULL',
      },
    });
  }

  getResults(address, addressType, sessionToken) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: '/address/completion',
      params: {
        fields: 'FULL',
        address,
        addressType,
        sessionToken,
      },
    });
  }
}
