// constants for subscription types and status
export const SOI = 'SOI';
export const DOI = 'DOI';
export const CONFIRMED = 'CONFIRMED';
export const PENDING = 'PENDING';
export const DEACTIVATED = 'DEACTIVATED';

// constants for subscription error types
export const CONFIRMATION = 'confirmation';
export const NOT_FOUND = 'notFound';
export const UNAVAILABLE = 'unavailable';
