<template>
  <lazy-fn-page-container-minimal v-if="route.meta.layout === 'minimal'">
    <slot />
  </lazy-fn-page-container-minimal>
  <lazy-fn-page-container-salon v-else-if="route.meta.layout === 'salon'">
    <slot />
  </lazy-fn-page-container-salon>
  <lazy-fn-page-container-default v-else>
    <slot />
  </lazy-fn-page-container-default>
</template>

<script setup lang="ts">
const route = useRoute();
</script>
