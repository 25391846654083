<template>
  <fn-cms-section-loader
    v-if="page.content.content_slots?.a?.sections"
    :sections="page.content.content_slots.a.sections"
  />
</template>

<script setup lang="ts">
defineProps<{
  page: Record<string, any>;
}>();

const route = useRoute();
useScrollToHash(route);
</script>
