export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const { path, query } = to;

  //  Delete currentPage from query when currentPage === 1
  if (query.currentPage === '1') {
    delete query.currentPage;

    //  Redirect to page without currentPage
    useNuxtApp().$log.debug('redirecting because currentPage === 1');

    const routeNew = {
      path,
      query,
    };

    return navigateTo(routeNew, {
      redirectCode: 301,
    });
  }

  return null;
});
