import AbstractHybrisApi from '@/api/AbstractHybrisApi';

export default class RedirectApi extends AbstractHybrisApi {
  find(path) {
    return this.request({
      method: 'get',
      version: 'v2',
      url: `/redirect?url=${path}`,
    });
  }
}
