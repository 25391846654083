export default {
  mounted() {
    // Manually scroll to hash. The browser cannot handle this correctly,
    // because the nuxt page (_.vue) loads components dynamically
    if (this.$route.hash) {
      try {
        const target = document.querySelector(this.$route.hash);
        if (target) {
          window.scrollTo({ top: target.getBoundingClientRect().top + window.pageYOffset });
        }
      } catch (error) {
        console.warn(`Tried to scroll to ${this.$route.hash} but failed:`, error);
      }
    }
  },
};
