<template>
  <div
    :data-crl8-container-id="containerId"
    :data-crl8-filter="filter"
    :data-crl8-lazy-load-experience="lazyLoad"
    :data-crl8-locale="locale"
    data-crl8-auto-init="false"
  />
</template>

<script setup lang="ts">
import type { Curalate } from '@/types/curalateScript';

type ExtendedWindow = Window &
  typeof globalThis & {
    crl8: Curalate;
  };

const props = withDefaults(
  defineProps<{
    containerId: string;
    filter?: string;
    lazyLoad?: boolean;
    locale?: string;
  }>(),
  {
    locale: 'de-DE',
    filter: '',
  },
);

useHead({
  script: [
    {
      key: 'curalateScript',
      src: '/scripts/curalateScript.js',
      type: 'text/javascript',
      async: true,
    },
  ],
});

onMounted(() => {
  if ((window as ExtendedWindow).crl8) {
    // curalate.ready fires the callback once the crl8 api is available for use on the page
    (window as ExtendedWindow).crl8.ready(() => {
      (window as ExtendedWindow).crl8.createExperience(props.containerId);
    });
  }
});

onBeforeUnmount(() => {
  if ((window as ExtendedWindow).crl8) {
    // to be safe, use crl8.ready again or check for presence of
    // ((window as ExtendedWindow).crl8).destroyExperience before calling the function
    (window as ExtendedWindow).crl8.ready(() => {
      (window as ExtendedWindow).crl8.destroyExperience(props.containerId);
    });
  }
});
</script>
