<template>
  <div data-bv-show="product_picker" />
</template>

<script>
export default {
  mounted() {
    const isoLang = this.$i18n.locales.find((loc) => loc.code === this.$i18n.locale)?.language?.replace('-', '_') || 'de_DE';
    const environment = useRuntimeConfig().public.bazaarvoiceRaffleEnvironment;

    const bvScript = document.createElement('script');
    bvScript.src = `https://apps.bazaarvoice.com/deployments/fressnapf/sweepstake/${environment}/${isoLang}/bv.js`;
    document.body.appendChild(bvScript);
  },
};
</script>
