export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, async (to) => {
  const mainStore = useMainStore();
  const cartStore = useCartStore();
  const { query } = to;

  if (mainStore.baseStore !== 'fressnapfCH') {
    return navigateToWithAdobeParam('/cart/', query);
  }

  await callOnce(async () => Promise.all([cartStore.loadCart({ fields: 'FULL' }), cartStore.loadDeliveryModes()]));

  // redirect user to address step if cart delivery mode is C&C
  if (cartStore.getDeliveryMode.code === 'ch-click-and-collect-shipping') {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getReachedMinimumOrderValue) {
    return navigateToWithAdobeParam('/cart/', query);
  }

  if (!cartStore.getBillingAddress) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (!cartStore.getDeliveryAddress) {
    return navigateToWithAdobeParam('/checkout/address/', query);
  }

  if (cartStore.getDeliveryAddressIsPickupStore) {
    try {
      await useUserStore().verifyAddress(cartStore.getDeliveryAddress);
    } catch (error) {
      return navigateToWithAdobeParam('/checkout/address/', { ...query, error: 'invalidPickupStation' });
    }
  }
});
