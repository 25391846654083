<template>
  <fn-curalate-gallery
    :container-id="data.container.id"
    :filter="generateFilter"
    :lazy-load="data.lazy_load"
  />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    generateFilter() {
      const galleryType = this.data.container.id;

      switch (galleryType) {
        case 'product':
          return `productId:'${this.data.product_id}'`;
        case 'category':
          return `category: '${this.data.category.parent_category} > ${this.data.category.name}`;
        default:
          return null;
      }
    },
  },
};
</script>
