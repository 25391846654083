import ErrorHandler from '@/services/ErrorHandler';

export default defineNuxtPluginWithTiming(import.meta.url, (nuxtApp) => {
  /**
   * Startup errors
   * @see https://nuxt.com/docs/getting-started/error-handling#startup-errors
   */
  nuxtApp.hook('app:error', (error) => {
    logger.error('[storefront:error:nuxt]', error);
    nuxtApp.$sentry?.captureException?.(error);
  });

  /**
   * Vue lifecycle errors - including unhandled and handled errors
   * @see https://nuxt.com/docs/getting-started/error-handling#vue-errors
   */
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    logger.error('[storefront:error:vue]', { error, instance, info });
    nuxtApp.$sentry?.captureException?.(error, { instance, info });
  };

  nuxtApp.$router.onError((error: any, to: any) => {
    logger.error('[storefront:error:router]', error);
  });

  return {
    provide: {
      errorHandler: new ErrorHandler(),
    },
  };
});
