export type NavigationState = {
  activeOffCanvasMenu: string | null;

  activeFlyoutIndexLevel1?: number;

  activeFlyoutIndexLevel2?: number;

  /**
   * True when viewport is small enough to switch to mobile/off-canvas navigation
   */
  isOffCanvasNavActive: boolean;

  /**
   * True when rendering the mobile/off-canvas navigation
   */
  isOffCanvasNavVisible: boolean;
};

function createGetIsOffCanvasMenuActive(state: any) {
  return (key: any) => state.activeOffCanvasMenu === key;
}

export const useNavigationStore = defineStore('navigation', {
  state: () =>
    ({
      activeOffCanvasMenu: null,
      activeFlyoutIndexLevel1: undefined,
      activeFlyoutIndexLevel2: undefined,
      isOffCanvasNavActive: false,
      isOffCanvasNavVisible: false,
    }) as NavigationState,
  getters: {
    getIsOffCanvasMenuActive(state) {
      return createGetIsOffCanvasMenuActive(state);
    },

    getIsOffCanvasFacetActive(state): any {
      return createGetIsOffCanvasMenuActive(state)('facets');
    },

    getIsFlyOutVisible(state): any {
      return state.activeFlyoutIndexLevel1 !== undefined;
    },
    getIsOffCanvasNavActive(state): boolean {
      return state.isOffCanvasNavActive;
    },
    getIsOffCanvasNavVisible(state): boolean {
      return state.isOffCanvasNavVisible;
    },
  },
  actions: {
    toggleOffCanvasFacets() {
      if (import.meta.client) {
        document.body.classList.add('no-scrolling');
      }

      this.activeOffCanvasMenu = this.activeOffCanvasMenu === 'facets' ? null : 'facets';
    },

    closeOffCanvasMenu() {
      if (import.meta.client) {
        document.body.classList.remove('no-scrolling');
      }

      this.activeOffCanvasMenu = null;
    },

    setOffCanvasNavState(isOffCanvasNavActive: boolean) {
      this.isOffCanvasNavActive = isOffCanvasNavActive;
    },

    closeOffCanvasNav() {
      this.isOffCanvasNavVisible = false;
    },

    openOffCanvasNav() {
      this.isOffCanvasNavVisible = true;
    },

    toggleOffCanvasNav() {
      this.isOffCanvasNavVisible = !this.isOffCanvasNavVisible;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot));
}
