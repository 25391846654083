import AbstractHybrisApi from '@/api/AbstractHybrisApi';

const slugReg = /\/(?!.)/gi;

export default class PageApi extends AbstractHybrisApi {
  find(slug, revId = null, timestamp = null, lang = null, cache = true, apiVersion = 'v2') {
    let pageSlug;

    switch (slug) {
      case 'homepage':
        pageSlug = '';
        break;

      case 'header':
      case 'footer':
        pageSlug = slug;
        break;

      default:
        pageSlug = slug.match(slugReg) ? slug : `${slug}/`;
        break;
    }

    return this.request({
      method: 'get',
      version: apiVersion,
      url: `/cms/wordpress/pages`,
      cache,
      params: {
        lang,
        url: `/${pageSlug}`,
        ...(timestamp && { ts: timestamp }),
        ...(revId && { pageVersion: revId }),
      },
    });
  }

  getPagesByTags(tagKey, maxResponseEntities = 32, lang = null, apiVersion = 'v2') {
    return this.request({
      method: 'get',
      version: apiVersion,
      url: `/cms/wordpress/pages/${tagKey}`,
      params: {
        lang,
        maxResponseEntities,
      },
    });
  }
}
