class ErrorHandler {
  /**
   * Shows an error modal if there is an unexpected server error
   */
  handleErrorModal(error: any, catchAll = true) {
    if (import.meta.server) {
      return;
    }

    // No need to show the modal. requires-hard-login middleware is redirecting to login page
    if (isResponseError(error, 401, REQUIRES_HARD_LOGIN_ERROR)) {
      return;
    }

    if ([401, 403, 408, 500, 502].includes(error?.response?.status)) {
      this.commit({
        code: error.response.status,
        image: `errors.${error.response.status}.image`,
        title: `errors.${error.response.status}.title`,
        text: `errors.${error.response.status}.text`,
        button: `errors.${error.response.status}.button`,
        redirectUrl: `errors.${error.response.status}.redirectUrl`,
      });
      return;
    }

    if (!catchAll) {
      return;
    }

    this.commit({
      code: error?.response?.status,
      title: 'errors.default.title',
      text: 'errors.default.text',
    });
  }

  /**
   * The `fields` parameter can be updated to { name: string; subject: string } once we complete WEB-3222.
   */
  getFormErrors(
    error: any,
    fields: Array<{ vid: string; subject: string } | { name: string; subject: string }>,
  ): Record<string, Array<string>> & { general?: string | Array<string> } {
    let formErrors = {};

    if (!error?.response?.data?.errors) {
      return formErrors;
    }

    const { $i18n } = useNuxtApp();

    const responseErrors = error.response.data.errors;

    for (let i = 0; i < responseErrors.length; i += 1) {
      const responseError = responseErrors[i];

      if (responseError.type && responseError.type === 'redirect') {
        continue;
      }

      if (!responseError.subject) {
        formErrors = this.addGeneralError(formErrors, responseError);
        continue;
      }

      const formField = fields.find((field) => field.subject === responseError.subject);

      if (!formField) {
        formErrors = this.addGeneralError(formErrors, responseError);
        continue;
      }

      if (responseError.message) {
        formErrors = this.addError(formErrors, formField?.vid || formField?.name, responseError.message);
        continue;
      }

      if (responseError.reason) {
        formErrors = this.addError(
          formErrors,
          formField?.vid || formField?.name,
          $i18n.t(`validation.${responseError.reason}`),
        );
        continue;
      }

      formErrors = this.addError(formErrors, formField?.vid || formField?.name, $i18n.t('validation.general'));
    }

    return formErrors;
  }

  addGeneralError(formErrors: Record<string, any>, responseError: { message: string }) {
    return this.addError(
      formErrors,
      'general',
      responseError.message ? responseError.message : useNuxtApp().$i18n.t('validation.general'),
    );
  }

  addError(formErrors: Record<string, any>, name: string, message: string) {
    if (!Array.isArray(formErrors[name])) {
      formErrors[name] = [];
    }

    formErrors[name].push(message);

    return formErrors;
  }

  commit({
    code,
    image,
    title,
    text,
    button,
    redirectUrl,
  }: {
    code: number;
    image?: string;
    title: string;
    text: string;
    button?: string;
    redirectUrl?: string;
  }) {
    const { $i18n, $log } = useNuxtApp();
    const mainStore = useMainStore();

    mainStore.error = {
      code,
      image: $i18n.te(image) ? $i18n.t(image) : undefined,
      title: $i18n.t(title),
      text: $i18n.t(text),
      button: $i18n.te(button) ? $i18n.t(button) : null,
      redirectUrl: $i18n.te(redirectUrl) ? $i18n.t(redirectUrl) : null,
    };

    $log.debug('ErrorHandler:commit', mainStore.error);
  }

  getErrorType(error: any) {
    return error?.response?.data?.errors?.[0]?.type;
  }

  isErrorType(errorType: string, expectedErrorType: string) {
    return errorType === expectedErrorType;
  }
}

export default ErrorHandler;
