export const getPageType = (page) => {
  if (page.url === '/') {
    return 'homepage';
  }

  if (page.url.startsWith('/p/')) {
    return 'product';
  }

  if (page.content.template === 'category') {
    return 'category';
  }

  if (page.content.template === 'product-listing') {
    return 'productlist';
  }

  if (page.content.template === 'search') {
    return 'search';
  }

  if (page.content.template === 'cart') {
    return 'cart';
  }

  if (page.content.template === 'order-confirmation') {
    return 'checkout-confirmation';
  }

  if (page.url.startsWith('checkout')) {
    return 'checkout';
  }

  return null;
};

export const getPagePath = (page) => {
  if (!page.content.breadcrumbs.length) {
    return [];
  }

  return Array.from(page.content.breadcrumbs, (breadcrumb) => breadcrumb.title);
};

export const getPageCategory = (page) => {
  const pageType = getPageType(page);

  if (pageType !== 'product' && pageType !== 'productlist') {
    return null;
  }

  if (!page.content.breadcrumbs.length) {
    return null;
  }

  return page.content.breadcrumbs[0].title;
};
