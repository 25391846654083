export default defineNuxtRouteMiddlewareWithTiming(import.meta.url, (to) => {
  if (useUserStore().softLogin) {
    return navigateTo({
      path: useNuxtApp().$i18n.t('url.login'),
      query: {
        redirectTo: getRouteRedirectTo(to),
      },
    });
  }

  return null;
});
