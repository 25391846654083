<template>
  <fn-section
    constrained="l"
    background-color="sand-light"
    padding-top="s"
    padding-bottom="xs"
  >
    <fn-panel>
      <div class="header">
        <fn-headline
          type="h1"
          size="h2"
          no-margin-bottom
        >
          {{ translations.headline }} {{ error?.statusCode }}
        </fn-headline>

        <fn-button
          v-if="$config.public.extendedErrorPageEnabled"
          quaternary-alt
          @click="copyToClipboard"
        >
          {{ copied ? translations.buttonCopied : translations.buttonCopy }}
        </fn-button>
      </div>

      <section
        v-if="$config.public.extendedErrorPageEnabled"
        class="container"
      >
        <div>
          <pre
            class="error-message"
            v-html="`[Error] ${error?.statusMessage || ''}${error?.message || ''}`"
          />

          <pre v-html="error?.stack || translations.noStackTrace" />
        </div>
      </section>

      <nuxt-link to="/">
        {{ translations.buttonBack }}
      </nuxt-link>
    </fn-panel>
  </fn-section>
</template>

<script setup lang="ts">
const error = useError();
const { $i18n, $config } = useNuxtApp();
const { copy, copied } = useClipboard();

// Fallbacks are useful in case we have errors before i18n initialization
const translations = computed(() => ({
  headline: $i18n.te('pages.error.message') ? $i18n.t('pages.error.message') : 'An error has occurred: status code',
  buttonBack: $i18n.te('pages.error.buttons.back') ? $i18n.t('pages.error.buttons.back') : 'Back to home page',
  buttonCopy: 'Copy to clipboard',
  buttonCopied: 'Copied to clipboard!',
  noStackTrace: 'No stack trace available',
}));

const copyToClipboard = async () => {
  await copy(
    JSON.stringify(
      {
        error: unref(error),
      },
      null,
      '\t',
    ),
  );
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $pad;
}

.container {
  display: flex;
  flex-direction: column;
  grid-gap: $pad;
  margin-bottom: $pad;

  :deep(pre) {
    white-space: pre-wrap;
    overflow-x: auto;
  }

  .error-message {
    font-weight: map-get($font-weights, 'bold');
    margin: 0;
  }
}
</style>
