<template>
  <div
    class="panel"
    :class="[
      backgroundColor ? 'panel--bg-' + backgroundColor : '',
      foregroundColor ? 'panel--fg-' + foregroundColor : '',
      paddingTop ? 'panel--pt-' + paddingTop : '',
      paddingBottom ? 'panel--pb-' + paddingBottom : '',
      {
        'panel--border-radius-xl': borderRadiusXl,
        'panel--border-radius-rebrand': borderRadiusRebrand,
        'panel--constrained': constrained,
        'panel--flex': flex,
        'panel--flex-reverse': flexReverse,
        'panel--gradient': gradient,
        'panel--hidden': !visible,
        'panel--loading': loading,
        'panel--medium-padding': mediumPadding,
        'panel--no-padding-top': noPaddingTop,
        'panel--no-padding-x': noPaddingX,
        'panel--padded': padded,
        'panel--small-padding': smallPadding,
        'panel--toggle': toggle,
        'panel--redesign': redesign,
      },
    ]"
  >
    <fn-loading-overlay v-if="loading" />

    <div
      v-if="headline || $slots.header"
      class="p-header"
      :class="[{ 'p-header--no-padY': subline }, { 'p-header--h2': headlineSize === 'h2' }]"
      @click.prevent="handleClick"
    >
      <slot name="header">
        <div class="p-header-heading">
          <fn-icon
            v-if="icon"
            :icon="icon"
            :color="iconColor"
            size="s"
          />
          <fn-headline
            :type="headlineSize"
            :size="headlineSize"
            class="p-head"
          >
            {{ headline }}
          </fn-headline>
        </div>
        <fn-headline
          v-if="subline"
          type="h4"
          size="h5"
          class="p-head-subline"
        >
          {{ subline }}
        </fn-headline>
      </slot>

      <div
        v-if="toggle"
        class="p-toggle-button"
      >
        <slot
          v-bind="{ handleClick, visible }"
          name="toggleButton"
        >
          <fn-button-round @click="handleClick">
            <fn-icon
              color="white"
              size="xxs"
              :icon="visible ? 'minus' : 'plus'"
            />
          </fn-button-round>
        </slot>
      </div>
    </div>
    <div
      v-if="visible"
      class="p-main"
    >
      <slot name="default" />
    </div>
  </div>
</template>

<script>
export default defineComponent({
  props: {
    backgroundColor: {
      type: String,
      default: '',
    },
    borderRadiusRebrand: {
      type: Boolean,
    },
    borderRadiusXl: {
      type: Boolean,
    },
    constrained: {
      type: Boolean,
    },
    flex: {
      type: Boolean,
    },
    flexReverse: {
      type: Boolean,
    },
    foregroundColor: {
      type: String,
      default: '',
    },
    gradient: {
      type: Boolean,
    },
    headline: {
      type: String,
      default: '',
    },
    headlineSize: {
      type: String,
      default: 'h3',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'black',
    },
    loading: {
      type: Boolean,
    },
    mediumPadding: {
      type: Boolean,
    },
    noPaddingTop: {
      type: Boolean,
    },
    noPaddingX: {
      type: Boolean,
    },
    padded: {
      type: Boolean,
    },
    paddingBottom: {
      type: String,
      default: null,
    },
    paddingTop: {
      type: String,
      default: null,
    },
    subline: {
      type: String,
      default: '',
    },
    smallPadding: {
      type: Boolean,
    },
    toggle: {
      type: Boolean,
    },
    toggleVisible: {
      type: Boolean,
    },
    redesign: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: !this.toggle || this.toggleVisible,
    };
  },
  methods: {
    handleClick() {
      if (!this.toggle) return;
      this.visible = !this.visible;
    },
  },
});
</script>

<style lang="scss" scoped>
.panel {
  position: relative;
  display: block;
  background-color: $color-general-background-neutral-default;
  padding: $pad;
  margin-bottom: $pad;

  @include bp(m) {
    padding: $pad * 2;
    border-radius: map-get($border-radiuses, 'l');
  }

  &:last-child {
    margin-bottom: 0;
  }

  .p-header {
    position: relative;
    margin-bottom: $pad;

    @include bp(m) {
      margin-bottom: $pad * 2;
    }

    &.p-header--no-padY {
      margin-bottom: math.div($space, 2);
    }

    &.p-header--h2 {
      margin: 0 0 $pad 0;
    }

    &-heading {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 0.5rem;
      }
    }
  }

  .p-head {
    margin: 0;
  }

  .p-head-subline {
    margin-top: math.div($space, 1.33);
  }

  .p-toggle-button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 24px;
    margin: auto 0;
  }

  &.panel--toggle {
    .p-header {
      cursor: pointer;
      padding-right: 30px;
    }
  }

  &.panel--hidden {
    .p-header {
      margin-bottom: 0;
    }
  }

  &.panel--loading {
    .loading-overlay {
      border-radius: map-get($border-radiuses, 'l');
    }
  }

  &.panel--padded {
    @include bp(m) {
      padding-top: $pad * 4;
      padding-bottom: $pad * 4;
    }
  }

  &.panel--small-padding {
    @include bp(m) {
      padding: $pad;
    }
  }

  &.panel--medium-padding {
    padding: $pad;

    @include bp(m) {
      padding: $pad * 1.5;
    }
  }

  &.panel--no-padding-x {
    padding-left: 0;
    padding-right: 0;
  }

  &.panel--no-padding-top {
    padding-top: 0;
  }

  &.panel--constrained {
    .p-header,
    .p-main {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.panel--gradient {
    background: linear-gradient(-30deg, $color-general-background-neutral-default, rgba(255, 255, 255, 0.3));
  }

  &.panel--flex {
    .p-main {
      display: flex;
      flex-direction: column;
      gap: $space;
    }
  }

  &.panel--flex-reverse {
    .p-main {
      flex-direction: column-reverse;
    }
  }

  &.panel--border-radius-xl {
    border-radius: map-get($border-radiuses, 'xl');
  }

  &.panel--border-radius-rebrand {
    border-radius: map-get($border-radiuses, 'rebrand');
  }

  // Colors
  @each $palette, $tones in $palettes {
    @each $tone, $hex in $tones {
      @if $tone == 'base' {
        &.panel--bg-#{$palette} {
          background-color: $hex;
        }
        &.panel--fg-#{$palette} {
          color: $hex;
        }
      } @else {
        &.panel--bg-#{$palette}-#{$tone} {
          background-color: $hex;
        }
        &.panel--fg-#{$palette}-#{$tone} {
          color: $hex;
        }
      }
    }
  }

  // Padding Top
  &.panel--pt-l {
    padding-top: $space;
  }

  // Padding Bottom
  &.panel--pb-l {
    padding-bottom: $space;
  }

  &.panel--redesign {
    background-color: $color-general-background-highlight-default;
    color: $color-general-text-neutral-default;
    font-size: map-get($label, 'large');

    p {
      color: $color-general-text-neutral-low;
    }

    :deep(.link-text) {
      color: $color-general-text-brand-default;
    }
  }
}
</style>
