import type { InputType } from 'vee-validate';

/**
 * Props for all input field components
 */
export type InputFieldProps = {
  autocomplete?: string;
  autofocus?: boolean;
  disabled?: boolean;
  id?: string;
  inputmode?: 'text' | 'search' | 'email' | 'none' | 'tel' | 'url' | 'numeric' | 'decimal';
  max?: string | number;
  maxlength?: number | string;
  min?: number;
  minlength?: number;
  name: string;
  novalidate?: boolean;
  pattern?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  size?: string;
  step?: number;
  tabindex?: number;
  type?: string;
  veeType?: InputType;
  initialValue?: string;
  hotjarAllow?: boolean;
};

/**
 * Default values for props type 'InputFieldProps'
 */
export const defineInputFieldPropsDefaults = () => ({
  initialValue: '',
  type: 'text',
  veeType: 'default' as InputType,
});

/**
 * Returns an object of attributes to be bound to input field components
 */
export const defineInputBindings = (props: InputFieldProps) =>
  computed(() => ({
    autocomplete: props.autocomplete,
    autofocus: props.autofocus,
    disabled: props.disabled,
    id: props.id,
    inputmode: props.inputmode,
    max: props.max,
    maxlength: props.maxlength,
    min: props.min,
    minlength: props.minlength,
    name: props.name,
    novalidate: props.novalidate,
    pattern: props.pattern,
    placeholder: props.placeholder,
    readonly: props.readonly,
    required: props.required,
    size: props.size,
    step: props.step,
    tabindex: props.tabindex,
    type: props.type,
    'data-hj-allow': props.hotjarAllow,
    'data-hj-suppress': !props.hotjarAllow,
  }));

/**
 * Legacy mixin for options API input field components
 * @deprecated Should no longer be used and completely replaced by the composition API implementations above
 */
export default defineComponent({
  props: {
    autocomplete: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    id: {
      type: String,
      default: '',
    },
    inputmode: {
      type: String,
      default: '',
    },
    max: {
      type: [String, Number],
    },
    maxlength: {
      type: [Number, String],
    },
    min: {
      type: Number,
    },
    minlength: {
      type: Number,
    },
    name: {
      type: String,
      required: true,
    },
    novalidate: {
      type: Boolean,
    },
    pattern: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    size: {
      type: String,
    },
    step: {
      type: Number,
    },
    tabindex: {
      type: Number,
    },
    type: {
      type: String,
      required: true,
    },
    veeType: {
      type: String,
      default: 'default',
    },
    value: {
      type: [String, Boolean],
      default: '',
    },
    initialValue: {
      type: String,
      default: '',
    },
    hotjarAllow: {
      type: Boolean,
    },
  },
  computed: {
    bindings(this: InputFieldProps) {
      return defineInputBindings(this);
    },
  },
});
